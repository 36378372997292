import React, { useState, useEffect } from "react";
import axios from "axios";
import "../export/export.css";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../userAuth";

const WEEE_GRADES = [
  "P3",
  "P4",
  "CDROM Green",
  "ECU",
  "Receiver W/ Chip",
  "WiFi",
  "HardDrive Boards",
  "RAMS gold",
  "Phone Mix",
  "Android",
  "Nokia",
  "Chinese",
  "P4 No Chips",
  "BrownBoards 2",
  "P4 +",
  "P4 2 IC",
  "P4 Mix",
  "P4 -",
  "P3 Mix",
];

const ViewWeeeLoad = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const { loadId } = useParams();
  const [saveMessage, setSaveMessage] = useState("");
  const [exportData, setExportData] = useState(null);
  const [weeGrades, setWeeGrades] = useState([]);
  const [loadValues, setLoadValues] = useState(null);
  const [mainLoad, setMainLoad] = useState(null);
  const isShipped = mainLoad?.shipped;
  const navigate = useNavigate();

  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  // Move fetchLoadData outside useEffect
  const fetchLoadData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/single-load/${loadId}`);
      setExportData(response.data);
      setLoadValues(response.data.lotsAll.indLots[0]);
      setMainLoad(response.data);
      const existingWeeGrades =
        response.data.lotsAll?.indLots[0]?.weeGrades || [];

      // Ensure all grades exist in the state with default values
      const initializedGrades = WEEE_GRADES.map((grade) => {
        const found = existingWeeGrades.find((item) => item.grade === grade);
        return {
          grade,
          sellPrice: found ? found.sellPrice : 0,
          paidPerKg: found ? found.paidPerKg : 0,
          gradeKg: found ? found.gradeKg || 0 : 0,
          gradeKgEst: found ? found.gradeKgEst || 0 : 0,
          sellValue: found ? found.sellValue || 0 : 0,
          totalPaid: found ? found.totalPaid || 0 : 0,
          paidPerKgFinal: found ? found.paidPerKgFinal || 0 : 0,
        };
      });

      setWeeGrades(initializedGrades);
    } catch (error) {
      console.error("Error fetching load details:", error);
    }
  };

  // Call fetchLoadData in useEffect
  useEffect(() => {
    fetchLoadData();
  }, [loadId]);

  const handleGradeChange = (index, field, value) => {
    const updatedGrades = [...weeGrades];
    updatedGrades[index][field] = parseFloat(value) || 0;

    // Automatically update sellValue
    updatedGrades[index].sellValue =
      updatedGrades[index].sellPrice * updatedGrades[index].gradeKg;

    updatedGrades[index].totalPaid =
      updatedGrades[index].paidPerKg * updatedGrades[index].gradeKgEst;

    updatedGrades[index].paidPerKgFinal =
      (updatedGrades[index].paidPerKg * updatedGrades[index].gradeKgEst) /
      updatedGrades[index].gradeKg;

    setWeeGrades(updatedGrades);
  };

  const handleSave = async () => {
    try {
      // Calculate totals
      const totalKilogramsFinal = weeGrades.reduce(
        (sum, item) => sum + item.gradeKg,
        0
      );
      const totalUsdValueFinal = weeGrades.reduce(
        (sum, item) => sum + item.sellValue,
        0
      );

      const updatedLoad = {
        ...exportData,
        lotsAll: {
          ...exportData.lotsAll,
          indLots: exportData.lotsAll.indLots.map((lot) => ({
            ...lot,
            weeGrades,
            kilogramsFinal: totalKilogramsFinal,
            usdValueFinal: totalUsdValueFinal,
          })),
        },
      };

      // Step 1: Update Load Data
      await axios.put(
        `${baseUrl}/api/loads-edit-singleload/from-view-weee-load/${loadId}`,
        updatedLoad
      );

      // Step 3: Update UI and refetch data
      setExportData(updatedLoad);
      alert("Saved successfully!");
      fetchLoadData();
    } catch (error) {
      console.error("Error updating WEEE grades or stock:", error);
    }
  };

  if (!exportData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="stock-second">
      {isLoggedIn && title === "Admin" && (
        <>
          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">
                {mainLoad.load} Load Estimations
              </div>
              <table className="table50">
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th>Weight</th>
                    <th>Spent</th>
                    <th>Gross</th>
                    <th>Margin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="inputExportDetailsFixations">
                      {loadValues?.contactCrg.name}
                    </td>
                    <td className="inputExportDetailsFixations">
                      {loadValues?.kilogramsInd} Kg
                    </td>
                    <td className="inputExportDetailsFixations">
                      ${loadValues?.usdSpentInd}
                    </td>
                    <td className="inputExportDetailsFixations">
                      ${loadValues?.usdValueInd}
                    </td>
                    <td className="inputExportDetailsFixations">
                      {loadValues?.usdSpentInd && loadValues?.usdValueInd
                        ? (
                            (loadValues.usdValueInd / loadValues.usdSpentInd) *
                            100
                          ).toFixed(2) + "%"
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">
                {mainLoad.load} Load Final
              </div>
              <table className="table50">
                <thead>
                  <tr>
                    <th>Net Weight</th>
                    <th>Recovery</th>
                    <th>Spent</th>
                    <th>Gross</th>
                    <th>Margin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="inputExportDetailsFixations">
                      {loadValues?.kilogramsFinal} Kg
                    </td>
                    <td className="inputExportDetailsFixations">
                      {(
                        (loadValues?.kilogramsFinal /
                          loadValues?.kilogramsInd) *
                        100
                      ).toFixed(2)}{" "}
                      %
                    </td>
                    <td className="inputExportDetailsFixations">
                      ${loadValues?.usdSpentInd}
                    </td>
                    <td className="inputExportDetailsFixations">
                      $
                      {(
                        loadValues?.usdValueFinal - loadValues?.usdSpentInd
                      ).toFixed(0)}
                    </td>
                    <td className="inputExportDetailsFixations">
                      {loadValues?.usdSpentInd && loadValues?.usdValueFinal
                        ? (
                            (loadValues.usdValueFinal /
                              loadValues.usdSpentInd) *
                            100
                          ).toFixed(2) + "%"
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <table className="table50">
                <thead>
                  <tr>
                    <th>Grade</th>
                    <th>Kg Est</th>
                    <th>Paid / Kg ($)</th>
                    <th>Kg Final</th>
                    <th>Sell Price ($)</th>
                    <th>Paid Final</th>
                    <th>Per KG Final</th>
                    <th>Sell Value</th>
                    <th>Profit</th>
                  </tr>
                </thead>
                <tbody>
                  {weeGrades.map((item, index) => (
                    <tr key={index}>
                      <td>{item.grade}</td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={item.gradeKgEst}
                          onChange={(e) =>
                            handleGradeChange(
                              index,
                              "gradeKgEst",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={item.paidPerKg}
                          onChange={(e) =>
                            handleGradeChange(
                              index,
                              "paidPerKg",
                              e.target.value
                            )
                          }
                        />
                      </td>

                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={item.gradeKg}
                          onChange={(e) =>
                            handleGradeChange(index, "gradeKg", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={item.sellPrice}
                          onChange={(e) =>
                            handleGradeChange(
                              index,
                              "sellPrice",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="inputExportDetailsFixations"
                          value={item.gradeKgEst * item.paidPerKg || 0}
                          onChange={(e) =>
                            handleGradeChange(
                              index,
                              "totalPaid",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="inputExportDetailsFixations"
                          value={
                            item?.gradeKg > 0 &&
                            item?.gradeKgEst !== undefined &&
                            item?.paidPerKg !== undefined
                              ? (
                                  (item.gradeKgEst * item.paidPerKg) /
                                  item.gradeKg
                                ).toFixed(2)
                              : "0.00"
                          }
                          onChange={(e) =>
                            handleGradeChange(
                              index,
                              "paidPerKgFinal",
                              e.target.value
                            )
                          }
                        />
                      </td>

                      <td>
                        {/* Read-only Display */}
                        <span className="inputExportDetailsFixations">
                          ${item.sellValue.toFixed(2)}
                        </span>

                        {/* Hidden Input to Store the Value */}
                        <input type="hidden" value={item.sellValue} />
                      </td>
                      <td>
                        {/* Read-only Display */}
                        <span className="inputExportDetailsFixations">
                          $
                          {(
                            item.sellValue -
                            item.gradeKgEst * item.paidPerKg
                          ).toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isShipped && (
                <div className="alertMessage">
                  This load has been finalized. Editing is disabled.
                </div>
              )}

              <div className="bottomButtonRow">
                <div
                  className={`exportCreateLoadButton ${
                    isShipped ? "disabled" : ""
                  }`}
                  onClick={!isShipped ? handleSave : null}
                  style={{
                    opacity: isShipped ? 0.5 : 1,
                    cursor: isShipped ? "not-allowed" : "pointer",
                  }}
                >
                  <FontAwesomeIcon className="dashboardIcons" icon={faSave} />
                  <span>Save</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewWeeeLoad;
