import React, { useState, useEffect } from "react";
import axios from "axios";
import "../export/export.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../userAuth";

const WEEE_GRADES = [
  "P3",
  "P4",
  "CDROM Green",
  "ECU",
  "Receiver W/ Chip",
  "WiFi",
  "HardDrive Boards",
  "RAMS gold",
  "Phone Mix",
  "Android",
  "Nokia",
  "Chinese",
  "P4 No Chips",
  "BrownBoards 2",
  "P4 +",
  "P4 2 IC",
  "P4 Mix",
  "P4 -",
  "P3 Mix",
];

const ViewWeeeStock = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const [saveMessage, setSaveMessage] = useState("");
  const [weeGrades, setWeeGrades] = useState([]);
  const [totalStockKilograms, setTotalStockKilograms] = useState(0);
  const [totalStockValue, setTotalStockValue] = useState(0);
  const [totalSellValue, setTotalSellValue] = useState(0);
  const navigate = useNavigate();

  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      fetchStockData();
    }
  }, [isLoggedIn, navigate]);

  const fetchStockData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/weeestock/main`);
      const stockData = response.data[0];

      const existingWeeGrades = stockData?.lotsAll?.indLots[0]?.weeGrades || [];

      const initializedGrades = WEEE_GRADES.map((grade) => {
        const found = existingWeeGrades.find((item) => item.grade === grade);
        return {
          grade,
          sellPrice: found ? found.sellPrice : 0,
          paidPerKg: found ? found.paidPerKg : 0,
          gradeKg: found ? found.gradeKg || 0 : 0,
          gradeKgEst: found ? found.gradeKgEst || 0 : 0,
          totalPaid: found ? found.totalPaid || 0 : 0,
          sellValue: found ? found.sellValue || 0 : 0,
          paidPerKgFinal: found ? found.paidPerKgFinal || 0 : 0,
          percentLoss: found ? found.percentLoss || 0 : 0,
        };
      });

      setWeeGrades(initializedGrades);
      setTotalStockKilograms(stockData?.totalStockKilograms || 0);
      setTotalStockValue(stockData?.totalStockValue || 0);
      setTotalSellValue(stockData?.totalSellValue || 0);
    } catch (error) {
      console.error("Error fetching WEEE stock:", error);
    }
  };

  const handleChange = (index, field, value) => {
    setWeeGrades((prevGrades) => {
      return prevGrades.map((grade, i) => {
        if (i === index) {
          if (field === "gradeKg") {
            const previousGradeKg = grade.gradeKg || 0; // Get the previously saved value
            const newGradeKg = value; // New user-entered value
            const difference = previousGradeKg - newGradeKg;

            const updatedGradeKgEst = (grade.gradeKgEst || 0) - difference; // Adjusted gradeKgEst
            const updatedTotalPaid = updatedGradeKgEst * (grade.paidPerKg || 0); // Update totalPaid

            const updatedSellValue = newGradeKg * grade.sellPrice;

            return {
              ...grade,
              gradeKg: newGradeKg, // Update the new gradeKg value
              gradeKgEst: updatedGradeKgEst, // Update gradeKgEst accordingly
              totalPaid: updatedTotalPaid, // Update totalPaid using new gradeKgEst
              sellValue: updatedSellValue,
            };
          }
        }
        return grade;
      });
    });
  };

  const handleSave = async () => {
    try {
      await axios.put(`${baseUrl}/api/weeestock/update`, {
        weeGrades,
      });

      fetchStockData();
      alert("Saved successfully!");
    } catch (error) {
      console.error("Error updating stock:", error);
    }
  };

  return (
    <div className="stock-second">
      {isLoggedIn && title === "Admin" && (
        <>
          <div className="exports-third-row">
            <div className="h1HeadersStockDetailsBig">Manage WEEE Stock</div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">Stock Summary</div>
              <table className="table50">
                <thead className="table50">
                  <tr className="table50">
                    <th className="table50th">Stock Value</th>
                    <th className="table50th">Net Kilograms</th>
                    <th className="table50th">Sell Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table50td">${totalStockValue.toFixed(0)}</td>
                    <td className="table50td">
                      ${totalStockKilograms.toFixed(0)}
                    </td>
                    <td className="table50td">${totalSellValue.toFixed(0)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <table className="table50">
                <thead>
                  <tr>
                    <th>Grade</th>
                    <th>Net Kg</th>
                    <th>Paid Per Kg</th>
                    <th>Total Paid</th>
                    <th>Sell Price</th>
                    <th>Sell Value</th>
                    <th>Gross Kg</th>
                    <th>Paid Per Kg</th>
                    <th>Loss Kg</th>
                  </tr>
                </thead>
                <tbody>
                  {weeGrades.map((grade, index) => (
                    <tr key={grade.grade}>
                      <td>{grade.grade}</td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={grade.gradeKg}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "gradeKg",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          step="0.01" // Ensures two decimal precision
                          value={Number(grade.paidPerKgFinal).toFixed(2)} // Ensures fixed decimal places
                          onChange={(e) =>
                            handleChange(
                              index,
                              "paidPerKg",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </td>

                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={Math.round(
                            grade.gradeKg * grade.paidPerKgFinal
                          )}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "totalPaid",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={Number(grade.sellPrice).toFixed(2)}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "sellPrice",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={Number(grade.sellValue).toFixed(0)}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "sellValue",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={grade.gradeKgEst}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "gradeKgEst",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="number"
                          value={grade.paidPerKg}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "paidPerKg",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="inputExportDetailsFixations"
                          type="text"
                          value={`${grade.percentLoss || 0}%`}
                          readOnly
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="bottomButtonRow">
            <div className="exportCreateLoadButton" onClick={handleSave}>
              <FontAwesomeIcon className="dashboardIcons" icon={faSave} />
              <span>Save</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewWeeeStock;
