import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './dashmain.css'
import CurrentGoal from './currentgoal';
import GoalCharts from './goalcharts';
import GoalProfitChart from './goalprofitchart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBills, faPallet, faPaperclip, faQuestion, faTrophy } from '@fortawesome/free-solid-svg-icons';
import GoalWeeklyChart from './goalWeeklyChart';
import GoalDailyChart from './goalDailyChart';
import { useAuth } from '../../userAuth';

const Sidebar = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  

  
  const handleGoalsClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate('/crg-goals/fads6t5dsa6t5t657654i6tuyk1j6s5g4dfgs+gdf');
  };

  const handleFinalExpensesClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate('/crg-final-expenses-by-month/salje3k54j6pe6oytirus-fga9fuwea[irbipsen[aiksjd');
  };

  const handleReportsClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate('/crg-monthly-reports/fjwaeoip5u4petiogufjhteyiojraeskvj[sdasf;lkj');
  };

  const handleStockClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate('/crg-stock-main/aksdl;fh4piou34-t90th=usdhij[fioa[ioAWEJ;KJ;65GF3HGLBNKHJGFAkkl.,jf321');
  };



  


  return (
    <div className="menuDashboardColumn">
{isLoggedIn && title === 'Admin' && (
    <>
      <div className="menuDashboard">
      <div className='menuDashboardClicks' onClick={handleGoalsClick}>
        <FontAwesomeIcon className='dashboardIcons' icon={faTrophy} />
        <span>Goals</span>
      </div>

      <div className='menuDashboardClicks' onClick={handleStockClick}>
        <FontAwesomeIcon className='dashboardIcons' icon={faQuestion} />
        <span>Stock</span>
      </div>

      <div className='menuDashboardClicks' onClick={handleReportsClick}>
        <FontAwesomeIcon className='dashboardIcons' icon={faPaperclip} />
        <span>Reports</span>
      </div>

      <div className='menuDashboardClicks' onClick={handleFinalExpensesClick}>
        <FontAwesomeIcon className='dashboardIcons' icon={faMoneyBills} />
        <span>Finances</span>
      </div>
      </div>
      </>
)}
    </div>
  );
};

const Content = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  return (
    <div className="content">
              {isLoggedIn && title === 'Admin' && (
    <>
        <CurrentGoal />
        </>
)}

    </div>
  );
};

const Dashboard = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  console.log(title)
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  return (
      <div className="dashmain">
        {isLoggedIn && title === 'Admin' && (
    <>

        <div className="dashmaintoprow">
        {title === 'Admin' && <Sidebar />}

                <div className="dashmaintopsecondrow">
                  <GoalCharts />
                  <GoalProfitChart />
                  <GoalWeeklyChart />
                  <GoalDailyChart />
            </div>
        </div>
        <Content />
        </>
)}

      </div>
  );
};

export default Dashboard;
