import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../userAuth";
import "./stock.css";

const CrgStockMain = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  const isLocal = window.location.hostname === "localhost";
const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  const handleWeeeClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate(
      "/crg-stock-weee/aksdl;fh4piou34-t90th=usdhij[fioa[ioAWEJ;KJ;65GF3HGaskd;lreHJGFAkkljf321"
    );
  };

  const handleStockCatsClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate(
      "/crg-stock-catalytics/aksdl;fh4piou34-t90th=usdhij[fioa[ioAWEJ;KJ;65GF3HGLBNKHJGFAkkl.,jf321"
    );
  };

  const handleLambdaClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate(
      "/crg-stock-lambda/aksdl;fh4piou34-t90th=usdhij[fioa[ioAWEJ;KJ;65GF3HGaskd;lreHJGFAkkljf321"
    );
  };

  const handleLoadsClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate(
      "/crg-all-loads/lasedsoi659eyrgpoiut4q3w908ufa0eihrtaaisodwweiorouepgof;"
    );
  };

  const handleCreateExportClick = () => {
    navigate(
      `/crg-export-form/laskdjfewaioptjdfkl;gjfsg;jdsfksgsl'dajfkl,mds;m;`
    );
  };

  const handleViewExportsSentClick = () => {
    navigate(`/crg-export-!2s55x55x5erewlaerio15!£$$55fg`);
  };

  return (
    <div className="stockMainComponent">
      <div className="menuDashboard">
        <div className="menuDashboardClicks" onClick={handleLoadsClick}>
          <span>Create Load</span>
        </div>
        <div className="menuDashboardClicks" onClick={handleCreateExportClick}>
          <span>Create Export</span>
        </div>
        <div
          className="menuDashboardClicks"
          onClick={handleViewExportsSentClick}
        >
          <span>Exports Sent</span>
        </div>
      </div>

      <div className="menuDashboard">
        <div className="menuDashboardClicks" onClick={handleWeeeClick}>
          <span>Electronics</span>
        </div>

        <div className="menuDashboardClicks" onClick={handleStockCatsClick}>
          <span>Catalytics</span>
        </div>

        <div className="menuDashboardClicks" onClick={handleLambdaClick}>
          <span>Lambda</span>
        </div>
      </div>
    </div>
  );
};

export default CrgStockMain;
