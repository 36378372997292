import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../userAuth";
import "../../dashboard/export/export.css";

const StockLambda = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  const [lambdaStock, setLambdaStock] = useState([]);
  const [lambda, setLambda] = useState([]);
  const [loads, setLoads] = useState([]);
  const [selectedLoad, setSelectedLoad] = useState(null);
  const [exportsStock, setExportsStock] = useState([]);
  const [crgData, setCrgData] = useState(null);
  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  const fetchLambdaStock = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/lambda`);
      setLambdaStock(response.data[0].ceramicsCrg.indCeramicsCrg);
      setLambda(response.data);
      setCrgData(response.data[0]);
    } catch (error) {
      console.error("Error fetching lambda data:", error);
    }
  };

  const fetchExportsInStock = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/exports/lambdas/instock`);
      setExportsStock(response.data);
    } catch (error) {
      console.error("Error fetching export data:", error);
    }
  };

  const fetchLoadsInStock = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/loads/stock/lambda`);
      setLoads(response.data);
    } catch (error) {
      console.error("Error fetching loads data:", error);
    }
  };

  useEffect(() => {
    fetchLoadsInStock();
    fetchLambdaStock();
    fetchExportsInStock();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year

    // Pad single digits with leading zero
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const sumKilograms = () => {
    if (!crgData) return 0;
    return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
      return total + item.kilogramsCrg;
    }, 0);
  };

  const sumSpent = () => {
    if (!crgData) return 0;
    return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
      return total + item.usdSpentCrg;
    }, 0);
  };

  const handleAddToLoadLambda = async (item, loadId) => {
    if (!loadId) {
      alert("Please select a load first.");
      return;
    }
    try {
      await axios.put(
        `${baseUrl}/api/loads/lambda/assign-item/from-stock/${loadId}`,
        {
          entry: item,
        }
      );
      fetchLambdaStock();
      fetchLoadsInStock();
    } catch (error) {
      console.error("Error adding to load:", error);
    }
  };

  const handleLoadsClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate('/crg-all-loads/lasedsoi659eyrgpoiut4q3w908ufa0eihrtaaisodwweiorouepgof;');
  };

  const handleAssignLoad = async (loadId, exportId) => {
    if (!exportId) {
      alert("Please select an export before assigning.");
      return;
    }

    try {
      // Fetch the current Load document
      const currentLoad = loads.find((load) => load._id === loadId);
      if (!currentLoad) {
        alert("Load not found.");
        return;
      }
      // Fetch the new selected Export object
      const selectedExport = exportsStock.find((exp) => exp._id === exportId);
      if (!selectedExport) {
        alert("Invalid export selection.");
        return;
      }

      // Assign the Load to the new Export, sending both `loadId` and `load` value
      const exportUpdateResponse = await axios.put(
        `${baseUrl}/api/exports/${exportId}/assign-load`,
        { loadId: loadId, loadValue: currentLoad.load } // Pass load value
      );

      if (!exportUpdateResponse.data.success) {
        throw new Error(exportUpdateResponse.data.message);
      }

      // Update the Load with the new Export
      const loadUpdateResponse = await axios.put(
        `${baseUrl}/api/loads/${loadId}/assign-export`,
        {
          exportId: exportId,
          exportLoadName: selectedExport.load,
          shipped: true,
        }
      );

      if (!loadUpdateResponse.data.success) {
        throw new Error(loadUpdateResponse.data.message);
      }

      alert("Load successfully reassigned to Export!");

      fetchLoadsInStock();
      fetchLambdaStock();
      fetchExportsInStock();
    } catch (error) {
      console.error("Error reassigning load:", error);
      alert("Failed to reassign load.");
    }
  };

  const handleViewLoad = async (loadId) => {
    navigate(`/view-edit-single-load/${loadId}`);
  };

  const handleViewExport = (loadId) => {
    navigate(
      `/crg-export-load-details/asd6ar5e459843y4tr65j4hk6yu5y4usfd5g1a+f56ds4f+af/${loadId}`
    );
  };

  return (
    <div className="stock-second">
      {isLoggedIn && title === "Admin" && (
        <>
          <div className="stock-table-containers">
            <div className="h1HeadersStockDetails">
              Unassigned Lambda {sumKilograms().toFixed(2)} Kg ${sumSpent()}
            </div>
            <table className="tableStock">
              <thead className="tableStock">
                <tr className="tableStock">
                  <th className="table50Goal">Date</th>
                  <th className="table50Goal">Customer</th>
                  <th className="table50Goal">Spent</th>
                  <th className="table50Goal">Kg</th>
                  <th className="table50Goal">Gross</th>
                  <th className="table50Goal">Assign</th>
                </tr>
              </thead>
              <tbody className="tableBodyStock">
                {lambdaStock.map((item) => (
                  <tr className="inputStockDetails" key={item._id}>
                    <td className="inputStockDetails">
                      {formatDate(item.dateCrg)}
                    </td>
                    <td className="inputStockDetails">
                      {item.contactCrg?.name || item.assignedLoad?.load}
                    </td>
                    <td className="inputStockDetails">${item.usdSpentCrg}</td>
                    <td className="inputStockDetails">{item.kilogramsCrg}</td>
                    <td className="inputStockDetails">${item.usdValueCrg}</td>
                    <td className="inputStockDetails">
                      <select
                        className="exportDropdown"
                        onChange={(e) => setSelectedLoad(e.target.value)}
                        defaultValue={selectedLoad ? selectedLoad.load : ""}
                      >
                        <option value="">Select Load</option>
                        {loads
                          .filter((load) => load.material === "Lambda")
                          .map((load) => (
                            <option key={load._id} value={load.load}>
                              {load.load}
                            </option>
                          ))}
                      </select>
                      <button
                        onClick={() => {
                          const selectedLoadObject = loads.find(
                            (load) => load.load === selectedLoad
                          );
                          if (!selectedLoadObject) {
                            alert("Please select a valid load first.");
                            return;
                          }
                          handleAddToLoadLambda(item, selectedLoadObject._id); // Pass the correct _id
                        }}
                      >
                        Assign
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="stock-table-containers">
          <div
                  className="h1HeadersStockDetails"
                  onClick={handleLoadsClick}
                  style={{ cursor: "pointer" }}
                >
               Loads Lambda</div>
            <table className="tableStock">
              <thead className="tableStock">
                <tr className="tableStock">
                  <th className="table50Goal">Customer</th>
                  <th className="table50Goal">Spent</th>
                  <th className="table50Goal">Gross</th>
                  <th className="table50Goal">Kg</th>
                  <th className="table50Goal">Margin</th>
                  <th className="table50Goal">Pt</th>
                  <th className="table50Goal">Pd</th>
                  <th className="table50Goal">Rh</th>
                  <th className="table50Goal">Assign</th>
                </tr>
              </thead>
              <tbody className="tableBodyStock">
                {loads.map((lot) => {
                  // Calculate sums from lotsAll.indLots
                  const totalUsdSpent = lot.lotsAll?.indLots?.reduce(
                    (sum, item) => sum + (item.usdSpentInd || 0),
                    0
                  );

                  const totalUsdValue = lot.lotsAll?.indLots?.reduce(
                    (sum, item) => sum + (item.usdValueInd || 0),
                    0
                  );

                  const totalKilograms = lot.lotsAll?.indLots?.reduce(
                    (sum, item) => sum + (item.kilogramsInd || 0),
                    0
                  );

                  return (
                    <tr className="inputStockDetails" key={lot._id}>
                      <td className="inputStockDetails">{lot.load}</td>
                      <td className="inputStockDetails">
                        ${totalUsdSpent.toFixed(2)}
                      </td>
                      <td className="inputStockDetails">
                        ${totalUsdValue.toFixed(2)}
                      </td>
                      <td className="inputStockDetails">
                        {totalKilograms.toFixed(2)} kg
                      </td>
                      <td className="inputStockDetails">
                        {totalUsdSpent > 0
                          ? ((totalUsdValue / totalUsdSpent) * 100).toFixed(2) +
                            "%"
                          : "N/A"}
                      </td>
                      <td className="inputStockDetails">{lot.crgXrf.ptPpm}</td>
                      <td className="inputStockDetails">{lot.crgXrf.pdPpm}</td>
                      <td className="inputStockDetails">{lot.crgXrf.rhPpm}</td>
                      <td className="inputStockDetails">
                        {lot?.exportLoad && loads?.exportLoad?.load && (
                          <div className="loadLabel">{lot.exportLoad.load}</div>
                        )}
                        {
                          lot.material === "Lambda" &&
                        lot.crgXrf?.weight < 1 ? (
                          <button
                            className="viewButton"
                            onClick={() => handleViewLoad(lot._id)}
                          >
                            View
                          </button>
                        ) : (
                          <select
                            className="exportDropdown"
                            onChange={(e) =>
                              handleAssignLoad(lot._id, e.target.value)
                            }
                            defaultValue={
                              lot.exportLoad ? lot.exportLoad._id : ""
                            }
                          >
                            <option value="">Select Export</option>
                            {exportsStock.map((exp) => (
                              <option key={exp._id} value={exp._id}>
                                {exp.load}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {exportsStock.map((exportItem) => {
            // Ensure `lotsAll.indLots` is an array before mapping
            const lots = exportItem?.lotsAll?.indLots || [];

            // Compute total kilograms and total USD spent for the current export item
            const totalKilograms = lots.reduce(
              (sum, lot) => sum + (lot.kilogramsInd || 0),
              0
            );
            const totalUsdSpent = lots.reduce(
              (sum, lot) => sum + (lot.usdSpentInd || 0),
              0
            );

            return (
              <div key={exportItem._id} className="stock-table-containers">
                <div
                  className="h1HeadersStockDetails"
                  onClick={() => handleViewExport(exportItem._id)}
                  style={{ cursor: "pointer" }}
                >
                  {exportItem.material} {exportItem.load} Stock{" "}
                  {totalKilograms.toFixed(0)} Kg ${totalUsdSpent.toFixed(2)}
                </div>

                <table className="tableStock">
                  <thead className="tableStock">
                    <tr className="tableStock">
                      <th className="table50Goal">Load</th>
                      <th className="table50Goal">Spent</th>
                      <th className="table50Goal">Gross</th>
                      <th className="table50Goal">Margin</th>
                      <th className="table50Goal">Kg</th>
                      <th className="table50Goal">Pt</th>
                      <th className="table50Goal">Pd</th>
                      <th className="table50Goal">Rh</th>
                      <th className="table50Goal">View</th>
                    </tr>
                  </thead>
                  <tbody className="tableBodyStock">
                    {lots.map((lot, index) => {
                      const assignedLoad = lot.assignedLoad?.id; // The populated Load document

                      return (
                        <tr
                          className="inputStockDetails"
                          key={lot._id || index}
                        >
                          <td className="inputStockDetails">
                            {assignedLoad ? assignedLoad.load : "N/A"}
                          </td>
                          <td className="inputStockDetails">
                            ${lot.usdSpentInd}
                          </td>
                          <td className="inputStockDetails">
                            ${lot.usdValueInd}
                          </td>
                          <td className="inputStockDetails">
                            {lot.usdSpentInd
                              ? (
                                  (lot.usdValueInd / lot.usdSpentInd) *
                                  100
                                ).toFixed(2)
                              : "N/A"}
                            %
                          </td>
                          <td className="inputStockDetails">
                            {lot.kilogramsInd}
                          </td>
                          <td className="inputStockDetails">
                            {assignedLoad?.crgXrf?.ptPpm || "N/A"}
                          </td>
                          <td className="inputStockDetails">
                            {assignedLoad?.crgXrf?.pdPpm || "N/A"}
                          </td>
                          <td className="inputStockDetails">
                            {assignedLoad?.crgXrf?.rhPpm || "N/A"}
                          </td>
                          <td className="inputStockDetails">
                            <button
                              className="viewButton"
                              onClick={() => handleViewLoad(assignedLoad?._id)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default StockLambda;
