import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../userAuth";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faComputer,
  faCar,
  faChartLine,
  faCalculator,
  faPhone,
  faPerson,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import "./contactDetails.css";

Modal.setAppElement("#root");

const ContactDetails = () => {
  const { isLoggedIn } = useAuth();
  const [showCeramicInputs, setShowCeramicInputs] = useState(false);
  const [showWeeeInputs, setShowWeeeInputs] = useState(false);
  const [showLambdaInputs, setShowLambdaInputs] = useState(false);
  const [showBorrowedInputs, setShowBorrowedInputs] = useState(false);
  const [showSteelInputs, setShowSteelInputs] = useState(false);
  const [showDPFInputs, setShowDPFInputs] = useState(false);
  const [showPPMInputs, setShowPPMInputs] = useState(false);
  const [showLmeInputs, setShowLmeInputs] = useState(false);
  const [showLmeLambdaInputs, setShowLmeLambdaInputs] = useState(false);
  const [weeeKgInput, setWeeeKgInput] = useState("");
  const [usdSpentWeeeInput, setUsdSpentWeeeInput] = useState("");
  const [lambdaKgInput, setLambdaKgInput] = useState("");
  const [usdSpentLambdaInput, setUsdSpentLambdaInput] = useState("");
  const [ceramicsKgInput, setCeramicsKgInput] = useState("");
  const [usdSpentCeramicsInput, setUsdSpentCeramicsInput] = useState("");
  const [steelKgInput, setSteelKgInput] = useState("");
  const [usdSpentSteelInput, setUsdSpentSteelInput] = useState("");
  const [dpfKgInput, setDpfKgInput] = useState("");
  const [usdSpentDpfInput, setUsdSpentDpfInput] = useState("");
  const [borrowedInput, setBorrowedInput] = useState("");
  const [returnedInput, setReturnedInput] = useState("");
  const borrowedValue = parseFloat(borrowedInput) || 0;
  const returnedValue = parseFloat(returnedInput) || 0;
  const location = useLocation();
  const userInfo = location.state?.userInfo || {};
  const userPosition = location.state?.userInfo?.position;
  const title = localStorage.title;
  const [lambdaUsdValueInput, setLambdaUsdValueInput] = useState("");
  const [weeeUsdValueInput, setWeeeUsdValueInput] = useState("");
  const [ceramicsUsdValueInput, setCeramicsUsdValueInput] = useState("");
  const [steelUsdValueInput, setSteelUsdValueInput] = useState("");
  const [dpfUsdValueInput, setDpfUsdValueInput] = useState("");
  const [ceramicsPlatinumPpmInput, setCeramicsPlatinumPpmInput] = useState("");
  const [ceramicsPalladiumPpmInput, setCeramicsPalladiumPpmInput] =
    useState("");
  const [ceramicsRhodiumPpmInput, setCeramicsRhodiumPpmInput] = useState("");
  const [weeeData, setWeeeData] = useState([]);
  const [lambdaData, setLambdaData] = useState([]);
  const [ceramicsData, setCeramicsData] = useState([]);
  const [isEditingCatalogue, setIsEditingCatalogue] = useState(false);
  const [editedCatalogue, setEditedCatalogue] = useState("NA");
  const [contact, setContact] = useState({});
  const [newNote, setNewNote] = useState("");
  const [editedMaterialType, setEditedMaterialType] = useState("");
  const [showContactModal, setShowContactModal] = useState(false);
  const [isEditingNextContact, setIsEditingNextContact] = useState(false);
  const [nextContactDate, setNextContactDate] = useState("");
  const [isEditingTypeOfContact, setIsEditingTypeOfContact] = useState(false);
  const [editedTypeOfContact, setEditedTypeOfContact] = useState("");
  const [loading, setLoading] = useState(false);
  const [loads, setLoads] = useState([]);
  const [ptLmeInput, setPtLmeInput] = useState("");
  const [pdLmeInput, setPdLmeInput] = useState("");
  const [rhLmeInput, setRhLmeInput] = useState("");
  const [selectedLoadId, setSelectedLoadId] = useState({ id: "", load: "" });
  const [isEditingField, setIsEditingField] = useState({
    name: false,
    address: false,
    number: false,
    city: false,
  });
  const [editedFields, setEditedFields] = useState({
    name: "",
    address: "",
    number: "",
    city: "",
  });
  const [contacts, setContacts] = useState([]);
  const [isEditingMaterialType, setIsEditingMaterialType] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  const { id } = useParams();
  const egyptianGovernorates = [
    "Cairo",
    "Alexandria",
    "Giza",
    "Dakahlia",
    "Red Sea",
    "Beheira",
    "Fayoum",
    "Gharbia",
    "Ismailia",
    "Menofia",
    "Minya",
    "Qaliubiya",
    "New Valley",
    "Suez",
    "Aswan",
    "Assiut",
    "Beni Suef",
    "Port Said",
    "Damietta",
    "Sharkia",
    "South Sinai",
    "Kafr Al sheikh",
    "Matrouh",
    "Luxor",
    "Qena",
    "North Sinai",
    "Sohag"
  ];
  
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';


// Function to calculate the expected kilograms based on historical data
const calculateExpectedKg = (purchases) => {
  // Assuming purchases is an array of objects with 'kilograms' field
  const totalKg = purchases.reduce(
    (total, purchase) => total + purchase.kilograms,
    0
  );
  return totalKg / purchases.length; // Average kilograms per purchase
};

const calculateFrequency = (purchases) => {
  if (!purchases || purchases.length <= 1) return 30; // Default to 30 days if there's only one or no purchase

  const startDate = new Date(purchases[0].date);
  const endDate = new Date(purchases[purchases.length - 1].date);

  const daysDifference =
    Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24); // Convert milliseconds to days

  const averageFrequencyInDays = daysDifference / (purchases.length - 1); // Divide by number of purchases minus one

  return averageFrequencyInDays;
};

const predictNextPurchaseDate = (
  lastPurchaseDate,
  averageFrequencyInDays
) => {
  const currentDate = new Date(); // Get the current date
  const oneMonthAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    currentDate.getDate()
  );
  if (!lastPurchaseDate || !averageFrequencyInDays) return oneMonthAgo;

  const nextPurchaseDate = new Date(lastPurchaseDate);
  nextPurchaseDate.setDate(
    nextPurchaseDate.getDate() + Math.round(averageFrequencyInDays)
  ); // Round to the nearest whole number of days
  return nextPurchaseDate.toLocaleDateString();
};

const fetchContactDetails = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/contacts/${id}`);
    setContact(response.data);

    // Extract and update state
    const weeeData = response.data?.weee?.indWeeePurchases || [];
    const lambdaData = response.data?.lambda?.indLambdaPurchases || [];
    const ceramicsData = response.data?.ceramics?.indCeramicsPurchases || [];

    setWeeeData(weeeData);
    setLambdaData(lambdaData);
    setCeramicsData(ceramicsData);
  } catch (error) {
    console.error("Error fetching contact details:", error);
  }
};

useEffect(() => {
  fetchContactDetails(); // Fetch initially
}, [id, baseUrl]); // Runs when `id` or `baseUrl` chang

const handleAddContact = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/users`);
    setContacts(response.data);
    setShowContactModal(true);
  } catch (error) {
    console.error("Error fetching contacts:", error);
  }
};

const handleAssociateContact = async (userId) => {
  try {
    // Fetch the user details
    const userResponse = await axios.get(`${baseUrl}/api/users/${userId}`);
    const userName = userResponse.data.name;

    // Send a request to associate the user with the selected contact
    await axios.put(`${baseUrl}/api/user/addcontact/${userId}`, {
      contactId: id,
      userName,
    });

    setShowContactModal(false);

    // Fetch the updated contact details
    const response = await axios.get(`${baseUrl}/api/contacts/${id}`);
    setContact(response.data);
  } catch (error) {
    console.error("Error associating contact with user:", error);
  }
};

const handleLoadChange = (e) => {
  const selectedLoad = loads.find((load) => load._id === e.target.value);
  if (selectedLoad) {
    setSelectedLoadId({ id: selectedLoad._id, load: selectedLoad.load });
  } else {
    setSelectedLoadId({ id: "", load: "" });
  }
};

const handleSaveNote = async () => {
  try {
    setLoading(true);
    const currentDate = new Date();

    const updatedNotes = newNote.trim()
      ? [
          ...contact.notes,
          {
            content: `${newNote} (${
              userInfo && userInfo.name ? userInfo.name : "Admin"
            })`,
            createdAt: currentDate,
          },
        ]
      : contact.notes; // Keep the existing notes unchanged
    // Update the contact with the new note
    await axios.put(`${baseUrl}/api/contacts/${id}`, {
      notes: updatedNotes,
      lastContact: currentDate,
      weee: await updateWeee(),
      lambda: await updateLambda(),
      ceramics: await updateCeramics(),
      debt:
        (contact.debt || 0) +
        (parseFloat(borrowedInput) || 0) -
        (parseFloat(returnedInput) || 0),
    });

    // Fetch the updated contact details
    const response = await axios.get(`${baseUrl}/api/contacts/${id}`);
    setContact(response.data);

    // Fetch the corresponding CRG document
    const crgResponse = await axios.get(`${baseUrl}/api/crg`);
    const crgData = crgResponse.data || {};

    // Prepare data to be sent to CRG
    const crgUpdateData = {
      weeeCrg: updateWeeeCrg(crgData.weeeCrg),
      lambdaCrg: updateLambdaCrg(crgData.lambdaCrg),
      ceramicsCrg: updateCeramicsCrg(crgData.ceramicsCrg),
      debtCrg: updateDebtCrg(crgData.debtCrg),
      debtCrgTotalUsd:
        (crgData.debtCrgTotalUsd || 0) +
        (parseFloat(borrowedInput) || 0) -
        (parseFloat(returnedInput) || 0),
    };

    // Update the CRG document
    await axios.put(`${baseUrl}/api/crg/contact-details`, crgUpdateData);

    // ✅ Create a new ceramic entry from frontend inputs
    const selectedEntries = [];

    if (parseFloat(ceramicsKgInput) > 0 || parseFloat(steelKgInput) > 0) {
      selectedEntries.push({
        kilogramsCrg: parseFloat(ceramicsKgInput) || 0,
        usdValueCrg: parseFloat(ceramicsUsdValueInput) || 0,
        usdSpentCrg: parseFloat(usdSpentCeramicsInput) || 0,
        kilogramsSteelCrg: parseFloat(steelKgInput) || 0,
        usdValueSteelCrg: parseFloat(steelUsdValueInput) || 0,
        usdSpentSteelCrg: parseFloat(usdSpentSteelInput) || 0,
        kilogramsDpfCrg: parseFloat(dpfKgInput) || 0,
        usdValueDpfCrg: parseFloat(dpfUsdValueInput) || 0,
        usdSpentDpfCrg: parseFloat(usdSpentDpfInput) || 0,
        ptLme: parseFloat(ptLmeInput) || 0,
        pdLme: parseFloat(pdLmeInput) || 0,
        rhLme: parseFloat(rhLmeInput) || 0,
        dateCrg: currentDate,
        ptPpm: parseFloat(ceramicsPlatinumPpmInput) || 0,
        pdPpm: parseFloat(ceramicsPalladiumPpmInput) || 0,
        rhPpm: parseFloat(ceramicsRhodiumPpmInput) || 0,
        contactCrg: {
          id: contact.id,
          name: contact.name,
        },
      });
    }

    if (parseFloat(weeeKgInput) > 0) {
      selectedEntries.push({
        kilogramsCrg: parseFloat(weeeKgInput) || 0,
        usdValueCrg: parseFloat(weeeUsdValueInput) || 0,
        usdSpentCrg: parseFloat(usdSpentWeeeInput) || 0,
        dateCrg: currentDate,
        ptPpm: 0, // Adjust if needed
        pdPpm: 0,
        rhPpm: 0,
        contactCrg: {
          id: contact.id,
          name: contact.name,
        },
      });
    }

    if (parseFloat(lambdaKgInput) > 0) {
      selectedEntries.push({
        kilogramsCrg: parseFloat(lambdaKgInput) || 0,
        usdValueCrg: parseFloat(lambdaUsdValueInput) || 0,
        usdSpentCrg: parseFloat(usdSpentLambdaInput) || 0,
        dateCrg: currentDate,
        ptPpm: parseFloat(ceramicsPlatinumPpmInput) || 0,
        pdPpm: parseFloat(ceramicsPalladiumPpmInput) || 0,
        rhPpm: parseFloat(ceramicsRhodiumPpmInput) || 0,
        ptLme: parseFloat(ptLmeInput) || 0,
        pdLme: parseFloat(pdLmeInput) || 0,
        rhLme: parseFloat(rhLmeInput) || 0,
        contactCrg: {
          id: contact.id,
          name: contact.name,
        },
      });
    }

    // ⚠️ Show a warning if more than one input is greater than 0
    if (selectedEntries.length > 1) {
      alert(
        "Only one type of material can be processed at a time. Please select only one."
      );
    } else if (selectedEntries.length === 1 && selectedLoadId.id) {
      // ✅ Send only the valid entry
      await axios.put(`${baseUrl}/api/load/${selectedLoadId.id}`, {
        entry: selectedEntries[0], // Send only one entry
      });
    }

    // Clear input fields
    setNewNote("");
    setWeeeKgInput("");
    setLambdaKgInput("");
    setCeramicsKgInput("");
    setSteelKgInput("");
    setDpfKgInput("");
    setCeramicsUsdValueInput("");
    setDpfUsdValueInput("");
    setSteelUsdValueInput("");
    setCeramicsPlatinumPpmInput("");
    setCeramicsPalladiumPpmInput("");
    setCeramicsRhodiumPpmInput("");
    setUsdSpentCeramicsInput("");
    setUsdSpentDpfInput("");
    setUsdSpentSteelInput("");
    setWeeeUsdValueInput("");
    setUsdSpentWeeeInput("");
    setLambdaUsdValueInput("");
    setUsdSpentLambdaInput("");
    setBorrowedInput("");
    setReturnedInput("");
    setLoads([]);
    setPtLmeInput("");
    setPdLmeInput("");
    setRhLmeInput("");
    setSelectedLoadId({ id: "", load: "" });

    // Fetch updated contact details again
    const updatedResponse = await axios.get(`${baseUrl}/api/contacts/${id}`);
    setContact(updatedResponse.data);

    // Calculate and update next purchase date
    const lastCeramicsPurchaseDate =
      updatedResponse.data.ceramics?.indCeramicsPurchases.length > 0
        ? updatedResponse.data.ceramics?.indCeramicsPurchases[
            updatedResponse.data.ceramics?.indCeramicsPurchases.length - 1
          ].date
        : null;
    const averageFrequency = calculateFrequency(
      updatedResponse.data.ceramics?.indCeramicsPurchases
    );
    const nextPurchaseDate = predictNextPurchaseDate(
      lastCeramicsPurchaseDate,
      averageFrequency
    );

    await axios.put(`${baseUrl}/api/contacts/${id}`, {
      nextPurchase: nextPurchaseDate,
    });

    await fetchContactDetails();

    setLoading(false);
  } catch (error) {
    console.error("Error adding note:", error);
    setLoading(false);
  }
};

const updateWeee = () => {
  const weee = contact.weee;

  if (weee && weeeKgInput.trim() !== "") {
    return {
      kilogram: (weee.kilogram || 0) + parseFloat(weeeKgInput),
      purchases: (weee.purchases || 0) + 1,
      indWeeePurchases: [
        ...weee.indWeeePurchases,
        {
          kilograms: parseFloat(weeeKgInput),
          usdValue: parseFloat(weeeUsdValueInput),
          usdSpent: parseFloat(usdSpentWeeeInput),
          assignedLoad: selectedLoadId.id
            ? { id: selectedLoadId.id, load: selectedLoadId.load }
            : null,
        },
      ],
    };
  }
  return weee;
};

const updateLambda = () => {
  const lambda = contact.lambda;

  if (lambda && lambdaKgInput.trim() !== "") {
    return {
      kilogram: (lambda.kilogram || 0) + parseFloat(lambdaKgInput),
      purchases: (lambda.purchases || 0) + 1,
      indLambdaPurchases: [
        ...lambda.indLambdaPurchases,
        {
          kilograms: parseFloat(lambdaKgInput),
          usdValue: parseFloat(lambdaUsdValueInput),
          usdSpent: parseFloat(usdSpentLambdaInput),
          ptPpm: parseFloat(ceramicsPlatinumPpmInput) || 0,
          pdPpm: parseFloat(ceramicsPalladiumPpmInput) || 0,
          rhPpm: parseFloat(ceramicsRhodiumPpmInput) || 0,
          ptLme: parseFloat(ptLmeInput) || 0,
          pdLme: parseFloat(pdLmeInput) || 0,
          rhLme: parseFloat(rhLmeInput) || 0,
          assignedLoad: selectedLoadId.id
            ? { id: selectedLoadId.id, load: selectedLoadId.load }
            : null,
        },
      ],
    };
  }
  return lambda;
};

const updateCeramics = () => {
  const ceramics = contact.ceramics;

  if (
    ceramics &&
    (ceramicsKgInput.trim() !== "" ||
      steelKgInput.trim() !== "" ||
      dpfKgInput.trim() !== "")
  ) {
    return {
      kilogram: (ceramics.kilogram || 0) + (parseFloat(ceramicsKgInput) || 0),
      kilogramSteel:
        (ceramics.kilogramSteel || 0) + (parseFloat(steelKgInput) || 0),
      kilogramDpf:
        (ceramics.kilogramDpf || 0) + (parseFloat(dpfKgInput) || 0),
      purchases: (ceramics.purchases || 0) + 1,
      indCeramicsPurchases: [
        ...ceramics.indCeramicsPurchases,
        {
          kilograms: parseFloat(ceramicsKgInput) || 0,
          usdValue: parseFloat(ceramicsUsdValueInput) || 0,
          usdSpent: parseFloat(usdSpentCeramicsInput) || 0,
          kilogramsSteel: parseFloat(steelKgInput) || 0,
          usdValueSteel: parseFloat(steelUsdValueInput) || 0,
          usdSpentSteel: parseFloat(usdSpentSteelInput) || 0,
          kilogramsDpf: parseFloat(dpfKgInput) || 0,
          usdValueDpf: parseFloat(dpfUsdValueInput) || 0,
          usdSpentDpf: parseFloat(usdSpentDpfInput) || 0,
          ptPpm: parseFloat(ceramicsPlatinumPpmInput) || 0,
          pdPpm: parseFloat(ceramicsPalladiumPpmInput) || 0,
          rhPpm: parseFloat(ceramicsRhodiumPpmInput) || 0,
          ptLme: parseFloat(ptLmeInput),
          pdLme: parseFloat(pdLmeInput),
          rhLme: parseFloat(rhLmeInput),
          assignedLoad: selectedLoadId.id
            ? { id: selectedLoadId.id, load: selectedLoadId.load }
            : null,
        },
      ],
    };
  }
  return ceramics;
};

const updateWeeeCrg = (weeeCrg) => {
  if (weeeCrg && weeeKgInput.trim() !== "") {
    return {
      kilogramCrg: (weeeCrg.kilogramCrg || 0) + parseFloat(weeeKgInput),
      purchasesCrg: (weeeCrg.purchasesCrg || 0) + 1,
      usdValueCrg: (weeeCrg.usdValueCrg || 0) + parseFloat(weeeUsdValueInput),
      usdSpentCrg: (weeeCrg.usdSpentCrg || 0) + parseFloat(usdSpentWeeeInput),
      indWeeeCrg: [
        ...weeeCrg.indWeeeCrg,
        {
          kilogramsCrg: parseFloat(weeeKgInput),
          usdValueCrg: parseFloat(weeeUsdValueInput),
          usdSpentCrg: parseFloat(usdSpentWeeeInput),
          dateCrg: new Date(),
          contactCrg: {
            id: contact._id,
            name: contact.name,
          },
          assignedLoad: selectedLoadId.id
            ? { id: selectedLoadId.id, load: selectedLoadId.load }
            : null,
        },
      ],
    };
  }
  return weeeCrg;
};

const updateLambdaCrg = (lambdaCrg) => {
  if (lambdaCrg && lambdaKgInput.trim() !== "") {
    return {
      kilogramCrg: (lambdaCrg.kilogramCrg || 0) + parseFloat(lambdaKgInput),
      purchasesCrg: (lambdaCrg.purchasesCrg || 0) + 1,
      usdValueCrg:
        (lambdaCrg.usdValueCrg || 0) + parseFloat(lambdaUsdValueInput),
      usdSpentCrg:
        (lambdaCrg.usdSpentCrg || 0) + parseFloat(usdSpentLambdaInput),
      indLambdaCrg: [
        ...lambdaCrg.indLambdaCrg,
        {
          kilogramsCrg: parseFloat(lambdaKgInput),
          usdValueCrg: parseFloat(lambdaUsdValueInput),
          usdSpentCrg: parseFloat(usdSpentLambdaInput),
          ptPpm: parseFloat(ceramicsPlatinumPpmInput) || 0,
          pdPpm: parseFloat(ceramicsPalladiumPpmInput) || 0,
          rhPpm: parseFloat(ceramicsRhodiumPpmInput) || 0,
          ptLme: parseFloat(ptLmeInput) || 0,
          pdLme: parseFloat(pdLmeInput) || 0,
          rhLme: parseFloat(rhLmeInput) || 0,
          dateCrg: new Date(),
          contactCrg: {
            id: contact._id,
            name: contact.name,
          },
          assignedLoad: selectedLoadId.id
            ? { id: selectedLoadId.id, load: selectedLoadId.load }
            : null,
        },
      ],
    };
  }
  return lambdaCrg;
};

const updateCeramicsCrg = (ceramicsCrg) => {
  if (
    ceramicsCrg &&
    (ceramicsKgInput.trim() !== "" ||
      steelKgInput.trim() !== "" ||
      dpfKgInput.trim() !== "")
  ) {
    return {
      kilogramCrg:
        (ceramicsCrg.kilogramCrg || 0) + (parseFloat(ceramicsKgInput) || 0),
      kilogramSteelCrg:
        (ceramicsCrg.kilogramSteelCrg || 0) + (parseFloat(steelKgInput) || 0),
      kilogramDpfCrg:
        (ceramicsCrg.kilogramDpfCrg || 0) + (parseFloat(dpfKgInput) || 0),
      purchasesCrg: (ceramicsCrg.purchasesCrg || 0) + 1,
      usdValueCrg:
        (ceramicsCrg.usdValueCrg || 0) +
        (parseFloat(ceramicsUsdValueInput) || 0),
      usdValueSteelCrg:
        (ceramicsCrg.usdValueSteelCrg || 0) +
        (parseFloat(steelUsdValueInput) || 0),
      usdValueDpfCrg:
        (ceramicsCrg.usdValueDpfCrg || 0) +
        (parseFloat(dpfUsdValueInput) || 0),
      usdSpentCrg:
        (ceramicsCrg.usdSpentCrg || 0) +
        (parseFloat(usdSpentCeramicsInput) || 0),
      usdSpentSteelCrg:
        (ceramicsCrg.usdSpentSteelCrg || 0) +
        (parseFloat(usdSpentSteelInput) || 0),
      usdSpentDpfCrg:
        (ceramicsCrg.usdSpentDpfCrg || 0) +
        (parseFloat(usdSpentDpfInput) || 0),
      indCeramicsCrg: [
        ...ceramicsCrg.indCeramicsCrg,
        {
          kilogramsCrg: parseFloat(ceramicsKgInput) || 0,
          usdValueCrg: parseFloat(ceramicsUsdValueInput) || 0,
          usdSpentCrg: parseFloat(usdSpentCeramicsInput) || 0,
          kilogramsSteelCrg: parseFloat(steelKgInput) || 0,
          usdValueSteelCrg: parseFloat(steelUsdValueInput) || 0,
          usdSpentSteelCrg: parseFloat(usdSpentSteelInput) || 0,
          kilogramsDpfCrg: parseFloat(dpfKgInput) || 0,
          usdValueDpfCrg: parseFloat(dpfUsdValueInput) || 0,
          usdSpentDpfCrg: parseFloat(usdSpentDpfInput) || 0,
          ptPpm: parseFloat(ceramicsPlatinumPpmInput) || 0,
          pdPpm: parseFloat(ceramicsPalladiumPpmInput) || 0,
          rhPpm: parseFloat(ceramicsRhodiumPpmInput) || 0,
          ptLme: parseFloat(ptLmeInput),
          pdLme: parseFloat(pdLmeInput),
          rhLme: parseFloat(rhLmeInput),
          dateCrg: new Date(),
          contactCrg: {
            id: contact._id,
            name: contact.name,
          },
          assignedLoad: selectedLoadId.id
            ? { id: selectedLoadId.id, load: selectedLoadId.load }
            : null,
        },
      ],
    };
  }
  return ceramicsCrg;
};

const updateDebtCrg = (debtCrg) => {
  if (borrowedValue !== 0 || returnedValue !== 0) {
    const newDebtEntry = {
      usdValueCrg: borrowedValue - returnedValue,
      contactCrg: {
        id: contact._id,
        name: contact.name,
      },
    };
    return [...debtCrg, newDebtEntry];
  }

  return debtCrg;
};

const handleSaveNextContact = async () => {
  try {
    if (!nextContactDate) {
      console.warn("Next contact date cannot be empty.");
      return;
    }

    const updatedContact = await axios.put(`${baseUrl}/api/contacts/${id}`, {
      nextContact: new Date(nextContactDate).toISOString(),
    });

    setContact(updatedContact.data);
    setIsEditingNextContact(false);
  } catch (error) {
    console.error("Error updating next contact date:", error);
  }
};

const handleEditField = (fieldName) => {
  setIsEditingField((prev) => ({ ...prev, [fieldName]: true }));
  setEditedFields((prev) => ({ ...prev, [fieldName]: contact[fieldName] }));
};

const handleSaveField = async (fieldName) => {
  try {
    if (editedFields[fieldName] === "") {
      console.warn(`${fieldName} cannot be empty.`);
      return;
    }

    await axios.put(`${baseUrl}/api/contacts/${id}`, {
      [fieldName]: editedFields[fieldName],
    });

    setIsEditingField((prev) => ({ ...prev, [fieldName]: false }));
    setContact((prev) => ({ ...prev, [fieldName]: editedFields[fieldName] }));
  } catch (error) {
    console.error(`Error updating ${fieldName}:`, error);
  }
};

const handleSaveTypeOfContact = async () => {
  try {
    if (editedTypeOfContact === "") {
      console.warn("Type of contact cannot be empty.");
      return;
    }

    // Assuming you want to restrict the typeOfContact to 'Call' or 'Visit'
    if (
      editedTypeOfContact !== "Call" &&
      editedTypeOfContact !== "Visit" &&
      editedTypeOfContact !== "Delivery"
    ) {
      console.warn("Invalid type of contact.");
      return;
    }

    await axios.put(`${baseUrl}/api/contacts/${id}`, {
      typeOfContact: editedTypeOfContact,
    });

    setIsEditingTypeOfContact(false);
    setContact((prev) => ({ ...prev, typeOfContact: editedTypeOfContact }));
  } catch (error) {
    console.error("Error updating type of contact:", error);
  }
};

const handleNoAnswer = async () => {
  try {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Increment the noAnswerCount
    await axios.put(`${baseUrl}/api/contacts/${id}`, {
      nextContact: tomorrow.toISOString(),
      notes: [
        ...contact.notes,
        { content: "No Contact", createdAt: new Date() },
      ],
      noAnswerCount: contact.noAnswerCount + 1,
    });

    // Fetch the updated contact details
    const response = await axios.get(`${baseUrl}/api/contacts/${id}`);
    setContact(response.data);

    // Check if noAnswerCount is 5 or more
    if (response.data.noAnswerCount >= 5) {
      // If yes, add the contact to the assigned user's lostCustomers field
      const userId = response.data.user ? response.data.user.id : null;
      if (userId) {
        await axios.put(
          `${baseUrl}/api/users/${userId}/addlostcustomer/${id}`
        );
      }
    }
    navigate("/contact-list", { state: { userInfo } });
  } catch (error) {
    console.error("Error handling no answer:", error);
  }
};

const handleCreateLoad = async () => {
  navigate("/loads-main", { state: { userInfo } });
};

const renderMaterialIcon = (materialType) => {
  switch (materialType) {
    case "Cats":
      return <FontAwesomeIcon className="iconMaterial" icon={faCar} />;
    case "Weee":
      return <FontAwesomeIcon className="iconMaterial" icon={faComputer} />;
    case "Lambda":
      return <FontAwesomeIcon className="iconMaterial" icon={faPlug} />;
    case "Cats/Lambda":
      return (
        <>
          <FontAwesomeIcon className="iconMaterial" icon={faCar} />
          <FontAwesomeIcon className="iconMaterial" icon={faPlug} />
        </>
      );
    case "Weee/Cats":
      return (
        <>
          <FontAwesomeIcon className="iconMaterial" icon={faCar} />
          <FontAwesomeIcon className="iconMaterial" icon={faComputer} />
        </>
      );
    default:
      return null;
  }
};

const handleSaveMaterialType = async () => {
  try {
    if (
      typeof editedMaterialType === "undefined" ||
      editedMaterialType === ""
    ) {
      console.warn("Material type cannot be empty.");
      return;
    }

    await axios.put(`${baseUrl}/api/contacts/${id}`, {
      materialType: editedMaterialType,
    });

    setIsEditingMaterialType(false);
    setContact((prev) => ({ ...prev, materialType: editedMaterialType }));
  } catch (error) {
    console.error("Error updating material type:", error);
  }
};

const handleSaveCatalogue = async () => {
  try {
    if (editedCatalogue === "") {
      console.warn("Catalogue cannot be empty.");
      return;
    }

    await axios.put(`${baseUrl}/api/contacts/${id}`, {
      catalogue: editedCatalogue,
    });

    setIsEditingCatalogue(false);
    setContact((prev) => ({ ...prev, catalogue: editedCatalogue }));
  } catch (error) {
    console.error("Error updating catalogue:", error);
  }
};

const getGoogleMapsLink = (address) => {
  if (address && address.includes("°")) {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps?q=${encodedAddress}`;
  }
  return null;
};

// Fetch loads from backend
const fetchLoads = async () => {
  try {
    setLoads([]); // Clear old loads before fetching
    const response = await fetch(`${baseUrl}/api/loads/stock`);
    const data = await response.json();
    setLoads(data);
  } catch (error) {
    console.error("Error fetching loads:", error);
  }
};

// Fetch loads on mount
useEffect(() => {
  fetchLoads();
}, []);

return (
  <div className="contactDetailsMain">
    <div className="contactDetailsSecond">
      <div className="contactDetailsBoxColumn">
        {title !== "Admin" && (
          <div className="contactDetailsBox">
            {contact.name}{" "}
            <FontAwesomeIcon className="editIcon" icon={faPerson} />
          </div>
        )}

        {title === "Admin" && (
          <div className="contactDetailsBox">
            <strong>Name:</strong>{" "}
            {isEditingField.name ? (
              <div>
                <input
                  type="text"
                  value={editedFields.name}
                  onChange={(e) =>
                    setEditedFields((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
                <button onClick={() => handleSaveField("name")}>Save</button>
              </div>
            ) : (
              <>
                {contact.name}{" "}
                <FontAwesomeIcon
                  className="editIcon"
                  icon={faEdit}
                  onClick={() => handleEditField("name")}
                />
              </>
            )}
          </div>
        )}

        {title !== "Admin" && (
          <div className="contactDetailsBox">
            {contact.number}{" "}
            <FontAwesomeIcon className="editIcon" icon={faPhone} />
          </div>
        )}

        {title === "Admin" && (
          <div className="contactDetailsBox">
            <strong>Phone:</strong>{" "}
            {isEditingField.number ? (
              <div>
                <input
                  type="text"
                  value={editedFields.number}
                  onChange={(e) =>
                    setEditedFields((prev) => ({
                      ...prev,
                      number: e.target.value,
                    }))
                  }
                />
                <button onClick={() => handleSaveField("number")}>
                  Save
                </button>
              </div>
            ) : (
              <>
                {contact.number}{" "}
                <FontAwesomeIcon
                  className="editIcon"
                  icon={faEdit}
                  onClick={() => handleEditField("number")}
                />
              </>
            )}
          </div>
        )}
      </div>

      <div className="contactDetailsBoxColumn">
        <div className="contactDetailsBox">
          <strong>Address:</strong>{" "}
          {isEditingField.address ? (
            <div>
              <input
                type="text"
                value={editedFields.address}
                onChange={(e) =>
                  setEditedFields((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }
              />
              <button onClick={() => handleSaveField("address")}>Save</button>
            </div>
          ) : (
            <>
              {getGoogleMapsLink(contact.address) ? (
                <a
                  href={getGoogleMapsLink(contact.address)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {contact.address}
                </a>
              ) : (
                contact.address
              )}
              <FontAwesomeIcon
                className="editIcon"
                icon={faEdit}
                onClick={() => handleEditField("address")}
              />
            </>
          )}
        </div>
        <div className="contactDetailsBox">
          <strong>City:</strong>{" "}
          {isEditingField.city ? (
            <div>
              <select
                value={editedFields.city}
                onChange={(e) =>
                  setEditedFields((prev) => ({
                    ...prev,
                    city: e.target.value,
                  }))
                }
              >
                {egyptianGovernorates.map((governorate) => (
                  <option key={governorate} value={governorate}>
                    {governorate}
                  </option>
                ))}
              </select>
              <button onClick={() => handleSaveField("city")}>Save</button>
            </div>
          ) : (
            <>
              {contact.city}{" "}
              <FontAwesomeIcon
                className="editIcon"
                icon={faEdit}
                onClick={() => handleEditField("city")}
              />
            </>
          )}
        </div>
      </div>
      <div className="contactDetailsBoxColumn">
        <div className="contactDetailsBox">
          <strong>Next:</strong>{" "}
          {isEditingNextContact ? (
            <div>
              <input
                type="date"
                value={nextContactDate}
                onChange={(e) => setNextContactDate(e.target.value)}
              />
              <button onClick={handleSaveNextContact}>Save</button>
            </div>
          ) : (
            <>
              {contact.nextContact ? (
                <>
                  {new Date(contact.nextContact).toLocaleDateString()}{" "}
                  <FontAwesomeIcon
                    className="editIcon"
                    icon={faEdit}
                    onClick={() => {
                      setIsEditingNextContact(true);
                      setNextContactDate(
                        new Date(contact.nextContact)
                          .toISOString()
                          .split("T")[0]
                      );
                    }}
                  />
                </>
              ) : (
                "N/A"
              )}
            </>
          )}
        </div>
        <div className="contactDetailsBox">
          <strong>Type:</strong>{" "}
          {isEditingTypeOfContact ? (
            <div>
              <select
                value={editedTypeOfContact}
                onChange={(e) => setEditedTypeOfContact(e.target.value)}
              >
                <option value="Call">Call</option>
                <option value="Visit">Visit</option>
                <option value="Delivery">Delivery</option>
              </select>
              <button onClick={handleSaveTypeOfContact}>Save</button>
            </div>
          ) : (
            <>
              {contact.typeOfContact}{" "}
              <FontAwesomeIcon
                className="editIcon"
                icon={faEdit}
                onClick={() => setIsEditingTypeOfContact(true)}
              />
            </>
          )}
        </div>
      </div>

      <Modal
        isOpen={showContactModal}
        onRequestClose={() => setShowContactModal(false)}
        contentLabel="Add Contact"
        style={{
          overlay: {
            top: "80px",
            width: "100%",
            background: "black",
          },
          content: {
            margin: "auto",
          },
        }}
      >
        <div>
          <div className="ulAssignUser">
            <h2>Assign User</h2>
            <div className="modaluserlist">
              {contacts.map((user) => (
                <div className="modaluserind" key={user._id}>
                  {user.name}
                  <FontAwesomeIcon
                    className="plusiconmodal"
                    icon={faPlus}
                    onClick={() => handleAssociateContact(user._id)}
                  />
                </div>
              ))}
            </div>
            <button
              className="buttonModalAssignUser"
              onClick={() => setShowContactModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
    <div className="contactIconBoxMain">
      {title === "Admin" && (
        <div className="contactIconBox">
          <div className="contactMaterialBox">
            <div className="noContactButton">${contact.debt}</div>
          </div>

          <div className="contactMaterialBox">
            {isEditingCatalogue ? (
              <div>
                <input
                  type="text"
                  value={editedCatalogue}
                  onChange={(e) => setEditedCatalogue(e.target.value)}
                />
                <button onClick={handleSaveCatalogue}>Save</button>
              </div>
            ) : (
              <>
                {contact.catalogue}{" "}
                <FontAwesomeIcon
                  className="editIconCatalogue"
                  icon={faCalculator}
                  onClick={() => setIsEditingCatalogue(true)}
                />
              </>
            )}
          </div>

          <div className="contactMaterialBox">
            <div className="noContactButton">
              <FontAwesomeIcon className="iconMaterial" icon={faComputer} />
              {contact.weee?.kilogram} Kg ({contact.weee?.purchases})
            </div>
          </div>
          <div className="contactMaterialBox">
            <div className="noContactButton">
              <FontAwesomeIcon className="iconMaterial" icon={faPlug} />
              {contact.lambda?.kilogram} Kg ({contact.lambda?.purchases})
            </div>
          </div>
          <div className="contactMaterialBox">
            <div className="noContactButton">
              <FontAwesomeIcon className="iconMaterial" icon={faCar} />
              {(contact.ceramics?.kilogram ?? 0).toFixed(2)} Kg (
              {contact.ceramics?.purchases})
              {contact.ceramics?.kilogram > 0 && (
                <span className="marginInfo">
                  ($
                  {(
                    contact.ceramics?.indCeramicsPurchases.reduce(
                      (sum, purchase) => sum + (purchase.usdValue || 0),
                      0
                    ) / contact.ceramics?.kilogram
                  ).toFixed(2)}
                  )
                </span>
              )}
            </div>
          </div>

          <div className="contactMaterialBox">
            <FontAwesomeIcon
              className="plusicon"
              icon={faPlus}
              onClick={handleAddContact}
            />
          </div>
          <div className="contactMaterialBox">
            <div className="noContactButton">{contact.noAnswerCount}</div>
          </div>
          <div className="contactMaterialBox">
            <Link
              to="/customer-material-line-chart"
              state={{
                weeeData: contact.weee?.indWeeePurchases,
                ceramicsData: contact.ceramics?.indCeramicsPurchases,
              }}
            >
              <FontAwesomeIcon className="iconChart" icon={faChartLine} />
            </Link>
          </div>
        </div>
      )}

      <div className="contactIconBox">
        <div className="contactMaterialBox">
          <button className="noContactButton" onClick={handleNoAnswer}>
            No Answer
          </button>
        </div>
        <div className="contactMaterialBox">
          <FontAwesomeIcon
            className="plusicon"
            icon={faPlus}
            onClick={handleAddContact}
          />
        </div>
        <div className="contactMaterialBox">
          {isEditingMaterialType ? (
            <div>
              <select
                value={editedMaterialType}
                onChange={(e) => setEditedMaterialType(e.target.value)}
              >
                <option value="Cats">Cats</option>
                <option value="Weee">Weee</option>
                <option value="Lambda">Lambda</option>
                <option value="Cats/Lambda">Cats/Lambda</option>
                <option value="Weee/Cats">Weee/Cats</option>
              </select>
              <button onClick={handleSaveMaterialType}>Save</button>
            </div>
          ) : (
            <>
              {renderMaterialIcon(contact.materialType)}
              <FontAwesomeIcon
                className="editIconMaterial"
                icon={faEdit}
                onClick={() => setIsEditingMaterialType(true)}
              />
            </>
          )}
        </div>
        <div className="contactMaterialBox">
          <div className="noContactButton">{contact.materialType}</div>
        </div>
        <div
          className="contactMaterialBox"
          style={{ backgroundColor: contact.user ? "lightgreen" : "red" }}
        >
          <strong>{contact.user ? "" : ""}</strong>
          {contact.user ? (
            <div className="noteContent">{contact.user.name}</div>
          ) : (
            <div className="noteContent">Not Assigned</div>
          )}
        </div>
        <div className="contactMaterialBox">
          <strong>Last:</strong>{" "}
          {contact.lastContact
            ? new Date(contact.lastContact).toLocaleDateString()
            : "N/A"}
        </div>
      </div>
    </div>

    {contact && (
      <div className="contactIconBox">
        <div className="contactMaterialBox">
          <strong>Last Purchase:</strong>{" "}
          {ceramicsData && ceramicsData.length > 0
            ? new Date(
                ceramicsData[ceramicsData.length - 1].date
              ).toLocaleDateString()
            : "N/A"}
        </div>
        <div className="contactMaterialBox">
          <strong>Next Purchase:</strong>{" "}
          {ceramicsData && ceramicsData.length > 0
            ? predictNextPurchaseDate(
                ceramicsData[ceramicsData.length - 1].date, // Use the date of the last purchase
                calculateFrequency(ceramicsData) // Calculate the average frequency dynamically
              )
            : "N/A"}
        </div>
        <div className="contactMaterialBox">
          <strong>Kg Expected:</strong>{" "}
          {ceramicsData && ceramicsData.length > 0
            ? calculateExpectedKg(ceramicsData).toFixed(2)
            : "N/A"}
        </div>
        {title === "Admin" && (
        <div
    className="contactMaterialBox"
    onClick={() => navigate(`/contact-edit-admin-ind/${id}`)}
    style={{ cursor: "pointer" }} // Makes it clear it's clickable
  >
    <strong>History</strong>
  </div>
        )}
      </div>
    )}

    {contact.notes && Array.isArray(contact.notes) && (
      <div className="contactDetailsNotesMain">
        <div className="contactDetailsNotesBox">
          <strong>Notes:</strong>
          {contact.notes &&
            Array.isArray(contact.notes) &&
            contact.notes.map((note, index) => (
              <div
                key={index}
                className={
                  index !== contact.notes.length - 1 ? "noteSeparator" : ""
                }
              >
                <div className="noteContent">
                  {new Date(note.createdAt).toLocaleString()} - {note.content}
                </div>
              </div>
            ))}
        </div>
        <div className="addNote">
          <textarea
            rows="4"
            cols="50"
            placeholder="Add a note..."
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
          {/*       {title === "Admin" && (
            <button onClick={handleCreateLoad}>Create New Load</button>
          )}
          {title === "Admin" && (
            <select
              className="loadDropdown"
              value={selectedLoadId ? selectedLoadId.id : ""}
              onChange={handleLoadChange}
            >
              <option value="">Assign a Load</option>
              {loads.map((load) => (
                <option key={load._id} value={load._id}>
                  {load.material} - {load.load}
                </option>
              ))}
            </select>
          )} */}
          {title === "Admin" && (
            <div className="weightEntryBox">
                              <div className="weightEntryBoxMain">
              <div className="material-detail-buttons">
              <button
                onClick={() => {
                  setShowWeeeInputs(!showWeeeInputs);
                  if (showBorrowedInputs) setShowBorrowedInputs(false);
                  if (showDPFInputs) setShowDPFInputs(false);
                  if (showLambdaInputs) setShowLambdaInputs(false);
                  if (showCeramicInputs) setShowCeramicInputs(false);
                }}
              >
                {showWeeeInputs ? "Hide Weee Inputs" : "Enter Weee Details"}
              </button>
              <button
                onClick={() => {
                  setShowCeramicInputs(!showCeramicInputs);
                  if (showBorrowedInputs) setShowBorrowedInputs(false);
                  if (showDPFInputs) setShowDPFInputs(false);
                  if (showLambdaInputs) setShowLambdaInputs(false);
                  if (showWeeeInputs) setShowWeeeInputs(false);
                }}
              >
                {showCeramicInputs
                  ? "Hide Ceramic Inputs"
                  : "Enter Ceramic Details"}
              </button>
              <button
                onClick={() => {
                  setShowLambdaInputs(!showLambdaInputs);
                  if (showBorrowedInputs) setShowBorrowedInputs(false);
                  if (showDPFInputs) setShowDPFInputs(false);
                  if (showCeramicInputs) setShowCeramicInputs(false);
                  if (showWeeeInputs) setShowWeeeInputs(false);
                }}
              >
                {showLambdaInputs
                  ? "Hide Lambda Inputs"
                  : "Enter Lambda Details"}
              </button>
              <button
                onClick={() => {
                  setShowBorrowedInputs(!showBorrowedInputs);
                  if (showLambdaInputs) setShowLambdaInputs(false);
                  if (showDPFInputs) setShowDPFInputs(false);
                  if (showCeramicInputs) setShowCeramicInputs(false);
                  if (showWeeeInputs) setShowWeeeInputs(false);
                }}
              >
                {showBorrowedInputs
                  ? "Hide $ Balance Inputs"
                  : "Enter $ Balance Details"}
              </button>
              </div>
              {showWeeeInputs && (
                <>
                  <div className="weightEntry">
                    USD Spent
                    <input
                      className="weightInput"
                      type="number"
                      value={usdSpentWeeeInput}
                      onChange={(e) => setUsdSpentWeeeInput(e.target.value)}
                      placeholder="Weee $ Spent"
                    />
                    Weee Kg
                    <input
                      className="weightInput"
                      type="number"
                      value={weeeKgInput}
                      onChange={(e) => setWeeeKgInput(e.target.value)}
                      placeholder="Weee Kg"
                    />
                    USD Profit
                    <input
                      className="weightInput"
                      type="number"
                      value={weeeUsdValueInput}
                      onChange={(e) => setWeeeUsdValueInput(e.target.value)}
                      placeholder="Weee $ Profit"
                    />
                  </div>
                </>
              )}
              {showCeramicInputs && (
                <>
                  <div className="weightEntry">
                    USD Spent Ceramic
                    <input
                      className="weightInput"
                      type="number"
                      value={usdSpentCeramicsInput}
                      onChange={(e) =>
                        setUsdSpentCeramicsInput(e.target.value)
                      }
                      placeholder="Ceramic $ Spent"
                    />
                    Ceramic Units/Kg
                    <input
                      className="weightInput"
                      type="number"
                      value={ceramicsKgInput}
                      onChange={(e) => setCeramicsKgInput(e.target.value)}
                      placeholder="Ceramic Kg"
                    />
                    USD Profit Ceramic
                    <input
                      className="weightInput"
                      type="number"
                      value={ceramicsUsdValueInput}
                      onChange={(e) =>
                        setCeramicsUsdValueInput(e.target.value)
                      }
                      placeholder="Ceramic $ Profit"
                    />
                    <button
                      onClick={() => setShowSteelInputs(!showSteelInputs)}
                    >
                      {showSteelInputs
                        ? "Hide Steel Inputs"
                        : "Enter Steel Details"}
                    </button>
                    {showSteelInputs && (
                      <div className="showsteel">
                        <input
                          className="weightInput"
                          type="number"
                          value={usdSpentSteelInput}
                          onChange={(e) =>
                            setUsdSpentSteelInput(e.target.value)
                          }
                          placeholder="Steel $ Spent"
                        />
                        <input
                          className="weightInput"
                          type="number"
                          value={steelKgInput}
                          onChange={(e) => setSteelKgInput(e.target.value)}
                          placeholder="Steel Kg"
                        />
                        <input
                          className="weightInput"
                          type="number"
                          value={steelUsdValueInput}
                          onChange={(e) =>
                            setSteelUsdValueInput(e.target.value)
                          }
                          placeholder="Steel $ Profit"
                        />
                      </div>
                    )}
                    <button onClick={() => setShowDPFInputs(!showDPFInputs)}>
                      {showDPFInputs
                        ? "Hide DPF Inputs"
                        : "Enter DPF Details"}
                    </button>
                    {showDPFInputs && (
                      <div className="showsteel">
                        <input
                          className="weightInput"
                          type="number"
                          value={usdSpentDpfInput}
                          onChange={(e) =>
                            setUsdSpentDpfInput(e.target.value)
                          }
                          placeholder="DPF $ Spent"
                        />
                        <input
                          className="weightInput"
                          type="number"
                          value={dpfKgInput}
                          onChange={(e) => setDpfKgInput(e.target.value)}
                          placeholder="DPF Kg"
                        />
                        <input
                          className="weightInput"
                          type="number"
                          value={dpfUsdValueInput}
                          onChange={(e) =>
                            setDpfUsdValueInput(e.target.value)
                          }
                          placeholder="DPF $ Profit"
                        />
                      </div>
                    )}
                    <button onClick={() => setShowLmeInputs(!showLmeInputs)}>
                      {showLmeInputs
                        ? "Hide LME Inputs"
                        : "Enter LME Details"}
                    </button>
                    {showLmeInputs && (
                      <div className="showsteel">
                        <input
                          className="weightInput"
                          type="number"
                          value={ptLmeInput}
                          onChange={(e) => setPtLmeInput(e.target.value)}
                          placeholder="PT LME"
                        />
                        <input
                          className="weightInput"
                          type="number"
                          value={pdLmeInput}
                          onChange={(e) => setPdLmeInput(e.target.value)}
                          placeholder="PD LME"
                        />
                        <input
                          className="weightInput"
                          type="number"
                          value={rhLmeInput}
                          onChange={(e) => setRhLmeInput(e.target.value)}
                          placeholder="RH LME"
                        />
                      </div>
                    )}
                    <button onClick={() => setShowPPMInputs(!showPPMInputs)}>
                      {showPPMInputs
                        ? "Hide PPM Inputs"
                        : "Enter PPM Details"}
                    </button>
                    {showPPMInputs && (
                      <div className="showsteel">
                        <input
                          className="weightInput"
                          type="number"
                          value={ceramicsPlatinumPpmInput}
                          onChange={(e) =>
                            setCeramicsPlatinumPpmInput(e.target.value)
                          }
                          placeholder="PT PPM"
                        />
                        <input
                          className="weightInput"
                          type="number"
                          value={ceramicsPalladiumPpmInput}
                          onChange={(e) =>
                            setCeramicsPalladiumPpmInput(e.target.value)
                          }
                          placeholder="PD PPM"
                        />
                        <input
                          className="weightInput"
                          type="number"
                          value={ceramicsRhodiumPpmInput}
                          onChange={(e) =>
                            setCeramicsRhodiumPpmInput(e.target.value)
                          }
                          placeholder="RH PPM"
                        />
                      </div>
                    )}
                  </div>
                </>
              )}

              {showLambdaInputs && (
                <>
                  <div className="weightEntry">
                    USD Spent
                    <input
                      className="weightInput"
                      type="number"
                      value={usdSpentLambdaInput}
                      onChange={(e) => setUsdSpentLambdaInput(e.target.value)}
                      placeholder="Lambda $ Spent"
                    />
                    Lambda Kg
                    <input
                      className="weightInput"
                      type="number"
                      value={lambdaKgInput}
                      onChange={(e) => setLambdaKgInput(e.target.value)}
                      placeholder="Lambda Kg"
                    />
                    USD Profit
                    <input
                      className="weightInput"
                      type="number"
                      value={lambdaUsdValueInput}
                      onChange={(e) => setLambdaUsdValueInput(e.target.value)}
                      placeholder="Lambda $ Profit"
                    />
                    <button
                      onClick={() => {
                        setShowLmeLambdaInputs(!showLmeLambdaInputs);
                        if (showBorrowedInputs) setShowBorrowedInputs(false);
                        if (showDPFInputs) setShowDPFInputs(false);
                        if (showCeramicInputs) setShowCeramicInputs(false);
                        if (showWeeeInputs) setShowWeeeInputs(false);
                      }}
                    >
                      {showLmeLambdaInputs
                        ? "Hide LME Inputs"
                        : "Enter LME Details"}
                    </button>
                    {showLmeLambdaInputs && (
                      <div className="showsteel">
                        PT LME
                        <input
                          className="weightInput"
                          type="number"
                          value={ptLmeInput}
                          onChange={(e) => setPtLmeInput(e.target.value)}
                        />
                        PD LME
                        <input
                          className="weightInput"
                          type="number"
                          value={pdLmeInput}
                          onChange={(e) => setPdLmeInput(e.target.value)}
                        />
                        RH LME
                        <input
                          className="weightInput"
                          type="number"
                          value={rhLmeInput}
                          onChange={(e) => setRhLmeInput(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}

              {showBorrowedInputs && (
                <div className="weightEntry">
                  Borrowed in $
                  <input
                    className="weightInput"
                    type="number"
                    value={borrowedInput}
                    onChange={(e) => setBorrowedInput(e.target.value)}
                  />
                  Returned in $
                  <input
                    className="weightInput"
                    type="number"
                    value={returnedInput}
                    onChange={(e) => setReturnedInput(e.target.value)}
                  />
                </div>
              )}
            </div>
            </div>
          )}

          <button
            onClick={handleSaveNote}
            disabled={
              (ceramicsKgInput.trim() && !ceramicsUsdValueInput.trim()) ||
              (!ceramicsKgInput.trim() && ceramicsUsdValueInput.trim()) ||
              (ceramicsKgInput.trim() && !ptLmeInput.trim()) ||
              (ceramicsKgInput.trim() && !pdLmeInput.trim()) ||
              (ceramicsKgInput.trim() && !rhLmeInput.trim()) ||
              (steelKgInput.trim() && !steelUsdValueInput.trim()) ||
              (!steelKgInput.trim() && steelUsdValueInput.trim()) ||
              (steelKgInput.trim() && !ptLmeInput.trim()) ||
              (steelKgInput.trim() && !pdLmeInput.trim()) ||
              (steelKgInput.trim() && !rhLmeInput.trim())
            }
          >
            Save
          </button>
        </div>
        <Modal
          isOpen={loading}
          style={{
            overlay: {
              top: "80px",
              width: "100%",
              background: "black",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              backgroundColor: "transparent", // completely transparent background for modal content
              border: "none", // remove border
              borderRadius: "8px",
              padding: "20px", // add padding
              color: "#ffffff", // white font color
            },
          }}
        >
          <div>
            <h2>UPDATING...</h2>
            {/* You can add a spinner or any other loading animation here */}
          </div>
        </Modal>
      </div>
    )}
  </div>
);
};

export default ContactDetails;
