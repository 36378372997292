import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './UserStats.css';

const UserStatistics = () => {
  const [userStats, setUserStats] = useState(null);
  const [totalCeramicsKilograms, setTotalCeramicsKilograms] = useState(0);
  const [monthlyCeramicsKilograms, setMonthlyCeramicsKilograms] = useState({});
  const [totalWeeeKilograms, setTotalWeeeKilograms] = useState(0);
  const [monthlyWeeeKilograms, setMonthlyWeeeKilograms] = useState({});
  const [totalCeramicsUSDValue, setTotalCeramicsUSDValue] = useState(0);
  const [monthlyCeramicsUSDValue, setMonthlyCeramicsUSDValue] = useState({});
  const [monthlyCeramicsPurchaseCount, setMonthlyCeramicsPurchaseCount] = useState({});
  const [monthlyWeeePurchaseCount, setMonthlyWeeePurchaseCount] = useState({});
  const [totalWeeeUSDValue, setTotalWeeeUSDValue] = useState(0);
  const [monthlyWeeeUSDValue, setMonthlyWeeeUSDValue] = useState({});
  const [dailyCeramicsKilograms, setDailyCeramicsKilograms] = useState({});
  const [dailyWeeeKilograms, setDailyWeeeKilograms] = useState({});
  const [dailyCeramicsUSDValue, setDailyCeramicsUSDValue] = useState({});
  const [dailyWeeeUSDValue, setDailyWeeeUSDValue] = useState({});
  const { id } = useParams();
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

  const sortedDates = Object.keys(dailyCeramicsUSDValue).sort((a, b) => {
    const dateA = new Date(a.split('-').reverse().join('-'));
    const dateB = new Date(b.split('-').reverse().join('-'));
  
    return dateB - dateA;
  });

  useEffect(() => {
    const fetchUserStatistics = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/users/${id}`);
        setUserStats(response.data);
  
        const {
          totalCeramics,
          monthlyCeramics,
          monthlyCeramicsPurchaseCount, // Destructure purchase count
          totalWeee,
          monthlyWeee,
          monthlyWeeePurchaseCount, // Destructure purchase count
          dailyCeramics,
          dailyWeee,
        } = await calculateTotalPurchases(response.data.contacts);
  
        setTotalCeramicsKilograms(totalCeramics);
        setMonthlyCeramicsKilograms(monthlyCeramics);
        setMonthlyCeramicsPurchaseCount(monthlyCeramicsPurchaseCount); // Set purchase count state
        setTotalWeeeKilograms(totalWeee);
        setMonthlyWeeeKilograms(monthlyWeee);
        setMonthlyWeeePurchaseCount(monthlyWeeePurchaseCount); // Set purchase count state
        setDailyCeramicsKilograms(dailyCeramics);
        setDailyWeeeKilograms(dailyWeee);
      } catch (error) {
        console.error('Error fetching user statistics:', error);
      }
    };
  
    if (id) {
      fetchUserStatistics();
    }
  }, [id, baseUrl]);
  
  

  const calculateTotalPurchases = async (contacts) => {
    try {
      const monthlyCeramics = {};
      const monthlyCeramicsUSDValue = {};
      const monthlyCeramicsPurchaseCount = {}; // New object for ceramics purchase count
      const monthlyWeee = {};
      const monthlyWeeeUSDValue = {};
      const monthlyWeeePurchaseCount = {}; // New object for weee purchase count
      const dailyCeramics = {};
      const dailyCeramicsUSDValue = {};
      const dailyWeee = {};
      const dailyWeeeUSDValue = {};
      let totalCeramics = 0;
      let totalCeramicsUSDValue = 0;
      let totalWeee = 0;
      let totalWeeeUSDValue = 0;
  
      await Promise.all(
        contacts.map(async (contactId) => {
          const contactResponse = await axios.get(`${baseUrl}/api/contacts/${contactId}`);
          const contactData = contactResponse.data;
  
          if (!contactData || !contactData.ceramics || !contactData.weee) {
            console.warn(`Contact data for ID ${contactId} is missing expected properties`, contactData);
            return;
          }
  
          const ceramicsPurchases = contactData.ceramics.indCeramicsPurchases || [];
          const weeePurchases = contactData.weee.indWeeePurchases || [];
  
          totalCeramics += ceramicsPurchases.reduce((sum, purchase) => {
            const { date, kilograms, usdValue } = purchase;
            const purchaseDate = new Date(date);
            const monthYearKey = `${purchaseDate.getMonth() + 1}-${purchaseDate.getFullYear()}`;
            monthlyCeramics[monthYearKey] = (monthlyCeramics[monthYearKey] || 0) + (kilograms || 0);
            totalCeramicsUSDValue += usdValue || 0;
            monthlyCeramicsUSDValue[monthYearKey] = (monthlyCeramicsUSDValue[monthYearKey] || 0) + (usdValue || 0);
            monthlyCeramicsPurchaseCount[monthYearKey] = (monthlyCeramicsPurchaseCount[monthYearKey] || 0) + 1;
            return sum + (kilograms || 0);
          }, 0);
  
          ceramicsPurchases.forEach((purchase) => {
            const { date, kilograms, usdValue } = purchase;
            const purchaseDate = new Date(date);
            const dayKey = `${purchaseDate.getDate()}-${purchaseDate.getMonth() + 1}-${purchaseDate.getFullYear()}`;
            dailyCeramics[dayKey] = (dailyCeramics[dayKey] || 0) + (kilograms || 0);
            dailyCeramicsUSDValue[dayKey] = (dailyCeramicsUSDValue[dayKey] || 0) + (usdValue || 0);
          });
  
          weeePurchases.forEach((purchase) => {
            const { date, kilograms, usdValue } = purchase;
            const purchaseDate = new Date(date);
            const dayKey = `${purchaseDate.getDate()}-${purchaseDate.getMonth() + 1}-${purchaseDate.getFullYear()}`;
            dailyWeee[dayKey] = (dailyWeee[dayKey] || 0) + (kilograms || 0);
            dailyWeeeUSDValue[dayKey] = (dailyWeeeUSDValue[dayKey] || 0) + (usdValue || 0);
          });
  
          totalWeee += weeePurchases.reduce((sum, purchase) => {
            const { date, kilograms, usdValue } = purchase;
            const purchaseDate = new Date(date);
            const monthYearKey = `${purchaseDate.getMonth() + 1}-${purchaseDate.getFullYear()}`;
            monthlyWeee[monthYearKey] = (monthlyWeee[monthYearKey] || 0) + (kilograms || 0);
            totalWeeeUSDValue += usdValue || 0;
            monthlyWeeeUSDValue[monthYearKey] = (monthlyWeeeUSDValue[monthYearKey] || 0) + (usdValue || 0);
            monthlyWeeePurchaseCount[monthYearKey] = (monthlyWeeePurchaseCount[monthYearKey] || 0) + 1;
            return sum + (kilograms || 0);
          }, 0);
        })
      );
  
      setTotalCeramicsUSDValue(totalCeramicsUSDValue);
      setMonthlyCeramicsUSDValue(monthlyCeramicsUSDValue);
      setTotalWeeeUSDValue(totalWeeeUSDValue);
      setMonthlyWeeeUSDValue(monthlyWeeeUSDValue);
      setDailyCeramicsUSDValue(dailyCeramicsUSDValue || {});
      setDailyWeeeUSDValue(dailyWeeeUSDValue || {});
  
      return {
        totalCeramics,
        monthlyCeramics,
        monthlyCeramicsPurchaseCount, // Include purchase count in return value
        dailyCeramics,
        totalWeee,
        monthlyWeee,
        monthlyWeeePurchaseCount, // Include purchase count in return value
        dailyWeee,
      };
    } catch (error) {
      console.error('Error fetching purchases:', error);
      return {
        totalCeramics: 0,
        monthlyCeramics: {},
        monthlyCeramicsPurchaseCount: {}, // Include purchase count in return value
        dailyCeramics: {},
        totalWeee: 0,
        monthlyWeee: {},
        monthlyWeeePurchaseCount: {}, // Include purchase count in return value
        dailyWeee: {},
      };
    }
  };
  
  
  
  
  
  
  return (
    <div className='userStatsMain'>
      <div className='userStatsSecond'>
        <h2>User Statistics</h2>
        {userStats ? (
          <>
            <div className="table1">
              <div className="table-row">
                <div className="table-cell table-cell-header">Metric</div>
                <div className="table-cell table-cell-header">Value</div>
              </div>
              <div className="table-row">
                <div className="table-cell">Total Contacts</div>
                <div className="table-cell">{userStats.contacts.length}</div>
              </div>
              <div className="table-row">
                <div className="table-cell">Lost Contacts</div>
                <div className="table-cell">{userStats.lostCustomers.length}</div>
              </div>
              <div className="table-row">
                <div className="table-cell">Total Ceramics KG</div>
                <div className="table-cell">{totalCeramicsKilograms.toFixed(2)}</div>
              </div>
              <div className="table-row">
                <div className="table-cell">Total Ceramics Profit</div>
                <div className="table-cell">${totalCeramicsUSDValue.toFixed(2)}</div>
              </div>
              <div className="table-row">
                <div className="table-cell">Total Ceramics Margin</div>
                <div className="table-cell">
                  ${totalCeramicsKilograms > 0
                    ? (totalCeramicsUSDValue / totalCeramicsKilograms).toFixed(2)
                    : 0
                  }
                </div>
              </div>
           {/*     <div className="table-row">
                <div className="table-cell">Total Weee KG</div>
                <div className="table-cell">{totalWeeeKilograms.toFixed(2)}</div>
              </div>
            <div className="table-row">
                <div className="table-cell">Total Weee Profit</div>
                <div className="table-cell">{totalWeeeUSDValue.toFixed(2)}</div>
              </div> */}
            </div>     

{/* Monthly data */}
<div className='userStatsMainList'>
  <div className="table4">
    {/* Header row */}
    <div className="header-row">
      <div className="table-cell">Month</div>
      <div className="table-cell">Cat KG</div>
      <div className="table-cell">Profit</div>
      <div className="table-cell">Buys</div> 
    </div>

    {/* Data rows for Monthly Ceramics and Weee KG and USD Value */}
    {Object.keys(monthlyCeramicsKilograms).map((monthYear, index) => (
      <div className="table-row" key={index}>
        <div className="table-cell daily-cell">{`${monthYear}`}</div>
        {/* Ceramics data */}
        <div className="table-cell daily-cell">{`${monthlyCeramicsKilograms[monthYear]?.toFixed(0) ?? 0} kg`}</div>
        <div className="table-cell daily-cell">{`$${monthlyCeramicsUSDValue[monthYear]?.toFixed(0) ?? 0}`}</div>
        {/* Weee data */}
      {/*  <div className="table-cell daily-cell">{`${monthlyWeeeKilograms[monthYear]?.toFixed(0) ?? 0} kg`}</div> */}
      {/*  <div className="table-cell daily-cell">{`$${monthlyWeeeUSDValue[monthYear]?.toFixed(0) ?? 0}`}</div> */}
        {/* Purchase counts */}
        <div className="table-cell daily-cell">{monthlyCeramicsPurchaseCount[monthYear] || 0}</div> {/* Display ceramics purchase count */}
      {/*  <div className="table-cell daily-cell">{monthlyWeeePurchaseCount[monthYear] || 0}</div> {/* Display weee purchase count */} 
      </div>
    ))}
  </div>
</div>



           {/* Daily data */}
           <div className='userStatsMainList'>
              <div className="table3">
                {/* Header row */}
                <div className="header-row">
                  <div className="table-cell ">Date</div>
                  <div className="table-cell ">Cat KG</div>
                  <div className="table-cell ">Cat Profit</div>
                  <div className="table-cell ">Margin</div>
             {/*       <div className="table-cell ">Weee KG</div>
                  <div className="table-cell ">Weee Profit</div>
                  <div className="table-cell ">Margin</div> */}
                </div>

{/* Data rows for Daily Ceramics and Weee KG and USD Value */}
<div className="table-row">
  {dailyCeramicsUSDValue &&
    sortedDates.map(day => {
      const ceramicsUsdValue = dailyCeramicsUSDValue[day];


      const weeeKilograms = dailyWeeeKilograms[day] || 0;
      const weeeUSDValue = dailyWeeeUSDValue[day] || 0;

      return (
        <div key={day} className="table-cell daily-cell">
          <div>{`${day}`}</div>
          {/* Ceramics data */}
          <div>{`${dailyCeramicsKilograms[day].toFixed(0)} kg`}</div>
          <div>{`$${ceramicsUsdValue.toFixed(0)}`}</div>
          <div>{`$${(ceramicsUsdValue / dailyCeramicsKilograms[day]).toFixed(2)}`}</div>
          {/* Weee data 
          <div>{`${dailyWeeeKilograms[day]?.toFixed(0) || '0'} kg`}</div>
          <div>{`$${dailyWeeeUSDValue[day]?.toFixed(0) || '0'}`}</div>
          <div>{`$${weeeKilograms > 0 ? (weeeUSDValue / weeeKilograms).toFixed(2) : '0'}`}</div> */}
        </div>
      );
    })}
</div>


        </div>
              </div>
  
          </>
        ) : (
          <p>Loading user statistics...</p>
        )}
      </div>
    </div>
  );
};

export default UserStatistics;
