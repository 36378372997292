import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../../userAuth";
import { useParams, useNavigate } from "react-router-dom";

const MonthlyExpense = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  const { id } = useParams();
  const [expense, setExpense] = useState(null);
  const [debt, setDebt] = useState(null);
  const [newUsdInAll, setNewUsdInAll] = useState({ usdIn: "" });
  const [newUsdExport, setNewUsdExport] = useState({ usdSpent: "" });
  const [newOpenDebt, setNewOpenDebt] = useState({ newOpenDebt: "" });
  const [newOpenCash, setNewOpenCash] = useState({ newOpenCash: "" });
  const [newAccountingPurchases, setNewAccountingPurchases] = useState({
    newAccountingPurhcases: "",
  });
  const [exportLoads, setExportLoads] = useState([]);
  const [selectedExportLoad, setSelectedExportLoad] = useState("");
  const [newFixedExpense, setNewFixedExpense] = useState({
    type: "",
    number: "",
    spentUsd: "",
  });
  const [newVariableExpense, setNewVariableExpense] = useState({
    type: "",
    number: "",
    spentUsd: "",
  });
  const [editingExpenseId, setEditingExpenseId] = useState(null); // Track which expense is being edited
  const [editedExpense, setEditedExpense] = useState({});
  const [editingFixedExpenseId, setEditingFixedExpenseId] = useState(null);
  const [editedFixedExpense, setEditedFixedExpense] = useState({
    expenseType: "",
    spentUsd: "",
    type: "", // Preserve existing type
  });

  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  const fetchExpense = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/expenses/${id}`);
      setExpense(response.data);
    } catch (error) {
      console.error("Error fetching expense:", error);
    }
  };

  const fetchDebt = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/crg`);
      setDebt(response.data.debtCrgTotalUsd);
    } catch (error) {
      console.error("Error fetching expense:", error);
    }
  };

  const fetchExportLoads = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/exports/noexportexpense/${id}`);
      setExportLoads(response.data);
    } catch (error) {
      console.error("Error fetching export loads:", error);
    }
  };

  useEffect(() => {
    fetchExpense();
    fetchDebt();
    fetchExportLoads();
  }, [id, baseUrl]);

  const handleFixedExpenseEditClick = (expense) => {
    setEditingFixedExpenseId(expense._id);
    setEditedFixedExpense({
      expenseType: expense.expenseType,
      spentUsd: expense.spentUsd,
      type: expense.type, // Preserve existing type
    });
  };

  const handleSaveFixedExpenseEdit = async (expenseId) => {
    try {
      await axios.put(
        `${baseUrl}/api/expenses/edit/${id}/fixedExpenses/${expenseId}`,
        editedFixedExpense
      );
      fetchExpense(); // Refresh data
      setEditingFixedExpenseId(null); // Exit edit mode
    } catch (error) {
      console.error("Error updating fixed expense:", error);
    }
  };

  const handleEditClick = (expense) => {
    setEditingExpenseId(expense._id);
    setEditedExpense({
      expenseType: expense.expenseType,
      spentUsd: expense.spentUsd,
      type: expense.type, // Preserve existing type
    });
  };

  const handleCancelEdit = () => {
    setEditingExpenseId(null);
    setEditedExpense({});
  };

  const handleSaveEdit = async (expenseId) => {
    try {
      await axios.put(
        `${baseUrl}/api/expenses/edit/${id}/variableExpenses/${expenseId}`,
        editedExpense
      );
      fetchExpense(); // Refresh the data
      setEditingExpenseId(null); // Exit edit mode
    } catch (error) {
      console.error("Error updating expense:", error);
    }
  };

  const handleCreateFixedExpense = async (e) => {
    e.preventDefault(); // Prevent page refresh on form submission

    try {
      const selectedLoad = exportLoads.find(
        (load) => load._id === selectedExportLoad
      );

      if (
        !newFixedExpense.expenseType ||
        !newFixedExpense.type ||
        !newFixedExpense.spentUsd ||
        !selectedExportLoad
      ) {
        console.error("Please fill in all fields");
        return;
      }

      const data = {
        expenseType: newFixedExpense.expenseType,
        type: newFixedExpense.type,
        spentUsd: newFixedExpense.spentUsd,
        exportLoadId: selectedExportLoad,
        load: selectedLoad?.load || "",
      };

      await axios.post(`${baseUrl}/api/expenses/${id}/fixedExpenses`, data);

      // Reset form
      setNewFixedExpense({ expenseType: "", type: "", spentUsd: "" });
      setSelectedExportLoad("");
      fetchExpense(); // Refresh data
    } catch (error) {
      console.error("Error creating fixed expense:", error);
    }
  };

  const handleCreateVariableExpense = async (e) => {
    e.preventDefault(); // Prevent page refresh on form submission
    try {
      const selectedLoad = exportLoads.find(
        (load) => load._id === selectedExportLoad
      );

      if (
        !newVariableExpense.expenseType || // Ensure expenseType is included
        !newVariableExpense.type ||
        !newVariableExpense.spentUsd ||
        !selectedExportLoad
      ) {
        console.error("Please fill in all fields");
        return;
      }

      const data = {
        expenseType: newVariableExpense.expenseType, // Predefined category
        type: newVariableExpense.type, // Custom description
        spentUsd: parseFloat(newVariableExpense.spentUsd) || 0, // Ensure numeric value
        exportLoadId: selectedExportLoad,
        load: selectedLoad?.load || "", // Handle potential undefined
      };

      await axios.post(`${baseUrl}/api/expenses/${id}/variableExpenses`, data);

      // Reset form
      setNewVariableExpense({ expenseType: "", type: "", spentUsd: "" });
      setSelectedExportLoad("");
      fetchExpense(); // Refresh expense data
    } catch (error) {
      console.error("Error creating variable expense:", error);
    }
  };

  const handleCreateMoneyIn = async (e) => {
    e.preventDefault(); 
    try {
      const data = {
        ...newUsdInAll,
        load: newUsdInAll.exportLoad.load,
      };
      await axios.post(`${baseUrl}/api/expenses/${id}/moneyIn`, data);
      setNewUsdInAll({ usdIn: "" }); // Clear form fields
      setSelectedExportLoad(""); // Clear selected export load
      fetchExpense(); // Fetch updated expense data
    } catch (error) {
      console.error("Error creating variable expense:", error);
    }
  };

  const handleCreateExportExpense = async () => {
    try {
      const selectedLoad = exportLoads.find(
        (load) => load._id === selectedExportLoad
      );
      const data = {
        ...newUsdExport,
        exportLoadId: selectedExportLoad,
        load: selectedLoad.load,
      };
      await axios.post(`${baseUrl}/api/expenses/${id}/exportExpense`, data);
      setNewUsdExport({ usdSpent: "" }); // Clear form fields
      setSelectedExportLoad(""); // Clear selected export load
      fetchExpense(); // Fetch updated expense data
    } catch (error) {
      console.error("Error creating variable expense:", error);
    }
  };

  const handleCreateOpenCash = async (e) => {
    e.preventDefault(); 
    
    try {
      const data = { ...newOpenCash };
      await axios.post(`${baseUrl}/api/expenses/${id}/openCash`, data);
      setNewOpenCash({ openCash: "" }); // Clear form fields
      fetchExpense(); // Fetch updated expense data
    } catch (error) {
      console.error("Error creating variable expense:", error);
    }
  };

  const handleCreateOpenDebt = async (e) => {
    e.preventDefault(); 

    try {
      const data = { ...newOpenDebt };
      await axios.post(`${baseUrl}/api/expenses/${id}/openDebt`, data);
      setNewOpenDebt({ openDebt: "" }); // Clear form fields
      fetchExpense(); // Fetch updated expense data
    } catch (error) {
      console.error("Error creating variable expense:", error);
    }
  };

  const handleCreateAccountingPurchases = async () => {
    try {
      const data = { ...newAccountingPurchases };
      await axios.post(
        `${baseUrl}/api/expenses/${id}/accountingPurchases`,
        data
      );
      setNewAccountingPurchases({ accountingPurchases: "" }); // Clear form fields
      fetchExpense(); // Fetch updated expense data
    } catch (error) {
      console.error("Error creating variable expense:", error);
    }
  };

  const handleDebtBreakdownClick = () => {
    navigate('/debt-breakdown'); // Update the route accordingly
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year

    // Pad single digits with leading zero
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  if (!expense) {
    return <div>Loading...</div>;
  }

  return (
    <div className="expenses-main">
      {isLoggedIn && title === "Admin" && (
        <>
          <div className="h1HeadersStockDetailsBig">
            {new Date(expense.month).toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </div>
          <div className="exports-third-row">
            <div className="export-unused-row">
              <div className="export-unused-expenses">
                Total ${expense.totalUsdSpent}
              </div>
            </div>
            <div className="export-unused-row">
              <div className="export-unused-expenses">
                Fixed ${expense.totalUsdSpentFixed}
              </div>
            </div>
            <div className="export-unused-row">
              <div className="export-unused-expenses">
                Variable ${expense.totalUsdSpentVariable}
              </div>
            </div>
            <div className="export-unused-row">
              <div className="export-unused-expenses"onClick={handleDebtBreakdownClick}>Debt Now ${debt}</div>
            </div>
            <div className="export-unused-row">
              <div className="export-unused-expenses">
                Debt Open ${expense.openDebt}
              </div>
            </div>
            <div className="export-unused-row">
              <div className="export-unused-expenses">
                Open Cash ${expense.openCash}
              </div>
            </div>
          </div>

          {expense.openCash < 1 && (
            <div className="exportExpensesBoxRowResponsive">
              <form
                className="formExpensesRowResponsive"
                onSubmit={handleCreateOpenCash}
              >
                <input
                  className="inputTaskExpenses"
                  type="text"
                  name="description"
                  value={expense.newOpenCash}
                  onChange={(e) => setNewOpenCash({ openCash: e.target.value })}
                  placeholder="Opening Cash"
                />
                <button className="expenseAllButtonOpenCash" type="submit">
                  Submit
                </button>
              </form>
            </div>
          )}

          {expense.openDebt < 1 && (
          <div className="exportExpensesContainer">
            <div className="exportExpensesBox">
            <div className="h2HeadersStockDetails">Advance Payments</div>
              <form
                className="formExpensesRowResponsive"
                onSubmit={handleCreateOpenDebt}
              >
                <input
                  className="inputTaskExpenses"
                  type="text"
                  name="description"
                  value={expense.newOpenDebt}
                  onChange={(e) => setNewOpenDebt({ openDebt: e.target.value })}
                  placeholder="Opening Debt"
                />
                <button className="expenseAllButtonOpenCash" type="submit">
                  Submit
                </button>
              </form>
            </div>
            </div>
          )}

          <div className="exportExpensesContainer">
            <div className="exportExpensesBox">
              <div className="h2HeadersStockDetails">Money In</div>
              <form onSubmit={handleCreateMoneyIn}>
                <input
                  className="inputTaskExpenses"
                  type="text"
                  value={newUsdInAll.usdIn}
                  onChange={(e) =>
                    setNewUsdInAll({ ...newUsdInAll, usdIn: e.target.value })
                  }
                  placeholder="Enter Amount"
                />
                <input
                  className="inputTaskExpenses"
                  type="text"
                  value={newUsdInAll.exportLoad?.load || ""}
                  onChange={(e) =>
                    setNewUsdInAll({
                      ...newUsdInAll,
                      exportLoad: {
                        ...newUsdInAll.exportLoad,
                        load: e.target.value,
                      },
                    })
                  }
                  placeholder="Enter Note"
                />

                <button className="expenseAllButton" type="submit">
                  Add Money In
                </button>
              </form>
            </div>

            {expense.totalUsdInAll > 1 && (
              <div className="exportExpensesBox">
                <div className="h2HeadersStockDetails">Money In</div>
                <table className="table50">
                  <thead className="table50">
                    <tr className="table50">
                      <th className="table50header">Date</th>
                      <th className="table50header">Amount</th>
                      <th className="table50header">Note</th>
                    </tr>
                  </thead>
                  <tbody className="table50">
                    {expense.usdInAll.map((item, index) => (
                      <tr className="table50" key={index}>
                        <td className="inputExportDetailsDate">
                          {formatDate(item.date)}
                        </td>
                        <td className="inputExportDetails">$ {item.usdIn}</td>
                        <td className="inputExportDetails">
                          {item.exportLoad.load}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>



          <div className="exportExpensesContainer">
            <div className="exportExpensesBox">
              <div className="h2HeadersStockDetails">
                Add Fixed Expense
              </div>
              <form onSubmit={handleCreateFixedExpense}>
                <select
                  className="selectTaskExpenses"
                  value={newFixedExpense.expenseType}
                  onChange={(e) =>
                    setNewFixedExpense({
                      ...newFixedExpense,
                      expenseType: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">-- Select Expense Type --</option>
                  {[
                    "Wages",
                    "Bank Charges",
                    "Warehouse Rent",
                    "Warehouse",
                    "Accommodation",
                    "Office",
                    "Petrol",
                    "Transport",
                    "Shipping",
                    "Telephones",
                    "Others",
                    "Staff Related",
                  ].map((expense, index) => (
                    <option key={index} value={expense}>
                      {expense}
                    </option>
                  ))}
                </select>
                <input
                  className="inputTaskExpenses"
                  type="text"
                  value={newFixedExpense.type}
                  onChange={(e) =>
                    setNewFixedExpense({
                      ...newFixedExpense,
                      type: e.target.value,
                    })
                  }
                  placeholder="Type"
                />
                <input
                  className="inputTaskExpenses"
                  type="text"
                  value={newFixedExpense.spentUsd}
                  onChange={(e) =>
                    setNewFixedExpense({
                      ...newFixedExpense,
                      spentUsd: e.target.value,
                    })
                  }
                  placeholder="Spent USD"
                />
                <select
                  className="selectTaskExpenses"
                  value={selectedExportLoad}
                  onChange={(e) => setSelectedExportLoad(e.target.value)}
                >
                  <option value="">Select an export load</option>
                  {exportLoads.map((load) => (
                    <option key={load._id} value={load._id}>
                      {load.load}
                    </option>
                  ))}
                </select>
                <button className="expenseAllButton" type="submit">
                  Add Fixed Expense
                </button>
              </form>
            </div>

            <div className="exportExpensesBox">
              <div className="h2HeadersStockDetails">
                Add Variable Expense
              </div>
              <form onSubmit={handleCreateVariableExpense}>
                <select
                  className="selectTaskExpenses"
                  value={newVariableExpense.expenseType}
                  onChange={(e) =>
                    setNewVariableExpense({
                      ...newVariableExpense,
                      expenseType: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">-- Select Expense Type --</option>
                  {[
                    "Wages",
                    "Bank Charges",
                    "Warehouse Rent",
                    "Warehouse",
                    "Accommodation",
                    "Office",
                    "Petrol",
                    "Transport",
                    "Shipping",
                    "Telephones",
                    "Others",
                    "Staff Related",
                  ].map((expense, index) => (
                    <option key={index} value={expense}>
                      {expense}
                    </option>
                  ))}
                </select>
                <input
                  className="inputTaskExpenses"
                  type="text"
                  value={newVariableExpense.type}
                  onChange={(e) =>
                    setNewVariableExpense({
                      ...newVariableExpense,
                      type: e.target.value,
                    })
                  }
                  placeholder="Type"
                />
                <input
                  className="inputTaskExpenses"
                  type="text"
                  value={newVariableExpense.spentUsd}
                  onChange={(e) =>
                    setNewVariableExpense({
                      ...newVariableExpense,
                      spentUsd: e.target.value,
                    })
                  }
                  placeholder="Spent USD"
                />
                <select
                  className="selectTaskExpenses"
                  value={selectedExportLoad}
                  onChange={(e) => setSelectedExportLoad(e.target.value)}
                >
                  <option value="">Select an export load</option>
                  {exportLoads.map((load) => (
                    <option key={load._id} value={load._id}>
                      {load.load}
                    </option>
                  ))}
                </select>
                <button className="expenseAllButton" type="submit">
                  Add Variable Expense
                </button>
              </form>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="exportExpensesBox">
              <div className="h2HeadersStockDetails">Fixed Expenses</div>
              <table className="table50">
                <thead className="table50">
                  <tr className="table50">
                    <th className="table50hexpenses">Date</th>
                    <th className="table50hexpenses">Type</th>
                    <th className="table50hexpenses">Desc</th>
                    <th className="table50hexpenses">Load</th>
                    <th className="table50hexpenses">Spent</th>
                    <th className="table50hexpenses">Edit</th>
                  </tr>
                </thead>
                <tbody className="table50">
                  {expense.fixedExpenses.map((item, index) => (
                    <tr className="table50" key={index}>
                      <td className="inputExportDetailsDate">
                        {formatDate(item.created)}
                      </td>

                      {editingFixedExpenseId === item._id ? (
                        <>
                          {/* Expense Type Editable */}
                          <td>
                            <select
                              value={editedFixedExpense.expenseType}
                              onChange={(e) =>
                                setEditedFixedExpense({
                                  ...editedFixedExpense,
                                  expenseType: e.target.value,
                                })
                              }
                            >
                              {[
                                "Wages",
                                "Bank Charges",
                                "Warehouse Rent",
                                "Warehouse",
                                "Accommodation",
                                "Office",
                                "Petrol",
                                "Transport",
                                "Shipping",
                                "Telephones",
                                "Others",
                                "Staff Related",
                              ].map((expense, i) => (
                                <option key={i} value={expense}>
                                  {expense}
                                </option>
                              ))}
                            </select>
                          </td>

                          {/* Type (Description) Editable */}
                          <td>
                            <input className="editExpense"
                              type="text"
                              value={editedFixedExpense.type}
                              onChange={(e) =>
                                setEditedFixedExpense({
                                  ...editedFixedExpense,
                                  type: e.target.value,
                                })
                              }
                            />
                          </td>

                          {/* Load (Non-Editable) */}
                          <td>{item.exportLoad.load}</td>

                          {/* Spent USD Editable */}
                          <td>
                            <input className="editExpense"
                              type="number"
                              value={editedFixedExpense.spentUsd}
                              onChange={(e) =>
                                setEditedFixedExpense({
                                  ...editedFixedExpense,
                                  spentUsd: e.target.value,
                                })
                              }
                            />
                          </td>

                          <td>
                            <button
                              onClick={() =>
                                handleSaveFixedExpenseEdit(item._id)
                              }
                            >
                              Save
                            </button>
                            <button
                              onClick={() => setEditingFixedExpenseId(null)}
                            >
                              Cancel
                            </button>
                          </td>
                        </>
                      ) : (
                        <>
                          {/* Non-edit mode */}
                          <td className="inputExportDetailsType">
                            {item.expenseType}
                          </td>
                          <td className="inputExportDetailsType">
                            {item.type}
                          </td>
                          <td className="inputExportDetailsDate">
                            {item.exportLoad.load}
                          </td>
                          <td className="inputExportDetails">
                            $ {item.spentUsd}
                          </td>
                          <td className="inputExportDetails">
                            <button
                              onClick={() => handleFixedExpenseEditClick(item)}
                            >
                              Edit
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="exportExpensesBox">
              <div className="h2HeadersStockDetails">
                Variable Expenses
              </div>
              <table className="table50">
                <thead className="table50">
                  <tr className="table50">
                    <th className="table50hexpenses">Date</th>
                    <th className="table50hexpenses">Type</th>
                    <th className="table50hexpenses">Desc</th>
                    <th className="table50hexpenses">Load</th>
                    <th className="table50hexpenses">Spent</th>
                    <th className="table50hexpenses">Edit</th>
                  </tr>
                </thead>
                <tbody className="table50">
                  {expense.variableExpenses.map((item) => (
                    <tr className="table50" key={item._id}>
                      <td className="inputExportDetailsDate">
                        {formatDate(item.created)}
                      </td>
                      <td className="inputExportDetailsType">
                        {editingExpenseId === item._id ? (
                          <select
                            value={editedExpense.expenseType}
                            onChange={(e) =>
                              setEditedExpense({
                                ...editedExpense,
                                expenseType: e.target.value,
                              })
                            }
                          >
                            {[
                              "Wages",
                              "Bank Charges",
                              "Warehouse Rent",
                              "Warehouse",
                              "Accommodation",
                              "Office",
                              "Petrol",
                              "Transport",
                              "Shipping",
                              "Telephones",
                              "Others",
                              "Staff Related",
                            ].map((expense, index) => (
                              <option key={index} value={expense}>
                                {expense}
                              </option>
                            ))}
                          </select>
                        ) : (
                          item.expenseType
                        )}
                      </td>
                      <td className="inputExportDetailsType">{item.type}</td>
                      <td className="inputExportDetailsDate">
                        {item.exportLoad.load}
                      </td>
                      <td className="inputExportDetails">
                        {editingExpenseId === item._id ? (
                          <input className="editExpense"
                            type="number"
                            value={editedExpense.spentUsd}
                            onChange={(e) =>
                              setEditedExpense({
                                ...editedExpense,
                                spentUsd: e.target.value,
                              })
                            }
                          />
                        ) : (
                          `$ ${item.spentUsd}`
                        )}
                      </td>
                      <td className="inputExportDetails">
                        {editingExpenseId === item._id ? (
                          <>
                            <button onClick={() => handleSaveEdit(item._id)}>
                              Save
                            </button>
                            <button onClick={handleCancelEdit}>Cancel</button>
                          </>
                        ) : (
                          <button onClick={() => handleEditClick(item)}>
                            Edit
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MonthlyExpense;
