import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./export.css";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPlane,
  faUpload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../userAuth";

const ExportDetails = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const { id } = useParams();
  const [selectedFiles, setSelectedFiles] = useState({});
  const [uploading, setUploading] = useState(false);
  const [isShipped, setIsShipped] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [lotsData, setLotsData] = useState([]);
  const [totalUsdSpentInd, setTotalUsdSpentInd] = useState(0);
  const [selectedLoadId, setSelectedLoadId] = useState(null);
  const [selectedLots, setSelectedLots] = useState([]); // Stores the indLots of the selected load
  const [netCrg, setNetCrg] = useState(null);
  const [netTechXrf, setNetTechXrf] = useState(null);
  const [averageBought, setAverageBought] = useState({ pt: 0, pd: 0, rh: 0 });
  const [netIcp, setNetIcp] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  const [editedData, setEditedData] = useState(null);
  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';
  
  const fetchExportById = useCallback(async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/exportsingleload/${id}`);
      const lots = response.data.lotsAll.indLots || [];
      setExportData(response.data);
      setEditedData(response.data);
      setLotsData(lots)

      const totalUsdSpent = lots.reduce(
        (sum, lot) => sum + (lot.usdSpentInd || 0),
        0
      );
      setTotalUsdSpentInd(totalUsdSpent);

      // Compute weighted averages
      const totalKilograms = lots.reduce(
        (sum, lot) => sum + (lot.kilogramsInd || 0),
        0
      );

      let totalPlatinum = 0,
        totalPalladium = 0,
        totalRhodium = 0;

      lots.forEach((lot) => {
        const proportion =
          totalKilograms > 0 ? (lot.kilogramsInd || 0) / totalKilograms : 0;
        totalPlatinum += (lot.ptLme || 0) * proportion;
        totalPalladium += (lot.pdLme || 0) * proportion;
        totalRhodium += (lot.rhLme || 0) * proportion;
      });

      const computedAverageBought = {
        pt: totalPlatinum,
        pd: totalPalladium,
        rh: totalRhodium,
      };

      setAverageBought(computedAverageBought);

      setEditedData((prev) => ({
        ...prev,
        averageBought: computedAverageBought,
      }));

      setNetCrg(
        (
          response.data.estGrossCrg -
          response.data.variableExpenses -
          response.data.fixedExpenses -
          response.data.exportExpenses -
          response.data.lotsAll.usdSpentTotal
        ).toFixed(0)
      );
      setNetTechXrf(
        (
          response.data.estGrossTechXrf -
          response.data.variableExpenses -
          response.data.fixedExpenses -
          response.data.exportExpenses -
          response.data.lotsAll.usdSpentTotal
        ).toFixed(0)
      );
      setNetIcp(
        (
          response.data.estGrossTechXrf -
          response.data.variableExpenses -
          response.data.fixedExpenses -
          response.data.exportExpenses -
          response.data.lotsAll.usdSpentTotal
        ).toFixed(0)
      );
      setIsShipped(response.data.shipped);

      // Trigger proportion calculation
      await axios.post(
        `${baseUrl}/api/export/load-all-proportion-calculation/${id}`
      );
    } catch (error) {
      console.error("Error fetching export document:", error);
    }
  }, [id, baseUrl]);

  // Fetch Expenses
  const fetchExpenses = useCallback(async () => {
    if (!exportData) return;
    try {
      const response = await axios.get(
        `${baseUrl}/api/expenses/perload/${exportData.load}`
      );

      const totalFixedExpenses = response.data.reduce((acc, expense) => {
        return (
          acc +
          expense.fixedExpenses.reduce((sum, curr) => sum + curr.spentUsd, 0)
        );
      }, 0);

      const totalVariableExpenses = response.data.reduce((acc, expense) => {
        return (
          acc +
          expense.variableExpenses.reduce((sum, curr) => sum + curr.spentUsd, 0)
        );
      }, 0);

      const totalExportExpenses = response.data.reduce((acc, expense) => {
        return (
          acc + expense.usdExport.reduce((sum, curr) => sum + curr.usdSpent, 0)
        );
      }, 0);

      setEditedData((prevState) => ({
        ...prevState,
        fixedExpenses: totalFixedExpenses,
        variableExpenses: totalVariableExpenses,
        usdExportExpenses: totalExportExpenses,
      }));
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  }, [exportData, baseUrl]);

  // Initial data fetch
  useEffect(() => {
    fetchExportById();
  }, [fetchExportById]);

  useEffect(() => {
    fetchExpenses();
  }, [fetchExpenses]);

  const handleChange = (e, key, nestedKey) => {
    const { value } = e.target;
    if (nestedKey) {
      setEditedData((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [nestedKey]: value,
        },
      }));
    } else {
      setEditedData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleSave = async () => {
    try {
      // Include the calculated values in the editedData object
      const updatedData = {
        ...editedData,
        estNetCrg: netCrg,
        estNetTechIcp: netIcp,
        estNetTechXrf: netTechXrf,
      };

      // Make a PUT request to update the export document with the updatedData object
      await axios.put(`${baseUrl}/api/exports-edit-load/${id}`, updatedData);
      setExportData(updatedData);

      alert("Saved successfully!");
      fetchExportById();
      fetchExpenses();
    } catch (error) {
      console.error("Error updating export document:", error);
    }
  };

  const handleSendExport = async () => {
    try {
      // Send a PUT request with totalUsdSpent
      await axios.put(`${baseUrl}/api/shipping/exports/${id}`, {
      });
  
      setIsShipped(true);
      alert("Export successful!");
    } catch (error) {
      console.error("Error exporting:", error);
      alert("Error exporting. Please try again later.");
    }
  };

  const handleViewLoad = async (loadId) => {
    if (selectedLoadId === loadId) {
      // Clicking the same load closes the expanded section
      setSelectedLoadId(null);
      setSelectedLots([]);
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}/api/single-load/${loadId}`);
      setSelectedLoadId(loadId);
      setSelectedLots(response.data.lotsAll?.indLots || []);
    } catch (error) {
      console.error("Error fetching load details:", error);
    }
  };

  const handleFileChange = (event, docType) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prev) => ({
        ...prev,
        [docType]: file,
      }));
    }
  };

  const handleUpload = async () => {
    if (Object.keys(selectedFiles).length === 0) {
      alert("Please select at least one document to upload.");
      return;
    }

    const formData = new FormData();
    Object.entries(selectedFiles).forEach(([key, file]) => {
      formData.append(key, file);
    });

    setUploading(true);
    try {
      const response = await fetch(`${baseUrl}/api/upload-docs/${id}`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        alert("Documents uploaded successfully!");
        setSelectedFiles({}); // Reset after upload
      } else {
        alert(`Error: ${result.error}`);
      }
      fetchExportById();
      fetchExpenses();
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Failed to upload documents.");
    }
    setUploading(false);
  };

  const handleViewDocument = async (docType) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/get-signed-url/${id}/${docType}`
      );
      const data = await response.json();
      if (data.url) {
        window.open(data.url, "_blank");
      } else {
        alert("Document not found.");
      }
      fetchExportById();
      fetchExpenses();
    } catch (error) {
      console.error("Error fetching signed URL:", error);
      alert("Failed to open document.");
    }
  };

  if (!exportData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="stock-second">
      {isLoggedIn && title === "Admin" && (
        <>
          <div className="exports-third-row">
            <div className="h1HeadersStockDetailsBig">
              Export Load {exportData.load}
            </div>
          </div>

          <div className="upload-doc-container">
            <div className="h1HeadersStockDetails">Upload Documents</div>

            <div className="upload-btn-group">
              {[
                "profitLoss",
                "invoice",
                "packingList",
                "exportDoc",
                "refineryLabel",
              ].map((docType) => (
                <div key={docType} className="upload-item">
                  <label>
                    <FontAwesomeIcon icon={faUpload} className="upload-icon" />
                    {selectedFiles[docType]
                      ? selectedFiles[docType].name
                      : docType}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, docType)}
                    />
                  </label>

                  {/* Show Eye Icon if the Document Exists */}
                  {exportData?.documents?.[docType] && (
                    <FontAwesomeIcon
                      icon={faEye}
                      className="view-icon"
                      onClick={() => handleViewDocument(docType)}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        color: "blue",
                      }}
                    />
                  )}
                </div>
              ))}
            </div>

            {/* ✅ Upload Button Added Back */}
            <button
              className="upload-button"
              onClick={handleUpload}
              disabled={uploading}
            >
              {uploading ? "Uploading..." : "Upload"}
            </button>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">CRG Final</div>
              <table className="table50">
                <thead className="table50">
                  <tr className="table50">
                    <th className="table50th">Spent</th>
                    <th className="table50th">Weight</th>
                    <th className="table50th">PT Ppm</th>
                    <th className="table50th">PD Ppm</th>
                    <th className="table50th">RH Ppm</th>
                    <th className="table50th">Est. Gross</th>
                    <th className="table50th">Est. Margin</th>
                    <th className="table50th">Final Gross</th>
                    <th className="table50th">Final Margin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table50td">
                      ${editedData.autoAddedSpent.toFixed(0)}
                    </td>
                    <td className="table50td">
                      {(editedData.crgProportion.weight / 1000).toFixed(2)} Kg
                    </td>
                    <td className="table50td">
                      {editedData.crgProportion.ptPpm.toFixed(0)}
                    </td>
                    <td className="table50td">
                      {editedData.crgProportion.pdPpm.toFixed(0)}
                    </td>
                    <td className="table50td">
                      {editedData.crgProportion.rhPpm.toFixed(0)}
                    </td>
                    <td className="table50td">
                      $
                      {(editedData.estGrossCrg - editedData.autoAddedSpent).toFixed(
                        0
                      )}
                    </td>
                    <td className="table50td">
                      {(
                        (editedData.estGrossCrg / editedData.autoAddedSpent) *
                        100
                      ).toFixed(0)}
                      %
                    </td>

                    <td className="table50td">
                      $
                      {editedData.estGrossTechXrf &&
                      editedData.estGrossTechXrf > 0
                        ? editedData.estGrossTechXrf.toFixed(0) -
                          editedData.autoAddedSpent
                        : editedData.estGrossTechIcp &&
                          editedData.estGrossTechIcp > 0
                        ? editedData.estGrossTechIcp.toFixed(0) -
                        editedData.autoAddedSpent
                        : (editedData.estGrossCrg || 0) -
                        editedData.autoAddedSpent}
                    </td>

                    <td className="table50td">
                      {editedData.estGrossTechXrf &&
                      editedData.estGrossTechXrf > 0
                        ? (
                            (editedData.estGrossTechXrf / editedData.autoAddedSpent - 1) *
                            100
                          ).toFixed(0)
                        : editedData.estGrossTechIcp &&
                          editedData.estGrossTechIcp > 0
                        ? (
                            (editedData.estGrossTechIcp / editedData.autoAddedSpent) *
                            100
                          ).toFixed(0)
                        : (
                            (editedData.estGrossCrg / editedData.autoAddedSpent - 1) *
                            100
                          ).toFixed(0)}
                      %
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">Metals Stock Price</div>
              <table className="table50">
                <thead className="table50">
                  <tr className="table50">
                    <th className="table50th">PT OZ</th>
                    <th className="table50th">PD OZ</th>
                    <th className="table50th">RH OZ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table50td">{averageBought.pt.toFixed(0)}</td>
                    <td className="table50td">{averageBought.pd.toFixed(0)}</td>
                    <td className="table50td">{averageBought.rh.toFixed(0)}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">Estimated Metals</div>
              <table className="table50">
                <thead className="table50">
                  <tr className="table50">
                    <th className="table50th">PT OZ</th>
                    <th className="table50th">PD OZ</th>
                    <th className="table50th">RH OZ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table50td">
                      {editedData.estimatedMetals.ptOz.toFixed(2)}
                    </td>
                    <td className="table50td">
                      {editedData.estimatedMetals.pdOz.toFixed(2)}
                    </td>
                    <td className="table50td">
                      {editedData.estimatedMetals.rhOz.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">Fixations</div>
              <table className="table50">
                <thead className="table50">
                  <tr className="table50">
                    <th className="table50th">PT LME</th>
                    <th className="table50th">PD LME</th>
                    <th className="table50th">RH LME</th>
                    <th className="table50th">PT Oz</th>
                    <th className="table50th">PD Oz</th>
                    <th className="table50th">RH Oz</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        className="inputExportDetailsFixations"
                        type="number"
                        value={editedData.fixedLme.ptLme}
                        onChange={(e) => handleChange(e, "fixedLme", "ptLme")}
                      />
                    </td>
                    <td>
                      <input
                        className="inputExportDetailsFixations"
                        type="number"
                        value={editedData.fixedLme.pdLme}
                        onChange={(e) => handleChange(e, "fixedLme", "pdLme")}
                      />
                    </td>
                    <td>
                      <input
                        className="inputExportDetailsFixations"
                        type="number"
                        value={editedData.fixedLme.rhLme}
                        onChange={(e) => handleChange(e, "fixedLme", "rhLme")}
                      />
                    </td>
                    <td>
                      <input
                        className="inputExportDetailsFixations"
                        type="number"
                        value={editedData.fixedLme.ptOz}
                        onChange={(e) => handleChange(e, "fixedLme", "ptOz")}
                      />
                    </td>
                    <td>
                      <input
                        className="inputExportDetailsFixations"
                        type="number"
                        value={editedData.fixedLme.pdOz}
                        onChange={(e) => handleChange(e, "fixedLme", "pdOz")}
                      />
                    </td>
                    <td>
                      <input
                        className="inputExportDetailsFixations"
                        type="number"
                        value={editedData.fixedLme.rhOz}
                        onChange={(e) => handleChange(e, "fixedLme", "rhOz")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">CRG XRF ${netCrg} Net</div>
              <table className="xrfTables">
                <tbody className="xrfTables">
                  <tr className="xrfTables">
                    <th className="xrfTablesHeaders">Weight</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.crgXrf.weight}
                        onChange={(e) => handleChange(e, "crgXrf", "weight")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PT Ppm</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.crgXrf.ptPpm}
                        onChange={(e) => handleChange(e, "crgXrf", "ptPpm")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PD Ppm</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.crgXrf.pdPpm}
                        onChange={(e) => handleChange(e, "crgXrf", "pdPpm")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">RH Ppm</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.crgXrf.rhPpm}
                        onChange={(e) => handleChange(e, "crgXrf", "rhPpm")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PT Oz</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.crgXrf.ptOz}
                        onChange={(e) => handleChange(e, "crgXrf", "ptOz")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PD Oz</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.crgXrf.pdOz}
                        onChange={(e) => handleChange(e, "crgXrf", "pdOz")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">RH Oz</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.crgXrf.rhOz}
                        onChange={(e) => handleChange(e, "crgXrf", "rhOz")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">Value</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.estGrossCrg}
                        onChange={(e) => handleChange(e, "estGrossCrg")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">
                T XRF ${netTechXrf} Net
              </div>
              <table className="xrfTables">
                <tbody className="xrfTables">
                  <tr className="xrfTables">
                    <th className="xrfTablesHeaders">Weight</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tXrf.weight}
                        onChange={(e) => handleChange(e, "tXrf", "weight")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PT Ppm</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tXrf.ptPpm}
                        onChange={(e) => handleChange(e, "tXrf", "ptPpm")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PD Ppm</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tXrf.pdPpm}
                        onChange={(e) => handleChange(e, "tXrf", "pdPpm")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">RH Ppm</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tXrf.rhPpm}
                        onChange={(e) => handleChange(e, "tXrf", "rhPpm")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PT Oz</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tXrf.ptOz}
                        onChange={(e) => handleChange(e, "tXrf", "ptOz")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PD Oz</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tXrf.pdOz}
                        onChange={(e) => handleChange(e, "tXrf", "pdOz")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">RH Oz</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tXrf.rhOz}
                        onChange={(e) => handleChange(e, "tXrf", "rhOz")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">Value</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.estGrossTechXrf}
                        onChange={(e) => handleChange(e, "estGrossTechXrf")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">T ICP ${netIcp} Net</div>
              <table className="xrfTables">
                <tbody className="xrfTables">
                  <tr className="xrfTables">
                    <th className="xrfTablesHeaders">Weight</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tIcp.weight}
                        onChange={(e) => handleChange(e, "tIcp", "weight")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PT Ppm</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tIcp.ptPpm}
                        onChange={(e) => handleChange(e, "tIcp", "ptPpm")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PD Ppm</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tIcp.pdPpm}
                        onChange={(e) => handleChange(e, "tIcp", "pdPpm")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">RH Ppm</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tIcp.rhPpm}
                        onChange={(e) => handleChange(e, "tIcp", "rhPpm")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PT Oz</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tIcp.ptOz}
                        onChange={(e) => handleChange(e, "tIcp", "ptOz")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">PD Oz</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tIcp.pdOz}
                        onChange={(e) => handleChange(e, "tIcp", "pdOz")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">RH Oz</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.tIcp.rhOz}
                        onChange={(e) => handleChange(e, "tIcp", "rhOz")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">Value</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.estGrossTechIcP}
                        onChange={(e) => handleChange(e, "estGrossTechIcP")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="bottomButtonRow">
            <div className="exportCreateLoadButton" onClick={handleSave}>
              <FontAwesomeIcon className="dashboardIcons" icon={faSave} />
              <span>Save</span>
            </div>
            {/* Check if any required document is missing */}
            {!isShipped &&
            (!exportData.documents.refineryLabel ||
              !exportData.documents.profitLoss ||
              !exportData.documents.packingList ||
              !exportData.documents.invoice ||
              !exportData.documents.exportDoc) ? (
              <>
                <p className="error-message">
                  Please upload all required documents before exporting.
                </p>
                <div className="exportCreateLoadButton disabled">
                  <FontAwesomeIcon className="dashboardIcons" icon={faPlane} />
                  <span>Export</span>
                </div>
              </>
            ) : (
              <div
                className="exportCreateLoadButton"
                onClick={handleSendExport}
              >
                <FontAwesomeIcon className="dashboardIcons" icon={faPlane} />
                <span>Export</span>
              </div>
            )}
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <table className="table50">
                <thead>
                  <tr>
                  <th>Load</th>
                    <th>Spent</th>
                    <th>Gross</th>
                    <th>Margin</th>
                    <th>Kg</th>
                    <th>Pt</th>
                    <th>Pd</th>
                    <th>Rh</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {lotsData.map((lot, index) => {
                    const assignedLoadId = lot.assignedLoad?.id;
                    const isExpanded = selectedLoadId === assignedLoadId; // ✅ Fixes comparison

                    return (
                      <React.Fragment key={lot._id || index}>
                        <tr>
                          <td>
                            {lot.assignedLoad ? lot.assignedLoad.load : "N/A"}
                          </td>
                          <td>${lot.usdSpentMinusResell}</td>
                          <td>${lot.usdValueInd}</td>
                          <td>
                            {lot.usdSpentInd
                              ? (
                                  (lot.usdValueInd / lot.usdSpentMinusResell) *
                                  100
                                ).toFixed(2)
                              : "N/A"}
                            %
                          </td>
                          <td>{lot.crgXrf.weight}</td>
                          <td>{lot.crgXrf.ptPpm}</td>
                          <td>{lot.crgXrf.pdPpm}</td>
                          <td>{lot.crgXrf.rhPpm}</td>
                          <td>
                            <button
                              onClick={() => handleViewLoad(assignedLoadId)}
                            >
                              {isExpanded ? "Close" : "View"}
                            </button>
                          </td>
                        </tr>

                        {/* Expanded row for lotsAll.indLots */}
                        {isExpanded && selectedLots.length > 0 && (
                          <tr>
                            <td colSpan="6">
                              <table className="nestedTable">
                                <thead>
                                  <tr>
                                    <th>Index</th>
                                    <th>Customer</th>
                                    <th>USD Spent</th>
                                    <th>Kilograms</th>
                                    <th>USD Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedLots.map((subLot, subIndex) => (
                                    <tr key={subLot._id || subIndex}>
                                      <td>{subIndex + 1}</td>
                                      <td>
                                        {subLot.contactCrg?.name || "N/A"}
                                      </td>
                                      <td>${subLot.usdSpentInd}</td>
                                      <td>{subLot.kilogramsInd}</td>
                                      <td>${subLot.usdValueInd}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExportDetails;
