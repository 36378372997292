// GoalCharts.js

import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto'; 
import './goalcharts.css'; 

const GoalCharts = () => {
    // State to hold the total number of contacts and served contacts
    const [totalContacts, setTotalContacts] = useState(0);
    const [servedContacts, setServedContacts] = useState(0);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

    // Function to fetch contacts from backend
    const fetchContacts = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/contacts`);
            if (!response.ok) {
                throw new Error('Failed to fetch contacts');
            }
            const contacts = await response.json();
            setTotalContacts(contacts.length);
            
            // Calculate served contacts
            const currentMonth = new Date().getMonth();
            const servedContactsCount = contacts.reduce((count, contact) => {
                const nextPurchaseDate = new Date(contact.nextPurchase);
                const kilogram = contact.ceramics.kilogram;
                if (kilogram > 0 && nextPurchaseDate.getMonth() !== currentMonth) {
                    return count + 1;
                }
                return count;
            }, 0);
            setServedContacts(servedContactsCount);
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

// Function to create the pie chart
const createPieChart = () => {
    const canvas = document.getElementById('contact-pie-chart');
    if (!canvas) return;

    // Destroy existing chart instance if it exists
    if (window.contactPieChart) {
        window.contactPieChart.destroy();
    }

    const ctx = canvas.getContext('2d');

    const data = {
        labels: ['Served', 'Remaining'],
        datasets: [{
            data: [servedContacts, totalContacts - servedContacts],
            backgroundColor: ['green', 'red']
        }]
    };

    // Create the pie chart
    window.contactPieChart = new Chart(ctx, {
        type: 'pie',
        data: data,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                      color: 'white' // Set legend label color to white
                    }
                },
                title: {
                    display: true,
                    text: 'TOTAL CONTACTS',
                    color: 'white'
                }
            },

        }
    });
};

    // Fetch contacts and create the pie chart when component mounts
    useEffect(() => {
        fetchContacts();
    }, []);

    // Recreate the pie chart when totalContacts or servedContacts change
    useEffect(() => {
        createPieChart();
    }, [totalContacts, servedContacts]);

    return (
        <div className="goal-charts-main">
            <canvas id="contact-pie-chart"></canvas>
        </div>
    );
};

export default GoalCharts;
