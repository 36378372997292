import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../userAuth';
import './goal.css';

function Goal() {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
    const [formData, setFormData] = useState({
      month: '',
      kilograms: '',
      grossProfit: '',
      fixedExpenses: '',
      variableExpenses: '',
      exportExpenses: ''
    });
    const [goalExists, setGoalExists] = useState(false);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/login');
      }
    }, [isLoggedIn, navigate]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await fetch(`${baseUrl}/api/goals`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        if (!response.ok) {
          throw new Error('Failed to create goal');
        }
        alert('Goal created successfully!');
        // Reset form fields
        setFormData({
          month: '',
          kilograms: '',
          grossProfit: '',
          fixedExpenses: '',
          variableExpenses: '',
          exportExpenses: ''
        });
        // Redirect to '/crg-dashboard'
        navigate('/crg-dashboard');
      } catch (error) {
        console.error('Error:', error);
        alert('Failed to create goal');
      }
    };

    useEffect(() => {
      const fetchGoalForCurrentMonth = async () => {
        try {
          const response = await fetch(`${baseUrl}/api/goals`);
          if (!response.ok) {
            throw new Error('Failed to fetch goals');
          }
          const goals = await response.json();
          const currentMonth = new Date().toISOString().slice(0, 7); // Get current month in "YYYY-MM" format
          const currentMonthGoal = goals.find(goal => goal.month.slice(0, 7) === currentMonth);

          if (currentMonthGoal) {
            setGoalExists(true);
            setFormData({
              ...currentMonthGoal,
              month: currentMonthGoal.month.slice(0, 7), // Truncate to "YYYY-MM" format for input type="month"
            });
          } else {
            setGoalExists(false);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchGoalForCurrentMonth();
    }, [baseUrl]);

    return (
      <div className="goal-container-setgoal">
                    {isLoggedIn && title === 'Admin' && (
    <>
        <h1 className='h1HeadersStockDetailsBigWhite'>Create a New Goal</h1>
        <form className='setGoal' onSubmit={handleSubmit}>
          <label htmlFor="month">Month:</label>
          <input  className='inputTask' type="month" id="month" name="month" value={formData.month} onChange={handleChange} required /><br/><br/>
          <label htmlFor="kilograms">Kilograms:</label>
          <input  className='inputTask' type="number" id="kilograms" name="kilograms" value={formData.kilograms} onChange={handleChange} disabled={goalExists} required /><br/><br/>
          <label htmlFor="grossProfit">Gross Profit:</label>
          <input className='inputTask' type="number" id="grossProfit" name="grossProfit" value={formData.grossProfit} onChange={handleChange} disabled={goalExists} required /><br/><br/>
          <label htmlFor="fixedExpenses">Fixed Expenses:</label>
          <input  className='inputTask'type="number" id="fixedExpenses" name="fixedExpenses" value={formData.fixedExpenses} onChange={handleChange} disabled={goalExists} required /><br/><br/>
          <label htmlFor="variableExpenses">Variable Expenses:</label>
          <input className='inputTask' type="number" id="variableExpenses" name="variableExpenses" value={formData.variableExpenses} onChange={handleChange} disabled={goalExists} required /><br/><br/>
          <label htmlFor="exportExpenses">Export Expenses:</label>
          <input className='inputTask' type="number" id="exportExpenses" name="exportExpenses" value={formData.exportExpenses} onChange={handleChange} disabled={goalExists} required /><br/><br/>
          <button className='expenseAllButton' type="submit" disabled={goalExists}>Submit</button>
        </form>
        </>
            )}
      </div>
    );
}

export default Goal;
