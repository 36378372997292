import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { useAuth } from '../userAuth'; 
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import './calendarCustomer.css';

const CalendarView = () => {
  const { isLoggedIn } = useAuth();
  const [date, setDate] = useState(new Date());
  const [contactData, setContactData] = useState([]);
  const [selectedContactType, setSelectedContactType] = useState(null);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = location.state?.userInfo || {};

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/contacts`);
        setContactData(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, [baseUrl]);

  const handleContactClick = (contactId) => {
    navigate(`/contact-details/${contactId}`, { state: { userInfo } });
  };

  const filterContactsByType = (type) => {
    setSelectedContactType(type);
  };

  const clearFilter = () => {
    setSelectedContactType(null);
  };

  const filteredContactData = selectedContactType
    ? contactData.filter((contact) => contact.typeOfContact.toLowerCase() === selectedContactType.toLowerCase())
    : contactData;

    return (
      <div className='calendarMain'>
        {isLoggedIn && (
          <div className='filter-buttons'>
            {/* Add buttons for each type of contact */}
            <button onClick={() => filterContactsByType('Delivery')}>Delivery</button>
            <button onClick={() => filterContactsByType('Visit')}>Visit</button>
            {userInfo.position !== 'Sales' && (
              <button onClick={() => filterContactsByType('Call')}>Call</button>
            )}
            <button onClick={clearFilter}>Clear Filter</button>
          </div>
        )}
    
        <Calendar
          onChange={setDate}
          value={date}
          tileContent={({ date }) => {
            const formattedDate = date.toLocaleDateString();
            const contactsOnDate = filteredContactData.filter((contact) => {
              const contactDate = new Date(contact.nextContact);
              return contactDate.toLocaleDateString() === formattedDate;
            });
    
            return (
              <div className="event-marker">
                {contactsOnDate.map((contact) => {
                  if (userInfo.position === 'Sales' && !['Visit', 'Delivery'].includes(contact.typeOfContact)) {
                    return null; // Do not display contacts other than 'Visit' and 'Delivery' for Sales
                  }
    
                  return (
                      <div
                        key={contact._id}
                        className={`contact-event ${contact.typeOfContact.toLowerCase()}`}
                        onClick={userInfo.position === 'Sales' ? undefined : () => handleContactClick(contact._id)}
                      >

                      {contact.name}
                      <FontAwesomeIcon icon={faArrowRight} className="visitIcon" />
                    </div>
                  );
                })}
              </div>
            );
          }}
        />
      </div>
    );    
};

export default CalendarView;
