import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../userAuth";
import "../../dashboard/export/export.css";

const StockWeee = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  const [weeeStock, setWeeeStock] = useState([]);
  const [weee, setWeee] = useState([]);
  const [weeeStockMain, setWeeeStockMain] = useState([]);
  const [loads, setLoads] = useState([]);
  const [loadsShipped, setLoadsShipped] = useState([]);
  const [selectedLoad, setSelectedLoad] = useState(null);
  const [exportsStock, setExportsStock] = useState([]);
  const [crgData, setCrgData] = useState(null);
  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  const fetchWeeeStock = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/weee`);
      setWeeeStock(response.data[0].ceramicsCrg.indCeramicsCrg);
      setWeee(response.data);
      setCrgData(response.data[0]);
    } catch (error) {
      console.error("Error fetching lambda data:", error);
    }
  };

  const fetchWeeeStockMain = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/weeestock/main`);
      setWeeeStockMain(response.data[0]);
    } catch (error) {
      console.error("Error fetching lambda data:", error);
    }
  };

  const fetchExportsInStock = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/exports/weee/instock`);
      setExportsStock(response.data);
    } catch (error) {
      console.error("Error fetching export data:", error);
    }
  };

  const fetchLoadsInStock = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/loads/stock/weee`);
      const loadsData = response.data;
      setLoads(loadsData);
    } catch (error) {
      console.error("Error fetching loads data:", error);
    }
  };

  const fetchLoadsShipped = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/loads/shipped/weee`);
      const shippedLoads = response.data;
      setLoadsShipped(shippedLoads);
    } catch (error) {
      console.error("Error fetching loads data:", error);
    }
  };
  

  useEffect(() => {
    fetchLoadsInStock();
    fetchLoadsShipped();
    fetchWeeeStock();
    fetchWeeeStockMain();
    fetchExportsInStock();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year

    // Pad single digits with leading zero
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const sumKilograms = () => {
    if (!crgData) return 0;
    return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
      return total + item.kilogramsCrg;
    }, 0);
  };

  const sumSpent = () => {
    if (!crgData) return 0;
    return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
      return total + item.usdSpentCrg;
    }, 0);
  };

  const handleAddToLoadWeee = async (item, loadId) => {
    if (!loadId) {
      alert("Please select a load first.");
      return;
    }
    try {
      await axios.put(
        `${baseUrl}/api/loads/weee/assign-item/from-stock/${loadId}`,
        item // Send item directly
      );
      fetchWeeeStock();
      fetchLoadsInStock();
      fetchLoadsShipped();
    } catch (error) {
      console.error("Error adding to load:", error);
    }
  };

  const handleLoadsClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate(
      "/crg-all-loads/lasedsoi659eyrgpoiut4q3w908ufa0eihrtaaisodwweiorouepgof;"
    );
  };

  const handleAssignLoad = async (loadId, exportId) => {
    if (!exportId) {
      alert("Please select an export before assigning.");
      return;
    }

    try {
      // Fetch the current Load document
      const currentLoad = loads.find((load) => load._id === loadId);
      if (!currentLoad) {
        alert("Load not found.");
        return;
      }
      // Fetch the new selected Export object
      const selectedExport = exportsStock.find((exp) => exp._id === exportId);
      if (!selectedExport) {
        alert("Invalid export selection.");
        return;
      }

      const exportUpdateResponse = await axios.put(
        `${baseUrl}/api/exports/weee/${exportId}/assign-load`,
        { loadId: loadId, loadValue: currentLoad.load } // Pass load value
      );

      if (!exportUpdateResponse.data.success) {
        throw new Error(exportUpdateResponse.data.message);
      }

      // Update the Load with the new Export
      const loadUpdateResponse = await axios.put(
        `${baseUrl}/api/loads/${loadId}/assign-export`,
        {
          exportId: exportId,
          exportLoadName: selectedExport.load,
          shipped: true,
        }
      );

      if (!loadUpdateResponse.data.success) {
        throw new Error(loadUpdateResponse.data.message);
      }

      // **NEW API CALL: Update Weeestock with Load values**
      const weeestockUpdateResponse = await axios.put(
        `${baseUrl}/api/weeestock/update-from-load/${loadId}`
      );

      if (!weeestockUpdateResponse.data.success) {
        throw new Error(weeestockUpdateResponse.data.message);
      }

      alert("Load successfully reassigned to Export and Weeestock updated!");

      fetchLoadsInStock();
      fetchLoadsShipped();
      fetchWeeeStock();
      fetchExportsInStock();
    } catch (error) {
      console.error("Error reassigning load:", error);
      alert("Failed to reassign load.");
    }
  };

  const handleViewLoad = async (loadId) => {
    navigate(`/view-edit-single-load-weee/${loadId}`);
  };

  const handleViewWeeeStock = async () => {
    navigate("/view-weee-full-stock");
  };

  const handleViewExport = (loadId) => {
    navigate(
      `/crg-export-weee-details/asd6ar5e459843y4tr65j4hk6yu5y4usfd5g1a+f56ds4f+af/${loadId}`
    );
  };

  const totalUsdSpentStockMain = weeeStockMain?.lotsAll?.indLots
  ?.flatMap(lot => lot.weeGrades || [])  // Extract all weeGrades from each lot
  ?.reduce((sum, item) => sum + (item.totalPaid || 0), 0);

const totalUsdValueStockMain = weeeStockMain?.lotsAll?.indLots
  ?.flatMap(lot => lot.weeGrades || []) 
  ?.reduce((sum, item) => sum + (item.sellValue || 0), 0);

const totalKilogramsStockMain = weeeStockMain?.lotsAll?.indLots
  ?.flatMap(lot => lot.weeGrades || []) 
  ?.reduce((sum, item) => sum + (item.gradeKg || 0), 0);


  return (
    <div className="stock-second">
      {isLoggedIn && title === "Admin" && (
        <>
          <div className="stock-table-containers">
            <div
              className="h1HeadersStockDetails"
              style={{ cursor: "pointer" }}
            >
              Weee Stock BreakDown
            </div>
            <table className="tableStock">
              <thead className="tableStock">
                <tr className="tableStock">
                  <th className="table50Goal">Spent</th>
                  <th className="table50Goal">Weight</th>
                  <th className="table50Goal">Sell Value</th>
                  <th className="table50Goal">Margin</th>
                  <th className="table50Goal">View</th>
                </tr>
              </thead>
              <tbody className="tableBodyStock">
                <tr className="inputStockDetails">
                  <td className="inputStockDetails">
                    ${totalUsdSpentStockMain}
                  </td>
                  <td className="inputStockDetails">
                    {totalKilogramsStockMain} kg
                  </td>
                  <td className="inputStockDetails">
                    ${totalUsdValueStockMain}
                  </td>
                  <td className="inputStockDetails">
                    {totalUsdSpentStockMain > 0
                      ? (
                          (totalUsdValueStockMain / totalUsdSpentStockMain) *
                          100
                        ).toFixed(2) + "%"
                      : "N/A"}
                  </td>
                  <td className="inputStockDetails">
                    <button
                      className="viewButton"
                      onClick={handleViewWeeeStock}
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="stock-table-containers">
            <div className="h1HeadersStockDetails">
              Unassigned Weee {sumKilograms().toFixed(2)} Kg ${sumSpent()}
            </div>
            <table className="tableStock">
              <thead className="tableStock">
                <tr className="tableStock">
                  <th className="table50Goal">Date</th>
                  <th className="table50Goal">Customer</th>
                  <th className="table50Goal">Spent</th>
                  <th className="table50Goal">Kg</th>
                  <th className="table50Goal">Gross</th>
                  <th className="table50Goal">Assign</th>
                </tr>
              </thead>
              <tbody className="tableBodyStock">
                {weeeStock.map((item) => (
                  <tr className="inputStockDetails" key={item._id}>
                    <td className="inputStockDetails">
                      {formatDate(item.dateCrg)}
                    </td>
                    <td className="inputStockDetails">
                      {item.contactCrg?.name || item.assignedLoad?.load}
                    </td>
                    <td className="inputStockDetails">${item.usdSpentCrg}</td>
                    <td className="inputStockDetails">{item.kilogramsCrg}</td>
                    <td className="inputStockDetails">${item.usdValueCrg}</td>
                    <td className="inputStockDetails">
                      <select
                        className="exportDropdown"
                        onChange={(e) => setSelectedLoad(e.target.value)}
                        defaultValue={selectedLoad ? selectedLoad.load : ""}
                      >
                        <option value="">Select Load</option>
                        {loads
                          .filter(
                            (load) =>
                              load.material === "Weee" &&
                              load.lotsAll?.indLots?.length === 0
                          )
                          .map((load) => (
                            <option key={load._id} value={load.load}>
                              {load.load}
                            </option>
                          ))}
                      </select>
                      <button
                        onClick={() => {
                          const selectedLoadObject = loads.find(
                            (load) => load.load === selectedLoad
                          );
                          if (!selectedLoadObject) {
                            alert("Please select a valid load first.");
                            return;
                          }
                          handleAddToLoadWeee(item, selectedLoadObject._id); // Pass the correct _id
                        }}
                      >
                        Assign
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="stock-table-containers">
            <div
              className="h1HeadersStockDetails"
              onClick={handleLoadsClick}
              style={{ cursor: "pointer" }}
            >
              Unassigned Loads Weee
            </div>
            <table className="tableStock">
              <thead className="tableStock">
                <tr className="tableStock">
                  <th className="table50Goal">Customer</th>
                  <th className="table50Goal">Spent</th>
                  <th className="table50Goal">Gross Profit</th>
                  <th className="table50Goal">Kg Gross</th>
                  <th className="table50Goal">Margin</th>
                  <th className="table50Goal">Kg Net</th>
                  <th className="table50Goal">Net Profit</th>
                  <th className="table50Goal">Assign</th>
                  <th className="table50Goal">View</th>
                </tr>
              </thead>
              <tbody className="tableBodyStock">
                {loads.map((lot) => {
                  // Calculate sums from lotsAll.indLots
                  const totalUsdSpent = lot.lotsAll?.indLots?.reduce(
                    (sum, item) => sum + (item.usdSpentInd || 0),
                    0
                  );

                  const totalUsdValue = lot.lotsAll?.indLots?.reduce(
                    (sum, item) => sum + (item.usdValueInd || 0),
                    0
                  );

                  const totalKilograms = lot.lotsAll?.indLots?.reduce(
                    (sum, item) => sum + (item.kilogramsInd || 0),
                    0
                  );

                  return (
                    <tr className="inputStockDetails" key={lot._id}>
                      <td className="inputStockDetails">{lot.load}</td>
                      <td className="inputStockDetails">${totalUsdSpent}</td>
                      <td className="inputStockDetails">${totalUsdValue}</td>
                      <td className="inputStockDetails">{totalKilograms} kg</td>
                      <td className="inputStockDetails">
                        {totalUsdSpent > 0
                          ? ((totalUsdValue / totalUsdSpent) * 100).toFixed(2) +
                            "%"
                          : "N/A"}
                      </td>
                      <td className="inputStockDetails">
                        {lot?.lotsAll?.indLots?.[0]?.kilogramsFinal ?? 0} Kg
                      </td>
                      <td className="inputStockDetails">
                        $
                        {(
                          (lot?.lotsAll?.indLots?.[0]?.usdValueFinal ?? 0) -
                          (lot?.lotsAll?.indLots?.[0]?.usdSpentInd ?? 0)
                        ).toFixed(2)}
                      </td>

                      <td className="inputStockDetails">
                        {/* Show Load Label if available */}
                        {lot?.exportLoad && loads?.exportLoad?.load && (
                          <div className="loadLabel">{lot.exportLoad.load}</div>
                        )}

                        {lot?.material === "Weee" && // Check if indLots is empty OR the first item has invalid values
                        (!lot?.lotsAll?.indLots || // If indLots is undefined
                          (Array.isArray(lot.lotsAll.indLots) &&
                            lot.lotsAll.indLots.length === 0) || // If indLots is an empty array
                          (lot.lotsAll.indLots?.[0] && // If indLots[0] exists
                            (!lot.lotsAll.indLots[0].kilogramsFinal || // If kilogramsFinal is missing/0
                              !lot.lotsAll.indLots[0].kilogramsInd || // If kilogramsInd is missing/0
                              lot.lotsAll.indLots[0].kilogramsFinal /
                                lot.lotsAll.indLots[0].kilogramsInd <
                                0.8))) ? (
                          <button
                            className="viewButton"
                            onClick={() => handleViewLoad(lot._id)}
                          >
                            Update
                          </button>
                        ) : (
                          <select
                            className="exportDropdown"
                            onChange={(e) =>
                              handleAssignLoad(lot._id, e.target.value)
                            }
                            defaultValue={lot?.exportLoad?._id || ""}
                          >
                            <option value="">Select Export</option>
                            {exportsStock.map((exp) => (
                              <option key={exp._id} value={exp._id}>
                                {exp.load}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>

                      <td className="inputStockDetails">
                        {/* Show Load Label if available */}
                        {lot?.exportLoad && loads?.exportLoad?.load && (
                          <div className="loadLabel">{lot.exportLoad.load}</div>
                        )}

                        {/* Ensure indLots array exists and contains at least one item */}
                        {lot?.material === "Weee" && (
                          <button
                            className="viewButton"
                            onClick={() => handleViewLoad(lot._id)}
                          >
                            View
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>



          <div className="stock-table-containers">
            <div
              className="h1HeadersStockDetails"
              onClick={handleLoadsClick}
              style={{ cursor: "pointer" }}
            >
              Prepped Loads Weee
            </div>
            <table className="tableStock">
              <thead className="tableStock">
                <tr className="tableStock">
                  <th className="table50Goal">Customer</th>
                  <th className="table50Goal">Spent</th>
                  <th className="table50Goal">Gross Profit</th>
                  <th className="table50Goal">Kg Gross</th>
                  <th className="table50Goal">Margin</th>
                  <th className="table50Goal">Kg Net</th>
                  <th className="table50Goal">Net Profit</th>
                  <th className="table50Goal">View</th>
                </tr>
              </thead>
              <tbody className="tableBodyStock">
                {loadsShipped.map((lot) => {
                  // Calculate sums from lotsAll.indLots
                  const totalUsdSpent = lot.lotsAll?.indLots?.reduce(
                    (sum, item) => sum + (item.usdSpentInd || 0),
                    0
                  );

                  const totalUsdValue = lot.lotsAll?.indLots?.reduce(
                    (sum, item) => sum + (item.usdValueInd || 0),
                    0
                  );

                  const totalKilograms = lot.lotsAll?.indLots?.reduce(
                    (sum, item) => sum + (item.kilogramsInd || 0),
                    0
                  );

                  return (
                    <tr className="inputStockDetails" key={lot._id}>
                      <td className="inputStockDetails">{lot.load}</td>
                      <td className="inputStockDetails">${totalUsdSpent}</td>
                      <td className="inputStockDetails">${totalUsdValue}</td>
                      <td className="inputStockDetails">{totalKilograms} kg</td>
                      <td className="inputStockDetails">
                        {totalUsdSpent > 0
                          ? ((totalUsdValue / totalUsdSpent) * 100).toFixed(2) +
                            "%"
                          : "N/A"}
                      </td>
                      <td className="inputStockDetails">
                        {lot?.lotsAll?.indLots?.[0]?.kilogramsFinal ?? 0} Kg
                      </td>
                      <td className="inputStockDetails">
                        $
                        {(
                          (lot?.lotsAll?.indLots?.[0]?.usdValueFinal ?? 0) -
                          (lot?.lotsAll?.indLots?.[0]?.usdSpentInd ?? 0)
                        ).toFixed(2)}
                      </td>
                      <td className="inputStockDetails">
                        {lot?.material === "Weee" && (
                          <button
                            className="viewButton"
                            onClick={() => handleViewLoad(lot._id)}
                          >
                            View
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>





          {exportsStock.map((exportItem) => {
            const lots = exportItem?.lotsAll?.indLots || [];

            // Compute total kilograms and total USD spent for the current export item
            const totalKilograms = lots.reduce(
              (sum, lot) => sum + (lot.kilogramsFinal || 0),
              0
            );
            const totalUsdSpent = lots.reduce(
              (sum, lot) => sum + (lot.usdSpentInd || 0),
              0
            );
            const totalUsdValue = lots.reduce(
              (sum, lot) => sum + (lot.usdValueInd || 0),
              0
            );

            return (
              <div key={exportItem._id} className="stock-table-containers">
                <div
                  className="h1HeadersStockDetails"
                  onClick={() => handleViewExport(exportItem._id)}
                  style={{ cursor: "pointer" }}
                >
                  {exportItem.material} {exportItem.load} Stock{" "}
                  {totalKilograms.toFixed(0)} Kg ${totalUsdSpent.toFixed(2)}
                </div>

                <table className="tableStock">
                  <thead className="tableStock">
                    <tr className="tableStock">
                      <th className="table50Goal">Load</th>
                      <th className="table50Goal">Spent</th>
                      <th className="table50Goal">Gross Profit</th>
                      <th className="table50Goal">Kg Gross</th>
                      <th className="table50Goal">Margin</th>
                      <th className="table50Goal">Kg Net</th>
                      <th className="table50Goal">Net Profit</th>
                      <th className="table50Goal">View</th>
                    </tr>
                  </thead>
                  <tbody className="tableBodyStock">
                    {lots.map((lot, index) => {
                      return (
                        <tr
                          className="inputStockDetails"
                          key={lot._id || index}
                        >
                          <td className="inputStockDetails">
                            {lot.assignedLoad?.load || "N/A"}
                          </td>
                          <td className="inputStockDetails">
                            ${lot.usdSpentInd.toFixed(0)}
                          </td>
                          <td className="inputStockDetails">
                            ${lot.usdValueInd.toFixed(0)}
                          </td>
                          <td className="inputStockDetails">
                            {lot.kilogramsInd.toFixed(2)} kg
                          </td>
                          <td className="inputStockDetails">
                            {lot.usdSpentInd > 0
                              ? (
                                  (lot.usdValueFinal / lot.usdSpentInd) *
                                  100
                                ).toFixed(0) + "%"
                              : "N/A"}
                          </td>
                          <td className="inputStockDetails">
                            {lot.kilogramsFinal ?? 0} Kg
                          </td>
                          <td className="inputStockDetails">
                            ${(lot.usdValueFinal - lot.usdSpentInd).toFixed(2)}
                          </td>
                          <td className="inputStockDetails">
                            <button
                              className="viewButton"
                              onClick={() =>
                                handleViewLoad(lot.assignedLoad?.id._id)
                              }
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default StockWeee;
