import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../userAuth";
import "./export.css";

const ViewWeeeLots = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  const { exportId } = useParams();
  const [lotsDataSum, setLotsDataSum] = useState({
    kilogramTotal: 0,
    usdValueTotal: 0,
    usdSpentTotal: 0,
  });
  const [lotsData, setLotsData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch full export document and all load details
        const response = await axios.get(
          `${baseUrl}/api/load/lots/weee/${exportId}`
        );
        const { exportDoc, loadDocuments } = response.data;

        loadDocuments.forEach((load, index) => {
          console.log(`Load ${index + 1}:`, load);
        });

        // Store the full export document
        setExportData(exportDoc);

        // Map load details by their ID for easy lookup
        const loadMap = {};
        loadDocuments.forEach((load) => {
          loadMap[load._id] = load;
        });


        // Enrich `indLots` with full load details
        const enrichedLots = exportDoc.lotsAll.indLots.map((lot, index) => {
          const enrichedLot = {
            ...lot,
            assignedLoadDetails: loadMap[lot.assignedLoad.id] || null,
          };
          console.log(`Enriched Lot ${index + 1}:`, enrichedLot);
          return enrichedLot;
        });

        // Update state with enriched lots
        setLotsData(enrichedLots);

        // Calculate total sums using `autoAddedGross`, `autoAddedSpent`, and `autoAddedWeight`
        const { kilogramTotal, usdValueTotal, usdSpentTotal } =
          enrichedLots.reduce(
            (acc, curr) => ({
              kilogramTotal:
                acc.kilogramTotal +
                (curr.assignedLoadDetails?.autoAddedWeight || 0),
              usdValueTotal:
                acc.usdValueTotal +
                (curr.assignedLoadDetails?.autoAddedGross || 0),
              usdSpentTotal:
                acc.usdSpentTotal +
                (curr.assignedLoadDetails?.autoAddedSpent || 0),
            }),
            { kilogramTotal: 0, usdValueTotal: 0, usdSpentTotal: 0 }
          );

        setLotsDataSum({ kilogramTotal, usdValueTotal, usdSpentTotal });

        setLotsDataSum({ kilogramTotal, usdValueTotal, usdSpentTotal });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [exportId]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0

    // Pad single digits with leading zero
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;

    return `${formattedDay}/${formattedMonth}`;
  }

  return (
    <div className="exports-second">
      {isLoggedIn && title === "Admin" && (
        <>
          <div className="exports-third-row-loadview">
            <h2 className="h1HeadersExportDetails">Added Lots</h2>
            <div className="export-unused-row">
              <div className="export-unused">
                {lotsDataSum.kilogramTotal.toFixed(2)} Kg
              </div>
              <div className="export-unused">
                Est Gross ${lotsDataSum.usdValueTotal}
              </div>
            </div>
            <div className="export-unused-row">
              <div className="export-unused">
                Spent ${lotsDataSum.usdSpentTotal}
              </div>
              <div className="export-unused">
                $
                {(
                  lotsDataSum.usdValueTotal / lotsDataSum.kilogramTotal
                ).toFixed(2)}{" "}
                / Kg
              </div>
              <div className="export-unused">
                {(
                  (lotsDataSum.usdValueTotal / lotsDataSum.usdSpentTotal) *
                  100
                ).toFixed(2)}
                %
              </div>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Load</th>
                <th>Spent</th>
                <th>Est Gross</th>
                <th>Kilograms</th>
                <th>Margin</th>
              </tr>
            </thead>
            <tbody>
              {lotsData.map((lot, index) => (
                <tr key={index}>
                  <td>{lot.assignedLoadDetails?.load || "N/A"}</td>
                  <td>${lot.assignedLoadDetails?.autoAddedSpent || 0}</td>
                  <td>${lot.assignedLoadDetails?.autoAddedGross || 0}</td>
                  <td>{lot.assignedLoadDetails?.autoAddedWeight || 0}</td>
                  <td>
                    {lot.assignedLoadDetails?.autoAddedSpent
                      ? (
                          lot.assignedLoadDetails.autoAddedGross /
                          lot.assignedLoadDetails.autoAddedSpent
                        )
                      : "N/A"}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ViewWeeeLots;
