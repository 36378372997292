import React, { useState, useEffect } from "react";
import axios from "axios";
import "./export.css";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faPlane } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../userAuth";

const WEEE_GRADES = [
  "P3",
  "P4",
  "CDROM Green",
  "ECU",
  "Receiver W/ Chip",
  "WiFi",
  "HardDrive Boards",
  "RAMS gold",
  "Phone Mix",
  "Android",
  "Nokia",
  "Chinese",
  "P4 No Chips",
  "BrownBoards 2",
  "P4 +",
  "P4 2 IC",
  "P4 Mix",
  "P4 -",
  "P3 Mix",
];

const ExportDetailsWeee = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const { id } = useParams();
  const [isShipped, setIsShipped] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [weeGrades, setWeeGrades] = useState([]);
  const [weeExportGrades, setWeeExportGrades] = useState([]);
  const [lotsData, setLotsData] = useState([]);
  const [netCrg, setNetCrg] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  const [editedData, setEditedData] = useState(null);
  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  // Define fetch functions outside useEffect so they can be reused
  const fetchExportById = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/exportsingleload/${id}`);
      setExportData(response.data);
      setEditedData(response.data);
      setIsShipped(response.data.shipped);

      const existingWeeExportGrades = response.data?.weeGradesExported || [];

      // Fetch stock data first
      const stockResponse = await axios.get(`${baseUrl}/api/weeestock/main`);
      const stockData = stockResponse.data[0];
      const existingWeeGrades = stockData?.lotsAll?.indLots[0]?.weeGrades || [];

      const initializedExportGrades = WEEE_GRADES.map((gradeExport) => {
        const foundExport = existingWeeExportGrades.find(
          (item) => item.grade === gradeExport
        );

        const foundStock = existingWeeGrades.find(
          (item) => item.grade === gradeExport
        );

        return {
          gradeExport,
          paidPerKgFinal: foundStock?.paidPerKgFinal
            ? parseFloat(foundStock.paidPerKgFinal.toFixed(2))
            : foundExport?.paidPerKgFinal.toFixed(2) || 0, // Use stock value if available
          sellPrice: foundExport?.sellPrice.toFixed(2) || 0,
          totalKgExport: foundExport?.totalKgExport.toFixed(2) || 0,
          totalPaidExport: foundExport?.totalPaidExport.toFixed(2) || 0,
          totalValueExport: foundExport?.totalValueExport.toFixed(2) || 0,
          totalGrossExport: foundExport?.totalGrossExport.toFixed(2) || 0,
        };
      });

      setWeeExportGrades(initializedExportGrades);
    } catch (error) {
      console.error("Error fetching export document:", error);
    }
  };

  const fetchStockData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/weeestock/main`);
      const stockData = response.data[0];

      const existingWeeGrades = stockData?.lotsAll?.indLots[0]?.weeGrades || [];

      const initializedGrades = WEEE_GRADES.map((grade) => {
        const found = existingWeeGrades.find((item) => item.grade === grade);
        return {
          grade,
          sellPrice: found?.sellPrice
            ? parseFloat(found.sellPrice.toFixed(2))
            : 0,
          paidPerKg: found?.paidPerKg
            ? parseFloat(found.paidPerKg.toFixed(2))
            : 0,
          gradeKg: found?.gradeKg ? parseFloat(found.gradeKg.toFixed(2)) : 0, // Ensure number
          gradeKgEst: found?.gradeKgEst
            ? parseFloat(found.gradeKgEst.toFixed(2))
            : 0,
          totalPaid: found?.totalPaid
            ? parseFloat(found.totalPaid.toFixed(2))
            : 0,
          sellValue: found?.sellValue
            ? parseFloat(found.sellValue.toFixed(2))
            : 0,
        };
      });

      setWeeGrades(initializedGrades);
    } catch (error) {
      console.error("Error fetching WEEE stock:", error);
    }
  };

  // useEffect to fetch data on component mount and when id changes
  useEffect(() => {
    if (!id) return;
    fetchExportById();
    fetchStockData();
  }, [id, baseUrl]); // Dependencies

  const handleChange = (e, key, nestedKey) => {
    const { value } = e.target;
    setEditedData((prevState) => ({
      ...prevState,
      [key]: nestedKey
        ? { ...(prevState[key] || {}), [nestedKey]: value }
        : value,
    }));
  };

  const handleChangeStock = (index, field, value) => {
    const updatedGrades = [...weeGrades];
    updatedGrades[index][field] = value;

    setWeeGrades(updatedGrades);
  };

  const handleChangeExportGrades = (index, field, value) => {
    setWeeExportGrades((prevGrades) =>
      prevGrades.map((grade, i) => {
        if (i === index) {
          // Find the corresponding paidPerKgFinal from weeGrades
          const matchingStockGrade = weeGrades.find(
            (g) => g.grade === grade.gradeExport
          );

          return {
            ...grade,
            [field]: value,
            paidPerKgFinal:
              matchingStockGrade?.paidPerKgFinal || grade.paidPerKgFinal,
          };
        }
        return grade;
      })
    );
  };

  const handleSave = async () => {
    try {
      const updatedData = {
        ...editedData,
        weeGradesExported: weeExportGrades.map((grade) => ({
          ...grade,
          paidPerKgFinal: parseFloat(grade.paidPerKgFinal || 0),
        })),
      };

      // ✅ Update Export Document
      await axios.put(
        `${baseUrl}/api/exports-edit-load-weee/${id}`,
        updatedData
      );
      setExportData(updatedData);

      alert("Saved successfully!");
    } catch (error) {
      console.error("Error updating export document:", error);
    }
  };

  const handleSendExport = async () => {
    try {
      // ✅ Update `weeGrades` with `remainingKg` before sending export
      const updatedWeeGrades = weeGrades.map((grade) => {
        const exportGrade =
          weeExportGrades.find((exp) => exp.gradeExport === grade.grade) || {};

        return {
          ...grade,
          gradeKg: Math.max(
            grade.gradeKg - (exportGrade.totalKgExport || 0),
            0
          ), // Auto-calculate remainingKg
        };
      });

      // ✅ Save updated state before making requests
      setWeeGrades(updatedWeeGrades);

      // ✅ Log and update gradeKg in Weeestock using updated `weeGrades`
      const stockUpdatePromises = updatedWeeGrades.map((grade) => {
        const stockUpdatePayload = {
          grade: grade.grade,
          gradeKg: grade.gradeKg, // Pass the automatically updated `remainingKg`
        };

        return axios.put(
          `${baseUrl}/api/update-weeestock-gradeKg-from-export`,
          stockUpdatePayload
        );
      });

      await Promise.all(stockUpdatePromises);

      // ✅ Send Export
      await axios.put(`${baseUrl}/api/shipping/exports/${id}`);
      setIsShipped(true);

      // ✅ Fetch the latest data after export
      await fetchExportById();
      await fetchStockData();

      alert("Export successful!");
    } catch (error) {
      console.error("Error exporting:", error);
      alert("Error exporting. Please try again later.");
    }
  };

  if (!exportData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="stock-second">
      {isLoggedIn && title === "Admin" && (
        <>
          <div className="exports-third-row">
            <div className="h1HeadersStockDetailsBig">
              Export Load {exportData.load} Details
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">
                CRG EST $
                {(
                  exportData.autoAddedValueWeee -
                  exportData.autoAddedSpentWeee -
                  exportData.exportExpensesWeee -
                  exportData.agentExpensesWeee -
                  exportData.refineryExpensesWeee
                ).toFixed(0)}{" "}
                Net
              </div>
              <table className="xrfTables">
                <tbody className="xrfTables">
                  <tr className="xrfTables">
                    <th className="xrfTablesHeaders">Stock Value</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={Number(
                          editedData?.autoAddedSpentWeee || 0
                        ).toFixed(0)}
                        onChange={(e) => handleChange(e, "autoAddedSpentWeee")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">Weight</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={Number(
                          editedData?.autoAddedWeightWeee || 0
                        ).toFixed(0)}
                        onChange={(e) => handleChange(e, "autoAddedWeightWeee")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">Value</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={Number(
                          editedData?.autoAddedGrossWeee || 0
                        ).toFixed(0)}
                        onChange={(e) => handleChange(e, "autoAddedGrossWeee")}
                      />
                    </td>
                    <th className="xrfTablesHeaders">CRG Expenses</th>
                    <td className="xrfExportResultsTableBody">
                      <input
                        className="inputExportDetailsResults"
                        type="number"
                        value={editedData.exportExpensesWeee}
                        onChange={(e) => handleChange(e, "exportExpensesWeee")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="exportExpensesContainer">
              <div className="stock-table-containers">
                <div className="h1HeadersStockDetails">
                  Agent Est $
                  {exportData.agentValueWeee -
                    exportData.autoAddedSpentWeee -
                    exportData.exportExpensesWeee -
                    exportData.agentExpensesWeee -
                    exportData.refineryExpensesWeee}{" "}
                  Net
                </div>
                <table className="xrfTables">
                  <tbody className="xrfTables">
                    <tr className="xrfTables">
                      <th className="xrfTablesHeaders">Stock Value</th>
                      <td className="xrfExportResultsTableBody">
                        <input
                          className="inputExportDetailsResults"
                          type="number"
                          value={Number(
                            editedData?.autoAddedSpentWeee || 0
                          ).toFixed(0)}
                          onChange={(e) =>
                            handleChange(e, "autoAddedSpentWeee")
                          }
                        />
                      </td>
                      <th className="xrfTablesHeaders">Weight</th>
                      <td className="xrfExportResultsTableBody">
                        <input
                          className="inputExportDetailsResults"
                          type="number"
                          value={Number(
                            editedData?.agentWeightWeee || 0
                          ).toFixed(0)}
                          onChange={(e) => handleChange(e, "agentWeightWeee")}
                        />
                      </td>
                      <th className="xrfTablesHeaders">Value</th>
                        <td className="xrfExportResultsTableBody">
                          <input
                            className="inputExportDetailsResults"
                            type="number"
                            value={Number(
                              editedData?.agentValueWeee || 0
                            ).toFixed(0)}
                            onChange={(e) => handleChange(e, "agentValueWeee")}
                          />
                        </td>
                      <th className="xrfTablesHeaders">Agent Expenses</th>
                      <td className="xrfExportResultsTableBody">
                        <input
                          className="inputExportDetailsResults"
                          type="number"
                          value={editedData.agentExpensesWeee}
                          onChange={(e) => handleChange(e, "agentExpensesWeee")}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="exportExpensesContainer">
              <div className="stock-table-containers">
                <div className="h1HeadersStockDetails">
                  Final $
                  {exportData.refineryValueWeee -
                    exportData.autoAddedSpentWeee -
                    exportData.exportExpensesWeee -
                    exportData.agentExpensesWeee -
                    exportData.refineryExpensesWeee}{" "}
                  Net
                </div>
                <table className="xrfTables">
                  <tbody className="xrfTables">
                    <tr className="xrfTables">
                      <th className="xrfTablesHeaders">Stock Value</th>
                      <td className="xrfExportResultsTableBody">
                        <input
                          className="inputExportDetailsResults"
                          type="number"
                          value={Number(
                            editedData?.autoAddedSpentWeee || 0
                          ).toFixed(0)}
                          onChange={(e) =>
                            handleChange(e, "autoAddedSpentWeee")
                          }
                        />
                      </td>
                      <th className="xrfTablesHeaders">Weight</th>
                      <td className="xrfExportResultsTableBody">
                        <input
                          className="inputExportDetailsResults"
                          type="number"
                          value={Number(
                            editedData?.refineryWeightWeee || 0
                          ).toFixed(0)}
                          onChange={(e) =>
                            handleChange(e, "refineryWeightWeee")
                          }
                        />
                      </td>
                      <th className="xrfTablesHeaders">Value</th>
                      <td className="xrfExportResultsTableBody">
                        <input
                          className="inputExportDetailsResults"
                          type="number"
                          value={Number(
                            editedData?.refineryValueWeee || 0
                          ).toFixed(0)}
                          onChange={(e) => handleChange(e, "refineryValueWeee")}
                        />
                      </td>
                      <th className="xrfTablesHeaders">Refinery Expenses</th>
                      <td className="xrfExportResultsTableBody">
                        <input
                          className="inputExportDetailsResults"
                          type="number"
                          value={editedData.refineryExpensesWeee}
                          onChange={(e) =>
                            handleChange(e, "refineryExpensesWeee")
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="h1HeadersStockDetailsBig">Manage WEEE Stock</div>
          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <table className="table50">
                <thead>
                  <tr>
                    <th>Grade</th>
                    <th>Grade Kg</th>
                    <th>Remaining Kg</th> {/* New column for remaining stock */}
                    <th>Paid Per Kg</th>
                    <th>Total Paid</th>
                    <th>Sell Price Stock</th>
                    <th>Sell Value</th>
                    <th>Export Kg</th>
                    <th>Stock Value</th>
                    <th>Sell Price Export</th>
                    <th>Value Export</th>
                    <th>Total Gross Export</th>
                  </tr>
                </thead>
                <tbody>
                  {weeGrades.map((grade, index) => {
                    const exportGrade =
                      weeExportGrades.find(
                        (exp) => exp.gradeExport === grade.grade
                      ) || {};

                    // Correct remaining stock calculation
                    const remainingKg = Math.max(
                      grade.gradeKg - (exportGrade.totalKgExport || 0),
                      0
                    );

                    return (
                      <tr key={grade.grade}>
                        <td>{grade.grade}</td>
                        <td>
                          <input
                            className="inputExportDetailsFixations"
                            type="number"
                            value={grade?.gradeKg.toFixed(0) || 0}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixations"
                            type="number"
                            value={remainingKg}
                            onChange={(e) =>
                              handleChangeStock(
                                index,
                                "gradeKg",
                                parseFloat(e.target.value) || 0
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixations"
                            type="number"
                            value={exportGrade.paidPerKgFinal}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixations"
                            type="number"
                            value={grade?.totalPaid.toFixed(0) || 0}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixations"
                            type="number"
                            value={grade.sellPrice}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixations"
                            type="number"
                            value={grade?.sellValue.toFixed(0) || 0}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixationsEdit"
                            type="number"
                            value={exportGrade.totalKgExport || 0}
                            onChange={(e) =>
                              handleChangeExportGrades(
                                index,
                                "totalKgExport",
                                parseFloat(e.target.value) || 0
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixations"
                            type="number"
                            value={(
                              (exportGrade.totalKgExport || 0) *
                              (exportGrade.paidPerKgFinal || 0)
                            ).toFixed(0)}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixationsEdit"
                            type="number"
                            value={exportGrade.sellPrice || 0}
                            onChange={(e) =>
                              handleChangeExportGrades(
                                index,
                                "sellPrice",
                                parseFloat(e.target.value) || 0
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixations"
                            type="number"
                            value={(
                              (exportGrade.totalKgExport || 0) *
                              (exportGrade.sellPrice || 0)
                            ).toFixed(0)}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            className="inputExportDetailsFixations"
                            type="number"
                            value={(
                              (exportGrade.totalKgExport || 0) *
                                (exportGrade.sellPrice || 0) -
                              (exportGrade.totalKgExport || 0) *
                                (grade.paidPerKgFinal || 0)
                            ).toFixed(0)}
                            readOnly
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="bottomButtonRow">
            <div className="exportCreateLoadButton" onClick={handleSave}>
              <FontAwesomeIcon className="dashboardIcons" icon={faSave} />
              <span>Save</span>
            </div>
            {!isShipped && (
              <div
                className="exportCreateLoadButton"
                onClick={handleSendExport}
              >
                <FontAwesomeIcon className="dashboardIcons" icon={faPlane} />
                <span>Export</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ExportDetailsWeee;
