import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faCog,
  faHome,
  faSignIn,
  faSignOut,
  faDatabase,
  faUser,
  faQuestion,
  faPeopleGroup,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";
import logo from "./logo.jpg";
import "./header.css";

function Header() {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [title, setTitle] = useState(null);

  const handleCartClick = () => {
    navigate("/cartaccess");
  };

  const handleSigninClick = () => {
    navigate("/login"); // Replace '/login' with the desired URL path for the login page
  };

  const handleSignoutClick = () => {
    // Perform logout actions if needed
    navigate("/login");
    setTitle(null); // Clear the title value upon sign-out
    localStorage.removeItem("title"); // Remove the title value from localStorage
  };

  const handleStockClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate(
      "/crg-stock-main/aksdl;fh4piou34-t90th=usdhij[fioa[ioAWEJ;KJ;65GF3HGLBNKHJGFAkkl.,jf321"
    );
  };

  const handleCustomersClick = () => {
    // Navigate to "/crg-tasks" when the icon is clicked
    navigate(
      "/contact-list"
    );
  };

  const renderAuthIcon = () => {
    if (localStorage.getItem("title")) {
      return (
        <div className="Settings-link">
          <FontAwesomeIcon
            onClick={handleSignoutClick}
            icon={faSignOut}
            className="Shopping-icon"
          />
        </div>
      );
    } else {
      // User is not logged in, show sign-in icon
      return (
        <div onClick={handleSigninClick} className="Settings-link">
          <FontAwesomeIcon icon={faSignIn} className="Shopping-icon" />
        </div>
      );
    }
  };

  useEffect(() => {
    const storedTitle = localStorage.getItem("title");
    if (storedTitle) {
      setTitle(storedTitle);
    }
  }, []);

  return (
    <header className="App-header">
      <div className="App-header-center">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
            <div className="App-header-right">
      {/*     {localStorage.getItem("title") === "Admin" && (
          <Link to="/admin" className="Settings-link">
            <FontAwesomeIcon icon={faCog} className="Settings-icon" />
          </Link>
        )} */}

        {localStorage.getItem("title") === "Admin" && (
          <Link to="/customers" className="Settings-link">
            <FontAwesomeIcon icon={faDatabase} className="Shopping-icon" />
          </Link>
        )}

{localStorage.getItem("title") === "Admin" && (
          <Link to="/crg-final-expenses-by-month/salje3k54j6pe6oytirus-fga9fuwea[irbipsen[aiksjd" className="Settings-link">
            <FontAwesomeIcon icon={faDollar} className="Shopping-icon" />
          </Link>
        )}


        {(localStorage.getItem("title") === "Sales" ||
          localStorage.getItem("title") === "Center") && (
          <Link to="/sales-login" className="Settings-link">
            <FontAwesomeIcon icon={faUser} className="Shopping-icon" />
          </Link>
        )}

        {/*       {localStorage.getItem('title') === 'Admin' ||
          localStorage.getItem('title') === 'Visitor' ? (
          <div onClick={handleCartClick} className="Settings-link">
            <FontAwesomeIcon
              icon={faShoppingCart}
              className="Settings-icon"
            />
          </div>
        ) : (
          <div className="Settings-link">
          </div>
        )}
 hide calculator
        <Link to="/price" className="Settings-link">
          <FontAwesomeIcon icon={faCalculator} className="Shopping-icon" />
        </Link>*/}

        {/*     <Link to="/weee" className="Settings-link">
          <FontAwesomeIcon icon={faComputer} className="Shopping-icon" />
        </Link> */}

        {/*       <Link to="/" className="Settings-link">
          <FontAwesomeIcon icon={faHome} className="Shopping-icon" />
        </Link>*/}

        {localStorage.getItem("title") === "Admin" ||
        localStorage.getItem("title") === "Visitor" ? (
          <div onClick={handleStockClick} className="Settings-link">
            <FontAwesomeIcon icon={faQuestion} className="Shopping-icon" />
          </div>
        ) : (
          <div className="Settings-link"></div>
        )}

        {localStorage.getItem("title") === "Admin" ||
        localStorage.getItem("title") === "Visitor" ? (
          <div onClick={handleCustomersClick} className="Settings-link">
            <FontAwesomeIcon icon={faPeopleGroup} className="Settings-icon" />
          </div>
        ) : (
          <div className="Settings-link"></div>
        )}

        {renderAuthIcon()}
      </div>
    </header>
  );
}

export default Header;
