import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../userAuth';
import axios from 'axios';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const AssignLoadWeee = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  const { id } = useParams();

  const [ceramicsData, setCeramicsData] = useState([]);
  const [exportLoad, setExportLoad] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    fetchCeramicsData();
    fetchExportById();
  }, []);

  const fetchCeramicsData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/loads/weeeall`);
      setCeramicsData(response.data);
    } catch (error) {
      console.error('Error fetching ceramics data:', error);
    }
  };

  const fetchExportById = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/exportsingleload/${id}`);
      setExportLoad(response.data);
    } catch (error) {
      console.error('Error fetching export document:', error);
    }
  };

  const handleExpandRow = (loadId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [loadId]: !prev[loadId],
    }));
  };

  const handleCheckboxChange = async (loadId, isChecked, loadValue) => {
    try {
      if (isChecked) {
        // Assign load to export document
        await axios.put(`${baseUrl}/api/updateLoadExport/${loadId}`, {
          exportId: id,
          exportLoadName: exportLoad.load,
        });
  
        await axios.put(`${baseUrl}/api/assignLoadToExport/${id}`, {
          loadId,
          loadValue, // Pass the correct load value
        });
  
        alert('Load successfully added to export.');
      } else {
        // Remove load from export document
        await axios.put(`${baseUrl}/api/removeLoadFromExport/${id}`, {
          loadId,
        });
  
        alert('Load successfully removed from export.');
      }
  
      // Refresh data so the load disappears after being added
      fetchCeramicsData();
    } catch (error) {
      console.error('Error updating export data:', error);
      alert('Failed to update export data. Please try again.');
    }
  };
  

  const formatDate = (dateString) => {
    const options = { month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className="exports-second">
      {isLoggedIn && title === 'Admin' && (
        <>
          <div className="exports-third-row-loadview">
            {exportLoad && <h2 className="h1HeadersExportDetails">Assign to Lot {exportLoad.load}</h2>}
          </div>

          <table>
            <thead>
              <tr>
                <th>Assign</th>
                <th>Load</th>
                <th>USD Spent ($)</th>
                <th>USD Value ($)</th>
                <th>Kilograms</th>
                <th>Expand</th>
              </tr>
            </thead>
            <tbody>
              {ceramicsData.map((item) => (
                <React.Fragment key={item._id}>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        checked={!!item.assigned}
                        onChange={(e) => handleCheckboxChange(item._id, e.target.checked)}
                      />
                    </td>
                    <td>{item.load}</td>
                    <td>${item.sumUsdSpent.toFixed(2)}</td>
                    <td>${item.sumUsdValue.toFixed(2)}</td>
                    <td>{item.sumKilograms.toFixed(2)} Kg</td>
                    <td>
                      <button onClick={() => handleExpandRow(item._id)} className="expand-btn">
                        {expandedRows[item._id] ? <FaChevronUp /> : <FaChevronDown />}
                      </button>
                    </td>
                  </tr>

                  {expandedRows[item._id] && (
                    <>
                      {/* Expanded Table Header */}
                      <tr className="expanded-header">
                        <th>Date</th>
                        <th>Contact</th>
                        <th>USD Spent</th>
                        <th>USD Value</th>
                        <th>Kilograms</th>
                        <th>Margin</th>
                      </tr>

                      {/* Expanded Lot Rows */}
                      {item.lotsAll.indLots.map((subitem) => (
                        <tr key={subitem._id} className="expanded-row">
                          <td>{subitem.dateInd ? formatDate(subitem.dateInd) : 'N/A'}</td>
                          <td>{subitem.contactCrg?.name || 'N/A'}</td>
                          <td>${subitem.usdSpentInd.toFixed(2)}</td>
                          <td>${subitem.usdValueInd.toFixed(2)}</td>
                          <td>{subitem.kilogramsInd.toFixed(2)} Kg</td>
                          <td>{((subitem.usdValueInd / subitem.usdSpentInd) * 100).toFixed(2)}%</td>
                        </tr>
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default AssignLoadWeee;
