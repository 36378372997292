import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './saleslogin.css';

const UserLoginForm = () => {
  const [userId, setUserId] = useState('');
  const storedTitle = localStorage.getItem('title');
  const [storedId, setStoredId] = useState(localStorage.getItem('id') || ''); // Initialize with stored value
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    const userIdFromStorage = localStorage.getItem('id');
    if (userIdFromStorage) {
      setStoredId(userIdFromStorage);
      setUserId(userIdFromStorage); // Set initial input value
    }
  }, []);

  const handleInputChange = (e) => {
    setUserId(e.target.value);
    setErrorMessage(''); // Clear the error message when typing
  };

  const handleEnterPress = async () => {
    if (!userId.trim()) {
      setErrorMessage('ID field cannot be empty');
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/api/users/${userId}`);
      const userData = await response.json();

      if ((storedTitle === 'Sales' && userData.position !== 'Sales') || (storedTitle === 'Center' && userData.position !== 'Center')) {
        setErrorMessage('Please Use Your ID');
        return;
      }

      if (response.ok) {
        const userInfo = {
          id: userData._id,
          name: userData.name,
          position: userData.position,
        };

        navigate('/contact-list', { state: { userInfo } });
      } else {
        setErrorMessage('ID not found in the database');
      }
    } catch (error) {
      console.error('Error checking user:', error);
      setErrorMessage('Error checking user');
    }
  };

  return (
    <div className='salesLoginMain'>
      <div className='salesLoginSecond'>
        <label>
          Verify Your ID:
          <input type="text" value={storedId} onChange={handleInputChange} />
        </label>
        <button onClick={handleEnterPress}>Enter</button>
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default UserLoginForm;
