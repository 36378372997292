import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../userAuth";
import "./export.css";

const ExportMain = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const [exports, setExports] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

    useEffect(() => {
      const fetchExports = async () => {
        try {
          const response = await axios.get(`${baseUrl}/api/exports/shipped`);
          const updatedExports = response.data.map((exp) => {
            const totalSpent = exp.exportData.lotsAll?.indLots?.reduce(
              (sum, lot) => sum + (lot.usdSpentInd || 0),
              0
            );
  
            if (exp.material === "Weee") {
              return {
                ...exp,
                exportSpentTotal: totalSpent,
                crgGross:
                  exp.autoAddedValueWeee -
                  exp.autoAddedSpentWeee -
                  exp.exportExpensesWeee -
                  exp.agentExpensesWeee -
                  exp.refineryExpensesWeee,
                xrfAgentGross:
                  exp.agentValueWeee -
                  exp.autoAddedSpentWeee -
                  exp.exportExpensesWeee -
                  exp.agentExpensesWeee -
                  exp.refineryExpensesWeee,
                icpRefineryGross:
                  exp.refineryValueWeee -
                  exp.autoAddedSpentWeee -
                  exp.exportExpensesWeee -
                  exp.agentExpensesWeee -
                  exp.refineryExpensesWeee,
              };
            }
          
            // For Ceramics, Steel, and DPF:
            if (["Ceramics", "Steel", "DPF", "Lambda"].includes(exp.material)) {
              return {
                ...exp,
                exportSpentTotal: totalSpent,
                crgGross: exp.exportData.estGrossCrg - totalSpent - exp.exportData.exportExpenses,
                xrfAgentGross: exp.exportData.estGrossTechXrf - totalSpent - exp.exportData.exportExpenses,
                icpRefineryGross: exp.exportData.estGrossTechIcp - totalSpent - exp.exportData.exportExpenses,
              };
            }
          
            // Default case for other materials
            return {
              ...exp,
              exportSpentTotal: totalSpent,
              crgGross: exp.exportData.estGrossCrg - totalSpent - exp.exportData.exportExpenses,
              xrfAgentGross: exp.exportData.estGrossTechXrf - totalSpent - exp.exportData.exportExpenses,
              icpRefineryGross: exp.exportData.estGrossTechIcp - totalSpent - exp.exportData.exportExpenses,
            };
          });
          setExports(updatedExports);
        } catch (error) {
          console.error("Error fetching export documents:", error);
        }
      };
  
      fetchExports();
    }, []);
  
    // Function to format date to month and year
    const formatMonthYear = (dateString) => {
      const date = new Date(dateString);
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();
      return `${month} ${year}`;
    };
  
    const formatFullDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0"); // Ensure two digits
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
  
    const handleExportViewLoadClick = (id, material) => {
      if (material === "Lambda") {
        navigate(
          `/crg-export-load-details/asd6ar5e459843y4tr65jds4tTh44jgfhdshds4f+af/${id}`
        );
      } else if (material === "Ceramics") {
        navigate(
          `/crg-export-load-details/asd6ar5e459843y4tr65j4hk6yu5y4usfd5g1a+f56ds4f+af/${id}`
        );
      } else if (material === "Weee") {
        navigate(
          `/crg-export-weee-details/asd6ar5e459843y4tr65j4hk6yu5y4usfd5g1a+f56ds4f+af/${id}`
        );
      }
    };
  
    return (
      <div className="stock-second">
        {isLoggedIn && title === "Admin" && (
          <>
            <div className="h1HeadersStockDetailsBig">Exports Sent</div>
            <div className="exportExpensesContainer">
              <div className="stock-table-containers">
                <table className="table50">
                  <thead className="table50">
                    <tr className="table50">
                      <th>Month</th>
                      <th>Shipped Date</th>
                      <th>Type</th>
                      <th>Load</th>
                      <th>Spent</th>
                      <th>CRG Net</th>
                      <th>XRF/Agent Net</th>
                      <th>ICP/Ref. Net</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exports.map((exp, index) => (
                      <tr
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleExportViewLoadClick(exp._id, exp.exportData.material)
                        }
                      >
                        <td className="table50td">
                          {formatMonthYear(exp.exportData.month)}
                        </td>
                        <td className="table50td">
                          {formatFullDate(exp.exportData.shippingDate)}
                        </td>
                        <td className="table50td">{exp.exportData.material}</td>
                        <td className="table50td">{exp.exportData.load}</td>
                        <td className="table50td">
                          ${exp.exportSpentTotal?.toFixed(0) || 0}
                        </td>
                        <td className="table50td">
                          ${exp.crgGross?.toFixed(0) || 0}
                        </td>
                        <td className="table50td">
                          ${exp.xrfAgentGross?.toFixed(0)|| 0}
                        </td>
                        <td className="table50td">
                          ${exp.icpRefineryGross?.toFixed(0) || 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };
  
  export default ExportMain;