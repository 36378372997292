import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WeeMainComponent from "./weemain/weemain";
import HomeMain from "./home/homemain";
import AdminSingle from "./admin/adminsingle";
import ImageUploader from "./admin/images";
import AdminMain from "./admin/adminmain";
import "./App.css";
import Header from "./header";
import axios from "axios";
import UserSignupLogin from "./user/userlogin";
import ContactsMain from "./database/contactsMain";
import ContactList from "./database/contactList";
import ContactFormWrapper from "./database/ContactFormWrapper";
import ContactDetails from "./database/contactDetails";
import AddUserWrapper from "./database/AddUserWrapper";
import PriceCalculator from "./ppmcalc.js/ppmcalc";
import CalendarView from "./database/calendarCustomer";
import CustomerMaterialLineChart from "./charts/CustomerMaterialLineChart";
import UserLoginForm from "./database/saleslogin";
import UserStatistics from "./database/UserStats";
import ContactStats from "./database/contactstats";
import ContactEditAdmin from "./database/contacteditadmin";
import CrgEgyptMain from "./crgegypt/crgegyptmain";
import EditFixedExpenses from "./crgegypt/editfixedexpenses";
import EditVariableExpenses from "./crgegypt/editvariableexpenses";
import DebtBreakdown from "./crgegypt/debtbreakdown";
import UserDailyMaterialChart from "./charts/userStatsChart";
import CalendarWarehouse from "./database/calendarWarehouse";
import { AuthProvider } from "./userAuth";
import UserAdminDailyMaterialChart from "./charts/userStatsChartAdmin";
import CalendarPredictView from "./database/calendarPredict";
import Tasks from "./crgegypt/dashboard/tasks";
import Dashboard from "./crgegypt/dashboard/dashmain";
import Goal from "./crgegypt/dashboard/goal";
import WeeklyContacts from "./crgegypt/dashboard/weeklyContacts";
import DailyContacts from "./crgegypt/dashboard/dailyContacts";
import GoalAllContacts from "./crgegypt/dashboard/goalAllContacts";
import ExportMain from "./crgegypt/dashboard/export/exportMain";
import ExportForm from "./crgegypt/dashboard/export/exportForm";
import ExportDetails from "./crgegypt/dashboard/export/exportDetails";
import AssignLoad from "./crgegypt/dashboard/export/assignLoad";
import ViewLoadLots from "./crgegypt/dashboard/export/viewLoadLots";
import FinalExpenses from "./crgegypt/dashboard/export/monthlyExpense";
import AllFinalExpenses from "./crgegypt/dashboard/export/finalExpensesAll";
import MonthlyExpense from "./crgegypt/dashboard/export/monthlyExpense";
import CrgReports from "./crgegypt/dashboard/reports/crgReports";
import CrgReportView from "./crgegypt/dashboard/reports/crgReportView";
import CrgStockMain from "./crgegypt/dashboard/stock/CrgStockMain";
import NotesMain from "./database/notes/notesMain";
import CrgReportViewOthers from "./crgegypt/dashboard/reports/crgReportViewOthers";
import CrgReportNewOthers from "./crgegypt/dashboard/reports/crgReportNewOthers";
import TeamMemberMaterialChart from "./charts/teamMemberStats";
import ScheduleAdminView from "./database/schedule";
import ScheduleSalesView from "./database/schedulesales";
import ExportDetailsLambda from "./crgegypt/dashboard/export/exportLambdaDetails";
import AssignLoadLambda from "./crgegypt/dashboard/export/assignLambdaLoad";
import ViewLambdaLots from "./crgegypt/dashboard/export/viewLambdaLoads";
import ExportDetailsWeee from "./crgegypt/dashboard/export/exportWeeeDetails";
import AssignLoadWeee from "./crgegypt/dashboard/export/assignWeeeLoad";
import ViewWeeeLots from "./crgegypt/dashboard/export/viewWeeeLoads";
import LoadsMain from "./crgegypt/dashboard/loads/CreateLoad";
import StockCats from "./crgegypt/dashboard/stock/StockCats";
import ViewLoad from "./crgegypt/dashboard/loads/ViewLoad";
import StockLambda from "./crgegypt/dashboard/stock/stockLambda";
import StockWeee from "./crgegypt/dashboard/stock/StockWeee";
import ViewWeeeLoad from "./crgegypt/dashboard/loads/ViewWeeeLoad";
import ViewWeeeStock from "./crgegypt/dashboard/loads/ViewWeeeStock";
import ContactEditAdminInd from "./database/contacteditind";

const isLocal = window.location.hostname === "localhost";
const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

axios.defaults.baseURL = baseUrl;

function App() {
  const [title, setTitle] = useState("");

  const handleContactAdded = (newContact) => {
    console.log("New contact added:", newContact);
  };

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header title={title} setTitle={setTitle} />
          <Routes>
            <Route path="/" element={<UserSignupLogin title={title} />} />
            <Route path="/weee" element={<WeeMainComponent title={title} />} />
            <Route
              path="/admin/:documentId/*"
              element={<AdminSingle title={title} />}
            />
            <Route
              path="/admin/:documentId/upload"
              element={<ImageUploader title={title} />}
            />
            <Route path="/admin" element={<AdminMain title={title} />} />
            <Route
              path="/login"
              element={<UserSignupLogin setTitle={setTitle} />}
            />
            <Route path="/customers" element={<ContactsMain title={title} />} />
            <Route
              path="/notes-calendar-asdtr+h4gs65yfwqsdsgfgfdhfdl9h5gh1g6+4sgfd5sadrlewjrikjg;lfdkgn"
              element={<NotesMain title={title} />}
            />
            <Route
              path="/my-notes-asdtr+h4gs65yfd9h5gh1g6+4sgfd5sadrlewjrikjg;lfdkgn"
              element={<NotesMain title={title} />}
            />
            <Route
              path="/crg-dashboard-asdtr+h4gs65yfd9h5gh1g6+4sgfd5"
              element={<Dashboard title={title} />}
            />
            <Route
              path="/crg-all-loads/lasedsoi659eyrgpoiut4q3w908ufa0eihrtaaisodwweiorouepgof;"
              element={<LoadsMain title={title} />}
            />
            {/*  <Route
              path="/crg-tasks/laser[ioepwag[iproweut4q3w908ufa0eihrtaaisodwweiorouepgof;"
              element={<Tasks title={title} />}
            /> */}
            <Route
              path="/profit-contacts-all"
              element={<GoalAllContacts title={title} />}
            />
            <Route
              path="/crg-goals/fads6t5dsa6t5t657654i6tuyk1j6s5g4dfgs+gdf"
              element={<Goal title={title} />}
            />
            m
            <Route
              path="/crg-export-form/laskdjfewaioptjdfkl;gjfsg;jdsfksgsl'dajfkl,mds;m;"
              element={<ExportForm title={title} />}
            />
            <Route
              path="/crg-monthly-reports/fjwaeoip5u4petiogufjhteyiojraeskvj[sdasf;lkj"
              element={<CrgReports title={title} />}
            />
            <Route
              path="/crg-monthly-reports/fjwaeoip5u4petiogufjhteyiojraeskvjds5re5f5gfgr5r5g221"
              element={<CrgReportNewOthers title={title} />}
            />
            <Route
              path="/crg-monthly-report-view/:reportId/ajsdkl;gjreaigjf;shkjfa'dfdskjfas'fjdkd;klj"
              element={<CrgReportView title={title} />}
            />
            <Route
              path="/crg-monthly-report-view/:reportId/ajsdkl;gjreaigjf;shkjfa'dfdskjfas'fjdkwrgfh5fhfdg523fg2j"
              element={<CrgReportViewOthers title={title} />}
            />
            <Route
              path="/crg-assign-load/lsadjf[ioewrtupeyiosjth[tyyot[pesrojfszklnflk;agwheiorphfdlk;/:id"
              element={<AssignLoad title={title} />}
            />
            <Route
              path="/crg-assign-load/lsadjf[ioewrtupeyiosjth[tyyot[pessadf54tryhf5wheiorphfdlk;/:id"
              element={<AssignLoadLambda title={title} />}
            />
            <Route
              path="/crg-assign-weee-load/lsadjf[ioewrtupeyiosjth[tyyot[pessadf54tryhf5wheiorphfdlk;/:id"
              element={<AssignLoadWeee title={title} />}
            />
            <Route
              path="/crg-monthly-expense/k;aslkdjfe[aigjfdhsktuwrept9w0ereur2i-wuarfiopskgjfkl;j/:id"
              element={<MonthlyExpense title={title} />}
            />
            <Route
              path="/crg-final-expenses-by-month/salje3k54j6pe6oytirus-fga9fuwea[irbipsen[aiksjd"
              element={<AllFinalExpenses title={title} />}
            />
            <Route
              path="/crg-view-load-lots/ds;alfkhwepoiruepgskhjtryuw=equrwaiofsjdgkfj;lk/:exportId"
              element={<ViewLoadLots title={title} />}
            />
            <Route
              path="/crg-view-lambda-lots/ds;alfkhwepoiruepgskhjtryuw=equrwaiofsjdgkfj;lk/:exportId"
              element={<ViewLambdaLots title={title} />}
            />
            <Route
              path="/crg-view-weee-lots/ds;alfkhwepoiruepgskhjtryuw=equrwaiofsjdgkfj;lk/:exportId"
              element={<ViewWeeeLots title={title} />}
            />
            <Route
              path="/crg-stock-main/aksdl;fh4piou34-t90th=usdhij[fioa[ioAWEJ;KJ;65GF3HGLBNKHJGFAkkl.,jf321"
              element={<CrgStockMain title={title} />}
            />
            <Route
              path="/crg-stock-catalytics/aksdl;fh4piou34-t90th=usdhij[fioa[ioAWEJ;KJ;65GF3HGLBNKHJGFAkkl.,jf321"
              element={<StockCats title={title} />}
            />
            <Route
              path="/crg-stock-lambda/aksdl;fh4piou34-t90th=usdhij[fioa[ioAWEJ;KJ;65GF3HGaskd;lreHJGFAkkljf321"
              element={<StockLambda title={title} />}
            />
            <Route
              path="/crg-stock-weee/aksdl;fh4piou34-t90th=usdhij[fioa[ioAWEJ;KJ;65GF3HGaskd;lreHJGFAkkljf321"
              element={<StockWeee title={title} />}
            />
            <Route
              path="/daily-contacts"
              element={<DailyContacts title={title} />}
            />
            <Route
              path="/weekly-contacts"
              element={<WeeklyContacts title={title} />}
            />
            <Route
              path="/contact-list"
              element={<ContactList title={title} />}
            />
            <Route
              path="/contact-details/:id"
              element={<ContactDetails title={title} />}
            />
            <Route
              path="/contact-form"
              element={
                <ContactFormWrapper onContactAdded={handleContactAdded} />
              }
            />
            <Route
              path="/adduser-form"
              element={<AddUserWrapper onUserAdded={handleContactAdded} />}
            />
            <Route path="/price" element={<PriceCalculator title={title} />} />
            <Route
              path="/calendar-view"
              element={<CalendarView title={title} />}
            />
            <Route
              path="/schedule-admin-view"
              element={<ScheduleAdminView title={title} />}
            />
            <Route
              path="/schedule-sales-view"
              element={<ScheduleSalesView title={title} />}
            />
            <Route
              path="/crg-export-!2s55x55x5erewlaerio15!£$$55fg"
              element={<ExportMain title={title} />}
            />
            <Route
              path="/crg-export-load-details/asd6ar5e459843y4tr65j4hk6yu5y4usfd5g1a+f56ds4f+af/:id"
              element={<ExportDetails title={title} />}
            />
            <Route
              path="/crg-export-load-details/asd6ar5e459843y4tr65jds4tTh44jgfhdshds4f+af/:id"
              element={<ExportDetailsLambda title={title} />}
            />
            <Route
              path="/crg-export-weee-details/asd6ar5e459843y4tr65j4hk6yu5y4usfd5g1a+f56ds4f+af/:id"
              element={<ExportDetailsWeee title={title} />}
            />
            <Route
              path="/calendar-predict-view"
              element={<CalendarPredictView title={title} />}
            />
            <Route
              path="/customer-material-line-chart"
              element={<CustomerMaterialLineChart />}
            />
            <Route
              path="/sales-login"
              element={<UserLoginForm title={title} />}
            />
            <Route path="/user-statistics/:id" element={<UserStatistics />} />
            <Route path="/contact-stats" element={<ContactStats />} />
            <Route
              path="/contact-edit-admin/:contactId"
              element={<ContactEditAdmin />}
            />
                        <Route
              path="/contact-edit-admin-ind/:id"
              element={<ContactEditAdminInd />}
            />
            <Route path="/crg-egypt-data" element={<CrgEgyptMain />} />
            <Route
              path="/edit-fixed-expenses"
              element={<EditFixedExpenses />}
            />
            <Route
              path="/edit-variable-expenses"
              element={<EditVariableExpenses />}
            />
            <Route path="/debt-breakdown" element={<DebtBreakdown />} />
            <Route path="/user-report" element={<UserDailyMaterialChart />} />
            <Route
              path="/user-report-team-member"
              element={<TeamMemberMaterialChart />}
            />
            <Route
              path="/user-report-admin"
              element={<UserAdminDailyMaterialChart />}
            />
            <Route path="/warehouse-schedule" element={<CalendarWarehouse />} />
            <Route path="/loads-main" element={<LoadsMain />} />
            <Route
              path="/view-edit-single-load/:loadId"
              element={<ViewLoad />}
            />
            <Route
              path="/view-edit-single-load-weee/:loadId"
              element={<ViewWeeeLoad />}
            />
            <Route
              path="/view-weee-full-stock"
              element={<ViewWeeeStock />}
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
