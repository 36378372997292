import React, { useState, useEffect } from "react";
import axios from "axios";
import "../export/export.css";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faRemove } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../userAuth";

const ViewLoad = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const { loadId } = useParams();
  const [saveMessage, setSaveMessage] = useState("");
  const [highlightedFields, setHighlightedFields] = useState({});
  const [exportData, setExportData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [loadValues, setLoadValues] = useState(null);
  const [resellUnits, setResellUnits] = useState(0);
  const [resellValue, setResellValue] = useState(0);
  const [averageBought, setAverageBought] = useState({ pt: 0, pd: 0, rh: 0 });
  const [weightWarning, setWeightWarning] = useState(false);
  const navigate = useNavigate();

  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const fetchLoadData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/single-load/${loadId}`);
      setExportData(response.data);
      setEditedData(response.data);

      const indLots = response.data.lotsAll?.indLots || [];
      if (indLots.length === 0) return;

      const totalKilograms = indLots.reduce(
        (total, lot) => total + (lot.kilogramsInd || 0),
        0
      );
      const totalUsdSpent = indLots.reduce(
        (total, lot) => total + (lot.usdSpentInd || 0),
        0
      );
      const totalUsdValue = indLots.reduce(
        (total, lot) => total + (lot.usdValueInd || 0),
        0
      );

      let totalPlatinum = 0;
      let totalPalladium = 0;
      let totalRhodium = 0;

      indLots.forEach((lot) => {
        const proportion = (lot.kilogramsInd || 0) / totalKilograms;
        totalPlatinum += (lot.ptLme || 0) * proportion;
        totalPalladium += (lot.pdLme || 0) * proportion;
        totalRhodium += (lot.rhLme || 0) * proportion;
      });

      const computedAverageBought = {
        pt: totalPlatinum,
        pd: totalPalladium,
        rh: totalRhodium,
      };

      const loadValues = {
        totalKilograms,
        usdSpentInd: totalUsdSpent,
        usdValueInd: totalUsdValue,
      };

      setAverageBought(computedAverageBought);
      setLoadValues(loadValues);

      // Merge into editedData
      setEditedData((prev) => ({
        ...prev,
        averageBought: computedAverageBought,
      }));
    } catch (error) {
      console.error("Error fetching load details:", error);
    }
  };

  // Run fetchLoadData on component mount and when loadId changes
  useEffect(() => {
    fetchLoadData();
  }, [loadId]);

  const handleChange = (e, parentKey, childKey) => {
    const { value } = e.target;
    const numericValue = parseFloat(value) || 0;

    setEditedData((prevState) => {
      if (!prevState) return prevState;

      return {
        ...prevState,
        [parentKey]: {
          ...(prevState[parentKey] || {}), // Preserve existing nested data
          [childKey]: numericValue,
        },
      };
    });

    // Check if weight exceeds 10 and show warning
    if (parentKey === "crgXrf" && childKey === "weight") {
      setWeightWarning(numericValue > 100);
    }
  };

  const handleSubmitResell = async (e) => {
    e.preventDefault();

    try {
      const updatedLoad = {
        resellUnits: (editedData.resellUnits || 0) + resellUnits,
        resellValue: (editedData.resellValue || 0) + resellValue,
        autoAddedWeight: editedData.autoAddedWeight - resellUnits,
        autoAddedSpent: editedData.autoAddedSpent - resellValue,
      };

      const response = await axios.put(
        `${baseUrl}/api/update-load/resell/${loadId}`,
        updatedLoad
      );

      setEditedData(response.data); // Update UI with new values
      setResellUnits(0);
      setResellValue(0);
      await fetchLoadData();
    } catch (error) {
      console.error("Error updating resell values:", error);
    }
  };

  const handleSave = async () => {
    try {
      const updatedData = {
        ...editedData,
        averageBoughtProportion: editedData.averageBought,
        crgXrf: {
          weight: editedData.crgXrf?.weight || 0,
          pdPpm: editedData.crgXrf?.pdPpm || 0,
          ptPpm: editedData.crgXrf?.ptPpm || 0,
          rhPpm: editedData.crgXrf?.rhPpm || 0,
          pdOz: editedData.crgXrf?.pdOz || 0,
          ptOz: editedData.crgXrf?.ptOz || 0,
          rhOz: editedData.crgXrf?.rhOz || 0,
        },
      };

      await axios.put(
        `${baseUrl}/api/loads-edit-singleload/from-view-load/${loadId}`,
        updatedData
      );
      setExportData(updatedData);

      // Show success message
      setSaveMessage("Saved successfully!");

      // Highlight updated fields
      setHighlightedFields({
        averageBought: {
          pt: true,
          pd: true,
          rh: true,
        },
        crgXrf: {
          weight: true,
          pdPpm: true,
          ptPpm: true,
          rhPpm: true,
          pdOz: true,
          ptOz: true,
          rhOz: true,
        },
      });

      // Remove highlight and message after 2 seconds
      setTimeout(() => {
        setSaveMessage("");
        setHighlightedFields({});
      }, 2000);
    } catch (error) {
      console.error("Error updating export document:", error);
    }
  };

  const handleRemoveCustomer = async (lotIndex) => {
    try {
      if (!editedData?.lotsAll?.indLots) return;

      // Remove the selected lot based on its "index" value
      const updatedLots = editedData.lotsAll.indLots.filter(
        (lot) => lot.index !== lotIndex // Ensure correct filtering
      );

      // Recalculate values based on remaining lots
      const newAutoAddedWeight = updatedLots.reduce(
        (total, lot) => total + (lot.kilogramsInd || 0),
        0
      );
      const newAutoAddedSpent = updatedLots.reduce(
        (total, lot) => total + (lot.usdSpentInd || 0),
        0
      );
      const newAutoAddedGross = updatedLots.reduce(
        (total, lot) => total + (lot.usdValueInd || 0),
        0
      );

      // Prepare updated data
      const updatedLoad = {
        lotsAll: { indLots: updatedLots },
        autoAddedWeight: newAutoAddedWeight,
        autoAddedSpent: newAutoAddedSpent,
        autoAddedGross: newAutoAddedGross,
      };

      // Send update request to backend
      await axios.put(`${baseUrl}/api/remove-customer/${loadId}`, updatedLoad);

      // Update frontend state
      setEditedData((prev) => ({
        ...prev,
        lotsAll: { indLots: updatedLots },
        autoAddedWeight: newAutoAddedWeight,
        autoAddedSpent: newAutoAddedSpent,
        autoAddedGross: newAutoAddedGross,
      }));

      // Fetch latest data to ensure consistency
      await fetchLoadData();
    } catch (error) {
      console.error("Error removing customer:", error);
    }
  };

  if (!exportData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="stock-second">
      {isLoggedIn && title === "Admin" && (
        <>
          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">Average Bought On</div>
              <table className="table50">
                <thead>
                  <tr>
                    <th>PT</th>
                    <th>PD</th>
                    <th>RH</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        className={`inputExportDetailsFixations ${
                          highlightedFields.averageBought?.pt ? "highlight" : ""
                        }`}
                        type="number"
                        value={
                          (editedData?.averageBought?.pt ?? 0).toFixed(0) || ""
                        }
                        onChange={(e) => handleChange(e, "averageBought", "pt")}
                      />
                    </td>
                    <td>
                      <input
                        className={`inputExportDetailsFixations ${
                          highlightedFields.averageBought?.pd ? "highlight" : ""
                        }`}
                        type="number"
                        value={
                          (editedData?.averageBought?.pd ?? 0).toFixed(0) || ""
                        }
                        onChange={(e) => handleChange(e, "averageBought", "pd")}
                      />
                    </td>
                    <td>
                      <input
                        className={`inputExportDetailsFixations ${
                          highlightedFields.averageBought?.rh ? "highlight" : ""
                        }`}
                        type="number"
                        value={
                          (editedData?.averageBought?.rh ?? 0).toFixed(0) || ""
                        }
                        onChange={(e) => handleChange(e, "averageBought", "rh")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">CRG Proportion</div>
              <table className="table50">
                <thead>
                  <tr>
                    <th>Weight KG</th>
                    <th>PT Ppm</th>
                    <th>PD Ppm</th>
                    <th>RH Ppm</th>
                  </tr>
                </thead>
                <tbody>
                  <td>
                    {weightWarning && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginBottom: "5px",
                        }}
                      >
                        Warning: Make sure you are entering kilograms, not
                        grams!
                      </div>
                    )}
                    <input
                      className={`inputExportDetailsFixations ${
                        highlightedFields.crgXrf?.weight ? "highlight" : ""
                      }`}
                      type="number"
                      value={editedData?.crgXrf?.weight || ""}
                      onChange={(e) => handleChange(e, "crgXrf", "weight")}
                    />
                  </td>
                  <td>
                    <input
                      className={`inputExportDetailsFixations ${
                        highlightedFields.crgXrf?.ptPpm ? "highlight" : ""
                      }`}
                      type="number"
                      value={editedData?.crgXrf?.ptPpm || ""}
                      onChange={(e) => handleChange(e, "crgXrf", "ptPpm")}
                    />
                  </td>

                  <td>
                    <input
                      className={`inputExportDetailsFixations ${
                        highlightedFields.crgXrf?.pdPpm ? "highlight" : ""
                      }`}
                      type="number"
                      value={editedData?.crgXrf?.pdPpm || ""}
                      onChange={(e) => handleChange(e, "crgXrf", "pdPpm")}
                    />
                  </td>
                  <td>
                    <input
                      className={`inputExportDetailsFixations ${
                        highlightedFields.crgXrf?.rhPpm ? "highlight" : ""
                      }`}
                      type="number"
                      value={editedData?.crgXrf?.rhPpm || ""}
                      onChange={(e) => handleChange(e, "crgXrf", "rhPpm")}
                    />
                  </td>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">Expected Metal Oz</div>
              <table className="table50">
                <thead>
                  <tr>
                    <th>PT Oz</th>
                    <th>PD Oz</th>
                    <th>RH Oz</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        className={`inputExportDetailsFixations ${
                          highlightedFields.crgXrf?.ptOz ? "highlight" : ""
                        }`}
                        type="number"
                        value={editedData?.crgXrf?.ptOz || ""}
                        onChange={(e) => handleChange(e, "crgXrf", "ptOz")}
                      />
                    </td>
                    <td>
                      <input
                        className={`inputExportDetailsFixations ${
                          highlightedFields.crgXrf?.pdOz ? "highlight" : ""
                        }`}
                        type="number"
                        value={editedData?.crgXrf?.pdOz || ""}
                        onChange={(e) => handleChange(e, "crgXrf", "pdOz")}
                      />
                    </td>
                    <td>
                      <input
                        className={`inputExportDetailsFixations ${
                          highlightedFields.crgXrf?.rhOz ? "highlight" : ""
                        }`}
                        type="number"
                        value={editedData?.crgXrf?.rhOz || ""}
                        onChange={(e) => handleChange(e, "crgXrf", "rhOz")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="stock-table-containers">
              <div className="h1HeadersStockDetails">Load Summary</div>
              <table className="table50">
                <thead>
                  <tr>
                    <th>Weight</th>
                    <th>Spent</th>
                    <th>Weight Final</th>
                    <th>Spent Final</th>
                    <th>Gross</th>
                    <th>Margin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="inputExportDetailsFixations">
                      {loadValues?.totalKilograms
                        ? `${loadValues.totalKilograms} Kg`
                        : "0"}
                    </td>
                    <td className="inputExportDetailsFixations">
                      ${loadValues?.usdSpentInd ?? 0}
                    </td>
                    <td className="inputExportDetailsFixations">
                      {exportData?.autoAddedWeight
                        ? `${exportData.autoAddedWeight} Kg`
                        : "0"}
                    </td>
                    <td className="inputExportDetailsFixations">
                      ${exportData?.autoAddedSpent ?? 0}
                    </td>
                    <td className="inputExportDetailsFixations">
                      ${loadValues?.usdValueInd ?? 0}
                    </td>
                    <td className="inputExportDetailsFixations">
                      {loadValues?.usdSpentInd && loadValues?.usdValueInd
                        ? (
                            (loadValues.usdValueInd / loadValues.usdSpentInd) *
                            100
                          ).toFixed(2) + "%"
                        : "0"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="exportExpensesContainer">
            <div className="exportExpensesBox">
              <div className="h2HeadersStockDetails">Add Resell</div>
              <form onSubmit={handleSubmitResell}>
                <input
                  className="inputTaskExpenses"
                  type="number"
                  placeholder="Units Sold"
                  value={resellUnits}
                  onChange={(e) => setResellUnits(Number(e.target.value))}
                />
                <input
                  className="inputTaskExpenses"
                  type="number"
                  placeholder="USD Value Received"
                  value={resellValue}
                  onChange={(e) => setResellValue(Number(e.target.value))}
                />
                <button className="expenseAllButton" type="submit">
                  Submit Resell
                </button>
              </form>
            </div>

            <div className="exportExpensesBox">
              <div className="h2HeadersStockDetails">Resells</div>
              <table className="table50">
                <thead className="table50">
                  <tr className="table50">
                    <th className="table50header">Units Sold</th>
                    <th className="table50header">USD Received</th>
                  </tr>
                </thead>
                <tbody className="table50">
                  <tr className="table50">
                    <td className="inputExportDetailsDate">
                      {editedData?.resellUnits || 0}
                    </td>
                    <td className="inputExportDetails">
                      $ {editedData?.resellValue?.toFixed(2) || "0.00"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {saveMessage && <div className="saveMessage">{saveMessage}</div>}

          <div className="bottomButtonRow">
            <div className="exportCreateLoadButton" onClick={handleSave}>
              <FontAwesomeIcon className="dashboardIcons" icon={faSave} />
              <span>Save</span>
            </div>
          </div>
        </>
      )}

      {exportData?.lotsAll?.indLots && (
        <div className="exportExpensesContainer">
          <div className="stock-table-containers">
            <div className="h1HeadersStockDetails">Assigned Customers</div>
            <table className="table50">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Customer</th>
                  <th>Weight</th>
                  <th>Spent</th>
                  <th>Gross</th>
                  <th>Margin</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {exportData.lotsAll.indLots.map((lot, index) => (
                  <tr key={lot.index}>
                    {" "}
                    {/* Ensure key is unique */}
                    <td>{index + 1}</td>
                    <td>{lot.contactCrg?.name || "N/A"}</td>
                    <td>{lot.kilogramsInd || "0"}</td>
                    <td>${lot.usdSpentInd || "0"}</td>
                    <td>${lot.usdValueInd || "0"}</td>
                    <td>
                      {lot.usdSpentInd > 0
                        ? ((lot.usdValueInd / lot.usdSpentInd) * 100).toFixed(
                            2
                          ) + "%"
                        : "0%"}
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon={faRemove}
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => handleRemoveCustomer(lot.index)} // Pass lot.index instead of array index
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewLoad;
