import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../userAuth';
import './export.css'

const ViewLambdaLots = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  const { exportId } = useParams();
  const [lotsDataSum, setLotsDataSum] = useState({ kilogramTotal: 0, usdValueTotal: 0, usdSpentTotal: 0, fixedValueTotal: 0 });
  const [lotsData, setLotsData] = useState([]);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch lots data
        const lotsResponse = await axios.get(`${baseUrl}/api/load/lots/${exportId}`);
        setLotsData(lotsResponse.data.lotsAll.indLots);
  
        // Calculate sums from lots data
        const { kilogramTotal, usdValueTotal, usdSpentTotal, fixedValueTotal } = lotsResponse.data.lotsAll.indLots.reduce((acc, curr) => {
          return {
            kilogramTotal: acc.kilogramTotal + curr.kilogramsInd,
            usdValueTotal: acc.usdValueTotal + curr.usdValueInd,
            usdSpentTotal: acc.usdSpentTotal + curr.usdSpentInd,
            fixedValueTotal: acc.fixedValueTotal + curr.fixedValue,
          };
        }, { kilogramTotal: 0, usdValueTotal: 0, usdSpentTotal: 0, fixedValueTotal: 0 });
        setLotsDataSum({ kilogramTotal, usdValueTotal, usdSpentTotal, fixedValueTotal });
  
        // Fetch fixed price calculation for each lot
        const promises = lotsResponse.data.lotsAll.indLots.map(async (lot) => {
          const { ptPpm, pdPpm, rhPpm, kilogramsInd } = lot;
          const { pdLme, ptLme, rhLme} = lotsResponse.data.fixedLme;
          const kilogramsIndToGrams = kilogramsInd * 1000;
          const response = await axios.post(`${baseUrl}/api/export/load-fixed-price-calculation/${exportId}`, {
            lotPtPpm: ptPpm,
            lotPdPpm: pdPpm,
            lotRhPpm: rhPpm,
            grams: kilogramsIndToGrams,
            lotLmePd: pdLme,
            lotLmePt: ptLme,
            lotLmeRh: rhLme,
          });
        });
  
        // Wait for all promises to resolve
        await Promise.all(promises);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0

    // Pad single digits with leading zero
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return `${formattedDay}/${formattedMonth}`;
}
  

  return (
    <div className="exports-second">
      {isLoggedIn && title === 'Admin' && (
    <>
       <div className='exports-third-row-loadview'>
       <h2 className='h1HeadersExportDetails'>Added Lots</h2>
      <div className='export-unused-row'>

        <div className='export-unused'>
          {lotsDataSum.kilogramTotal.toFixed(2)} Kg
        </div>
        <div className='export-unused'>
         Est Gross ${lotsDataSum.usdValueTotal}
        </div>
        </div>
        <div className='export-unused-row'>
        <div className='export-unused'>
         Spent ${lotsDataSum.usdSpentTotal}
        </div>
        <div className='export-unused'>
        ${(lotsDataSum.usdValueTotal / lotsDataSum.kilogramTotal).toFixed(2)} / Kg
        </div>
        <div className='export-unused'>
        {(lotsDataSum.usdValueTotal / lotsDataSum.usdSpentTotal * 100).toFixed(2)}%
        </div>
      </div>
      </div>
      <table>
        <thead>
          <tr>
              <th>Date</th>
              <th>Customer</th>
              <th>Spent</th>
              <th>Est Gross</th>
              <th>Margin</th>
              <th>Kilograms</th>
          </tr>
        </thead>
        <tbody>
          {lotsData.map((lot, index) => (
            <tr key={index}>
              <td>{formatDate(lot.dateInd)}</td>
              <td>{lot.contactCrg.name}</td>
              <td>${lot.usdSpentInd}</td>
              <td>${lot.usdValueInd}</td>
              <td>${(lot.usdValueInd / lot.kilogramsInd).toFixed(2)}</td>
              <td>{lot.kilogramsInd}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </>
            )}
    </div>
  );
};

export default ViewLambdaLots;
