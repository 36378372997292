import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../userAuth';
import axios from 'axios';

const AssignLoad = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
    const [ceramicsData, setCeramicsData] = useState([]);
    const { id } = useParams();
    const [exportLoad, setExportLoad] = useState(null);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

        const fetchCeramicsData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/ceramics`);
                setCeramicsData(response.data);
            } catch (error) {
                console.error('Error fetching ceramics data:', error);
            }
        };

        const fetchExportById = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/exportsingleload/${id}`);
                setExportLoad(response.data);
            } catch (error) {
                console.error('Error fetching export document:', error);
            }
        };

    useEffect(() => {
        fetchCeramicsData();
        fetchExportById();
    }, []);

    

    const handleCheckboxChange = async (ceramicsIndex, subitemIndex, subitem) => {
        try {
          const updatedCeramicsData = [...ceramicsData];
          updatedCeramicsData[ceramicsIndex].ceramicsCrg.indCeramicsCrg[subitemIndex].assigned = true;
          const index = subitem.index;
      
          // Call backend route to update assigned field
          await axios.put(`${baseUrl}/api/updateCeramicsCrg`, {
            indCeramicsCrg: {
              ...updatedCeramicsData[ceramicsIndex].ceramicsCrg.indCeramicsCrg[subitemIndex],
              index: index,
            }
          });
    
            // Call backend route to update export modal
            await axios.put(`${baseUrl}/api/updateExportModal/${id}`, {
                indCeramicsCrg: updatedCeramicsData[ceramicsIndex].ceramicsCrg.indCeramicsCrg[subitemIndex]
            });
            // Update ceramicsData state if needed
            setCeramicsData(updatedCeramicsData);
        } catch (error) {
            console.error('Error updating ceramics data:', error);
        }
    };
    
    

    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <div className="exports-second">
      {isLoggedIn && title === 'Admin' && (
    <>
            <div className='exports-third-row-loadview'>
        {exportLoad && (
            <h2 className='h1HeadersExportDetails'>Assign to Lot {exportLoad.load}</h2>
            
        )}
           
   <div className='export-unused-row'>
   {ceramicsData && ceramicsData.length > 0 && ( // Check if ceramicsData exists and has at least one item
    <div className='export-unused'>
      Spent ${ceramicsData[0].sumUsdSpent}
    </div>
  )}
  {ceramicsData && ceramicsData.length > 0 && ( // Check if ceramicsData exists and has at least one item
    <div className='export-unused'>
      {ceramicsData[0].sumKilograms.toFixed(2)} Kg
    </div>
  )}
  {ceramicsData && ceramicsData.length > 0 && ( // Check if ceramicsData exists and has at least one item
    <div className='export-unused'>
      Gross ${ceramicsData[0].sumUsdValue}
    </div>
  )}
    {ceramicsData && ceramicsData.length > 0 && ( // Check if ceramicsData exists and has at least one item
    <div className='export-unused'>
      ${(ceramicsData[0].sumUsdValue / ceramicsData[0].sumKilograms).toFixed(2)} / Kg
    </div>
  )}
      {ceramicsData && ceramicsData.length > 0 && ( // Check if ceramicsData exists and has at least one item
    <div className='export-unused'>
      {(ceramicsData[0].sumUsdValue / ceramicsData[0].sumUsdSpent * 100).toFixed(2)}%
    </div>
  )}
</div>

        
        </div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>Spent</th>
                        <th>Kilograms</th>
                        <th>Assign</th> {/* New column for the checkbox */}
                    </tr>
                </thead>
                <tbody>
                {ceramicsData.map((item, ceramicsIndex) => (
                    item.ceramicsCrg.indCeramicsCrg.map((subitem, subitemIndex) => (
                        <tr key={subitem._id}> {/* Use _id as key */}
                            <td>{subitem.dateCrg ? formatDate(subitem.dateCrg) : ''}</td>
                            <td>{subitem.contactCrg.name}</td>
                            <td>${subitem.usdSpentCrg}</td>
                            <td>{subitem.kilogramsCrg}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={subitem.assigned}
                                    onChange={() => handleCheckboxChange(ceramicsIndex, subitemIndex, subitem)}
                                />
                            </td>
                        </tr>
                    ))
                ))}

                </tbody>
            </table>
            </>
            )}
        </div>
    );
};

export default AssignLoad;
