import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../userAuth";
import "./exportForm.css";

const ExportForm = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';
  const [formData, setFormData] = useState({
    month: "",
    material: "",
    load: "",
    fixedLme: {
      pdLme: 0,
      ptLme: 0,
      rhLme: 0,
      pdOz: 0,
      ptOz: 0,
      rhOz: 0,
    },
    crgProportion: {
      weight: 0,
      pdPpm: 0,
      ptPpm: 0,
      rhPpm: 0,
    },
    crgXrf: {
      weight: 0,
      pdPpm: 0,
      ptPpm: 0,
      rhPpm: 0,
    },
    tXrf: {
      weight: 0,
      pdPpm: 0,
      ptPpm: 0,
      rhPpm: 0,
      pdOz: 0,
      ptOz: 0,
      rhOz: 0,
    },
    tIcp: {
      weight: 0,
      pdPpm: 0,
      ptPpm: 0,
      rhPpm: 0,
      pdOz: 0,
      ptOz: 0,
      rhOz: 0,
    },
    fixedExpenses: 0,
    variableExpenses: 0,
    exportExpenses: 0,
  });

  useEffect(() => {
    fetchLastExport();
  }, []);

  const fetchLastExport = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/exports/lastone`);
      if (response.data) {
        // Parse the current load value
        const currentLoadNumber = parseInt(response.data.load.slice(1));
        // Increment the load number
        const nextLoadNumber = currentLoadNumber + 1;
        // Construct the new load value with 'T' followed by the incremented number
        const newLoadValue = `T${nextLoadNumber}`;

        setFormData((prevState) => ({
          ...prevState,
          load: newLoadValue, // Update the load field with the latest load value
        }));
      }
    } catch (error) {
      console.error("Error fetching last export document:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/export`, formData);
      // Extract the ID from the response
      const newDocumentId = response.data.export._id;
      // Clear the form data after successful submission
      setFormData({
        month: "",
        material: "",
        load: formData.load,
        fixedLme: {
          pdLme: 0,
          ptLme: 0,
          rhLme: 0,
          pdOz: 0,
          ptOz: 0,
          rhOz: 0,
        },
        crgProportion: {
          weight: 0,
          pdPpm: 0,
          ptPpm: 0,
          rhPpm: 0,
        },
        crgXrf: {
          weight: 0,
          pdPpm: 0,
          ptPpm: 0,
          rhPpm: 0,
        },
        tXrf: {
          weight: 0,
          pdPpm: 0,
          ptPpm: 0,
          rhPpm: 0,
          pdOz: 0,
          ptOz: 0,
          rhOz: 0,
        },
        tIcp: {
          weight: 0,
          pdPpm: 0,
          ptPpm: 0,
          rhPpm: 0,
          pdOz: 0,
          ptOz: 0,
          rhOz: 0,
        },
        fixedExpenses: 0,
        variableExpenses: 0,
        exportExpenses: 0,
      });
      // Redirect the user to '/crg-export-load-details' with the new document's ID
      navigate(
        `/crg-export-load-details/asd6ar5e459843y4tr65j4hk6yu5y4usfd5g1a+f56ds4f+af/${newDocumentId}`
      );
    } catch (error) {
      console.error("Error creating export document:", error);
    }
  };

  return (
    <div className="tasks-container-tasks">
      {isLoggedIn && title === "Admin" && (
        <>
          <form onSubmit={handleSubmit}>
            <h2 className="h1HeadersStockDetailsBigWhite">Create Export</h2>
            <input
              className="inputTaskExport"
              type="date"
              name="month"
              value={formData.month}
              onChange={handleChange}
            />
            <select
              className="selectTaskExport"
              name="material"
              value={formData.material}
              onChange={handleChange}
              required
            >
              <option value="">Select Material</option>
              {/* Default placeholder */}
              <option value="Ceramics">Ceramics</option>
              <option value="Weee">Weee</option>
              <option value="Lambda">Lambda</option>
              <option value="Steel">Steel</option>
              <option value="DPF">DPF</option>
            </select>

            <input
              className="inputTaskExport"
              type="text"
              name="load"
              value={formData.load}
              onChange={handleChange}
            />
            <button
              className="expenseAllButton"
              type="submit"
              disabled={!formData.month}
            >
              Submit
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default ExportForm;
