import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth'; 
import { useParams, useNavigate } from 'react-router-dom';
import EditableRow from './contacteditadminedit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import './contacteditadmin.css';

const ContactEditAdmin = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  const { contactId } = useParams();
  const [contact, setContact] = useState(null);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [enteredName, setEnteredName] = useState('');
  const [editedNoAnswerCount, setEditedNoAnswerCount] = useState(contact?.noAnswerCount || 0);
  const [editedCatalogue, setEditedCatalogue] = useState(contact?.catalogue || '');
  const [nameMismatch, setNameMismatch] = useState(false);
  const [isEditingNoAnswerCount, setIsEditingNoAnswerCount] = useState(false);
  const [isEditingCatalogue, setIsEditingCatalogue] = useState(false);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';


  useEffect(() => {
    const fetchContact = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/contacts/${contactId}`);
        setContact(response.data);
      } catch (error) {
        console.error('Error fetching contact details:', error);
      }
    };

    fetchContact();
  }, [contactId, baseUrl]);

  if (!contact) {
    return <div>Loading...</div>;
  }

  const handleSavePurchase = async (purchaseId) => {
    try {
      // Pass the contactId as a prop to EditableRow
      const response = await axios.get(`${baseUrl}/api/contacts/${contactId}`);
      setContact(response.data);
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };

  const handleTrashIconClick = () => {
    setEnteredName('');
    setNameMismatch(false);
    setShowWarningPopup(true);
  };

  const handlePopupConfirm = async () => {
    // Compare the entered name with the contact's name
    if (enteredName.trim().toLowerCase() === contact.name.trim().toLowerCase()) {
      try {
        await axios.delete(`${baseUrl}/api/contacts/${contactId}`);
        
        // Close the popup and redirect to the previous component
        setShowWarningPopup(false);
        window.history.back(); // This will navigate back to the previous page
      } catch (error) {
        console.error('Error deleting contact:', error);
        // Handle error, e.g., show an error message to the user
      }
    } else {
      // Names do not match, set the mismatch status to true
      setNameMismatch(true);
    }
  };


  const handlePopupCancel = () => {
    setShowWarningPopup(false);
  };

  const handleEditNoAnswerCount = async () => {
    try {
      await axios.put(`${baseUrl}/api/contacts/${contactId}`, {
        noAnswerCount: editedNoAnswerCount,
      });
      const updatedContact = await axios.get(`${baseUrl}/api/contacts/${contactId}`);
      setContact(updatedContact.data);
      setIsEditingNoAnswerCount(false); // Hide the input and save button after successful update
    } catch (error) {
      console.error('Error updating noAnswerCount:', error);
    }
  };

  const handleEditCatalogue = async () => {
    try {
      await axios.put(`${baseUrl}/api/contacts/${contactId}`, {
        catalogue: editedCatalogue,
      });
      const updatedContact = await axios.get(`${baseUrl}/api/contacts/${contactId}`);
      setContact(updatedContact.data);
      setIsEditingCatalogue(false); // Hide the input and save button after successful update
    } catch (error) {
      console.error('Error updating catalogue:', error);
    }
  };

  return (
    <div className='contactEditAdminMain'>
      {isLoggedIn ? (
        <>
          <div className='contactEditAdminSecond'>
            <div>
              <div className='contactEditAdminSecondBox'> Name: {contact.name}</div>
            </div>
            <div>
              <div className='contactEditAdminSecondBox'>
                Warnings: {contact.noAnswerCount}
                {isEditingNoAnswerCount ? (
                  <div>
                    <label>
                      <input
                        className='adminEditWarningInput'
                        type='number'
                        value={editedNoAnswerCount}
                        onChange={(e) => setEditedNoAnswerCount(e.target.value)}
                      />
                    </label>
                    <button onClick={handleEditNoAnswerCount}>Save</button>
                  </div>
                ) : (
                  <FontAwesomeIcon
                    className='editicon'
                    icon={faEdit}
                    onClick={() => setIsEditingNoAnswerCount(true)}
                  />
                )}
              </div>
            </div>
            <div>
              <div className='contactEditAdminSecondBox'>
                Catalogue: {contact.catalogue}
                {isEditingCatalogue ? (
                  <div>
                    <label>
                      <input
                        className='adminEditCatalogueInput'
                        type='text'
                        value={editedCatalogue}
                        onChange={(e) => setEditedCatalogue(e.target.value)}
                      />
                    </label>
                    <button onClick={handleEditCatalogue}>Save</button>
                  </div>
                ) : (
                  <FontAwesomeIcon
                    className='editicon'
                    icon={faEdit}
                    onClick={() => setIsEditingCatalogue(true)}
                  />
                )}
              </div>
            </div>
            <div>
              <div className='contactEditAdminSecondBox'>Owed ${contact.debt}</div>
            </div>
            <div>
              <div className='contactEditAdminSecondBox'>Weee {contact.weee.kilogram.toFixed(0)} Kg</div>
            </div>
            <div>
              <div className='contactEditAdminSecondBox'>Cats {contact.ceramics.kilogram.toFixed(0)} Kg</div>
            </div>
            <div className='contactEditAdminSecondBox'>
              <FontAwesomeIcon
                className='trashicon'
                icon={faTrashCan}
                onClick={handleTrashIconClick}
              />
            </div>
          </div>
  
          {/* Display warning popup */}
          {showWarningPopup && (
            <div className='warning-popup'>
              <p>Are you sure you want to delete this contact?</p>
              <label>
                Enter the name of the contact to confirm:
                <input
                  className='deleteAdminContactInput'
                  type='text'
                  value={enteredName}
                  onChange={(e) => setEnteredName(e.target.value)}
                />
              </label>
              {nameMismatch && <p className='warning-message'>The entered name does not match the contact's name.</p>}
              <button onClick={handlePopupConfirm}>Yes</button>
              <button onClick={handlePopupCancel}>No</button>
            </div>
          )}
  
          {contact.weee.indWeeePurchases && contact.weee.indWeeePurchases.length > 0 && (
            <div className='contactEditAdminTableContainer'>
              <div className='contactEditAdminMainList'>
                <div className="table-container-editAdmin ">
                  <div className="table-header">
                    <div className="table-cell">Weee Kg</div>
                    <div className="table-cell">Profit</div>
                    <div className="table-cell">Date</div>
                    <div className="table-cell">Edit</div>
                    <div className="table-cell">Delete</div>
                  </div>
                  {contact.weee.indWeeePurchases.map((purchase) => (
                    <EditableRow
                      key={purchase._id}
                      purchase={purchase}
                      contactId={contactId} 
                      onSave={() => handleSavePurchase(purchase._id)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
  
          {/* Repeat the same pattern for ceramics purchases */}
          {contact.ceramics.indCeramicsPurchases && contact.ceramics.indCeramicsPurchases.length > 0 && (
            <div className='contactEditAdminTableContainer'>
              <div className='contactEditAdminMainList'>
                <div className="table-container-editAdmin ">
                  <div className="table-header">
                    <div className="table-cell">Cats Kg</div>
                    <div className="table-cell">Profit</div>
                    <div className="table-cell">Date</div>
                    <div className="table-cell">Edit</div>
                    <div className="table-cell">Delete</div>
                  </div>
                  {contact.ceramics.indCeramicsPurchases.map((purchase) => (
                    <EditableRow
                      key={purchase._id}
                      purchase={purchase}
                      contactId={contactId} 
                      onSave={() => handleSavePurchase(purchase._id)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <p>Please log in to view contact details.</p>
      )}
    </div>
  );
  
};

export default ContactEditAdmin;