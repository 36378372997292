import React, { useState } from 'react';
import axios from 'axios';

const EditableRow = ({ purchase, onSave, contactId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedKilograms, setEditedKilograms] = useState(purchase.kilograms);
  const [editedUsdValue, setEditedUsdValue] = useState(purchase.usdValue);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${baseUrl}/api/purchases/${contactId}/${purchase._id}`);

      // Notify the parent component about the deletion
      onSave(purchase._id);
    } catch (error) {
      console.error('Error deleting purchase:', error);
    }
  };

  const handleSave = async () => {
    try {
      await axios.patch(`${baseUrl}/api/purchases/${contactId}/${purchase._id}`, {
        kilograms: editedKilograms,
        usdValue: editedUsdValue,
      });

      // Notify the parent component about the save
      onSave();
    } catch (error) {
      console.error('Error updating purchase:', error);
    }

    setIsEditing(false);
  };
  
  const handleCancel = () => {
    // Reset the edited values and switch back to non-edit mode
    setEditedKilograms(purchase.kilograms);
    setEditedUsdValue(purchase.usdValue);
    setIsEditing(false);
  };

  return (
    <div className="table-row" key={purchase._id}>
      <div className="table-cell">
        {isEditing ? (
          <input
            type="number"
            value={editedKilograms}
            onChange={(e) => setEditedKilograms(e.target.value)}
          />
        ) : (
          purchase.kilograms
        )}
      </div>
      <div className="table-cell">
        {isEditing ? (
          <input
            type="number"
            value={editedUsdValue}
            onChange={(e) => setEditedUsdValue(e.target.value)}
          />
        ) : (
          purchase.usdValue
        )}
      </div>
      <div className="table-cell">{new Date(purchase.date).toLocaleDateString()}</div>
      <div className="table-cell">
        {isEditing ? (
          <>
            <button onClick={handleSave}>Save</button>
            <button onClick={handleCancel}>Cancel</button>
          </>
        ) : (
          <button onClick={handleEdit}>Edit</button>
        )}
      </div>
      <div className="table-cell">
          <button onClick={handleDelete}>Delete</button>
      </div>
    </div>
  );
};

export default EditableRow;
