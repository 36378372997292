import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './export.css';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEye, faPlus, faPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../userAuth';

const ExportDetailsLambda = () => {
    const { isLoggedIn } = useAuth();
    const title = localStorage.title;
    const { id } = useParams();
    const [isShipped, setIsShipped] = useState(false);
    const [exportData, setExportData] = useState(null);
    const [lotsData, setLotsData] = useState([]);
    const [totalUsdSpentInd, setTotalUsdSpentInd] = useState([]);
    const [netCrg, setNetCrg] = useState(null);
    const [netTechXrf, setNetTechXrf] = useState(null);
    const [netIcp, setNetIcp] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/login');
      }
    }, [isLoggedIn, navigate]);
    const [editedData, setEditedData] = useState(null);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

    useEffect(() => {
        const fetchExportById = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/exportsingleload/${id}`);
                setLotsData(response.data.lotsAll.indLots);
                setExportData(response.data);
                setEditedData(response.data);
                setNetCrg((response.data.estGrossCrg - response.data.variableExpenses - response.data.fixedExpenses - response.data.exportExpenses - response.data.lotsAll.usdSpentTotal).toFixed(0))
                setNetTechXrf((response.data.estGrossTechXrf - response.data.variableExpenses - response.data.fixedExpenses - response.data.exportExpenses - response.data.lotsAll.usdSpentTotal).toFixed(0))
                setNetIcp((response.data.estGrossTechXrf - response.data.variableExpenses - response.data.fixedExpenses - response.data.exportExpenses - response.data.lotsAll.usdSpentTotal).toFixed(0))
                setIsShipped(response.data.shipped)
                setTotalUsdSpentInd(response.data.lotsAll.usdSpentTotal)

            const responseProportion = await axios.post(`${baseUrl}/api/export/load-all-proportion-calculation/${id}`,);

            } catch (error) {
                console.error('Error fetching export document:', error);
            }
        };

        fetchExportById();
    }, [id]);

    useEffect(() => {
        const fetchExpenses = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/expenses/perload/${exportData.load}`);
                const totalFixedExpenses = response.data.reduce((acc, expense) => {
                    return acc + expense.fixedExpenses.reduce((acc, curr) => acc + curr.spentUsd, 0);
                }, 0);
                // Calculate sum of variable expenses
                const totalVariableExpenses = response.data.reduce((acc, expense) => {
                    return acc + expense.variableExpenses.reduce((acc, curr) => acc + curr.spentUsd, 0);
                }, 0);
            // Calculate total export expenses
            const totalExportExpenses = response.data.reduce((acc, expense) => {
                return acc + expense.usdExport.reduce((acc, curr) => acc + curr.usdSpent, 0);
            }, 0);
                setEditedData(prevState => ({
                    ...prevState,
                    fixedExpenses: totalFixedExpenses,
                    variableExpenses: totalVariableExpenses,
                    usdExportExpenses: totalExportExpenses
                }));
            } catch (error) {
                console.error('Error fetching expenses:', error);
            }
        };
        if (exportData) {
            fetchExpenses();
        }
    }, [exportData]);
    

    const handleChange = (e, key, nestedKey) => {
        const { value } = e.target;
        if (nestedKey) {
            setEditedData(prevState => ({
                ...prevState,
                [key]: {
                    ...prevState[key],
                    [nestedKey]: value
                }
            }));
        } else {
            setEditedData(prevState => ({
                ...prevState,
                [key]: value
            }));
        }
    };
    
    
    const handleSave = async () => {
        try {
            // Include the calculated values in the editedData object
            const updatedData = {
                ...editedData,
                estNetCrg: netCrg,
                estNetTechIcp: netIcp,
                estNetTechXrf: netTechXrf
            };
    
            // Make a PUT request to update the export document with the updatedData object
            await axios.put(`${baseUrl}/api/exports-edit-load/${id}`, updatedData);
            setExportData(updatedData);
            window.location.reload();
        } catch (error) {
            console.error('Error updating export document:', error);
        }
    };

    const handleSendExport = async () => {
        try {
            // Send a PUT request to update the export
            await axios.put(`${baseUrl}/api/shipping/exports/${id}`, {
                autoAddedSpent: totalUsdSpentInd, // Pass the calculated value
              });

            setIsShipped(true);
            // Handle success
            alert('Export successful!');
            window.location.reload();
            // You may want to refresh the export list or update the UI in some way
        } catch (error) {
            console.error('Error exporting:', error);
            alert('Error exporting. Please try again later.');
        }
    };
    

    if (!exportData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="exports-second">
      {isLoggedIn && title === 'Admin' && (
    <>
              <div className="exports-third-row">
            <div className='h1HeadersStockDetailsBig'>Export Load {exportData.load} Details</div>
            </div>
            <div className='exportExpensesContainer'>
            <div className='exportExpensesBox'>
                <h2 className='h1HeadersExportDetailsExpenses'>Expenses</h2>
                <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                            <th className='table50th'>Fixed</th>
                            <th className='table50th'>Variable</th>
                            <th className='table50th'>Export</th>
                            <th className='table50th'>Spent</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                        <td className='table50td'>${editedData.fixedExpenses}</td>
                            <td className='table50td'>${editedData.variableExpenses}</td>
                            <td className='table50td'>${editedData.usdExportExpenses}</td>
                            <td className='table50td'>${editedData.lotsAll.usdSpentTotal}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
                </div>

            <div className='exportExpensesContainer'>
            <div className='exportExpensesBox'>
                <h2 className='h1HeadersExportDetailsExpenses'>CRG EST ${netCrg} Net</h2>
                <table className='xrfTables'>
                    <tbody className='xrfTables'>
                        <tr className='xrfTables'>
                        <th className='xrfTablesHeaders'>Weight</th>
                            <td className='xrfExportResultsTableBody'><input className='inputExportDetailsResults' type="number" value={editedData.crgXrf.weight} onChange={(e) => handleChange(e, 'crgXrf','weight')} /></td>
                            <th className='xrfTablesHeaders'>Value</th>
                            <td className='xrfExportResultsTableBody'><input className='inputExportDetailsResults' type="number" value={editedData.estGrossCrg} onChange={(e) => handleChange(e, 'estGrossCrg')} /></td>
                        </tr>
                    </tbody>
                </table>
           </div>
           <div className='exportExpensesBox'>
                <h2 className='h1HeadersExportDetailsExpenses'>Agent Est ${netTechXrf} Net</h2>
                <table className='xrfTables'>
                    <tbody className='xrfTables'>
                        <tr className='xrfTables'>
                        <th className='xrfTablesHeaders'>Weight</th>
                        <td className='xrfExportResultsTableBody'><input className='inputExportDetailsResults' type="number" value={editedData.tXrf.weight} onChange={(e) => handleChange(e, 'tXrf','weight')} /></td>
                            <th className='xrfTablesHeaders'>Value</th>
                            <td className='xrfExportResultsTableBody'><input className='inputExportDetailsResults' type="number" value={editedData.estGrossTechXrf} onChange={(e) => handleChange(e, 'estGrossTechXrf')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='exportExpensesBox'>
                <h2 className='h1HeadersExportDetailsExpenses'>Final ${netIcp} Net</h2>
                <table className='xrfTables'>
                    <tbody className='xrfTables'>
                        <tr className='xrfTables'>
                        <th className='xrfTablesHeaders'>Weight</th>
                        <td className='xrfExportResultsTableBody'><input className='inputExportDetailsResults' type="number" value={editedData.tIcp.weight} onChange={(e) => handleChange(e, 'tIcp','weight')} /></td>
                            <th className='xrfTablesHeaders'>Value</th>
                            <td className='xrfExportResultsTableBody'><input className='inputExportDetailsResults' type="number" value={editedData.estGrossTechIcP} onChange={(e) => handleChange(e, 'estGrossTechIcP')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
            <div className='bottomButtonRow'>
            <div className='exportCreateLoadButton' onClick={handleSave}>
            <FontAwesomeIcon className='dashboardIcons' icon={faSave} />
        <span>Save</span>
            </div>
            {!isShipped && (
                <div className='exportCreateLoadButton' onClick={handleSendExport}>
                    <FontAwesomeIcon className='dashboardIcons' icon={faPlane} />
                    <span>Export</span>
                </div>
            )}
            </div>
            </>
            )}
        </div>
    );
};

export default ExportDetailsLambda;
