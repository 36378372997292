import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './goalcharts.css';

const GoalDailyChart = () => {
    const [totalWeeklySpent, setTotalWeeklySpent] = useState(0);
    const [contactsData, setContactsData] = useState([]);
    const navigate = useNavigate();

    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/contacts-daily-data`);
            const totalKg = response.data.totalThisWeekKg;
            const totalSpent = response.data.totalThisWeekSpent;
            setTotalWeeklySpent(totalSpent + totalKg * 5.23);
            setContactsData(response.data.contacts); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [baseUrl]);

    useEffect(() => {
        createPieChart();
    }, [totalWeeklySpent]);

    const handleDailyChartClick = (event) => {
        const activeElement = window.weeklyProfitPieChart.getElementsAtEventForMode(event, 'point', window.weeklyProfitPieChart.options);
        if (activeElement.length > 0) {
            // Navigate to WeeklyContacts component upon clicking the chart
            navigate('/daily-contacts');
        }
    };

    const createPieChart = () => {
        const canvas = document.getElementById('daily-profit-pie-chart');
        if (!canvas) return;

        // Destroy existing chart instance if it exists
        if (window.dailyProfitPieChart) {
            window.dailyProfitPieChart.destroy();
        }

        const ctx = canvas.getContext('2d');

        const data = {
            labels: ['7 Days Late', 'Needed More'],
            datasets: [{
                data: [totalWeeklySpent, 7000 - totalWeeklySpent],
                backgroundColor: ['Yellow', 'Red']
            }]
        };

        // Create the pie chart
        window.dailyProfitPieChart = new Chart(ctx, {
            type: 'pie',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            color: 'white' // Set legend label color to white
                        }
                    },
                    title: {
                        display: true,
                        text: '7 Days Late',
                        color: 'white'
                    }
                },
                onClick: handleDailyChartClick // Attach click event handler
            }
        });
    };

    return (
        <div className="goal-charts-main">
            <canvas id="daily-profit-pie-chart"></canvas>
        </div>
    );
};

export default GoalDailyChart;
