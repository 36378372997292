import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto'; // Import Chart.js
import { useAuth } from '../userAuth'; 
import { useNavigate, Link } from 'react-router-dom'; // Import the useNavigate hook
import './crgegyptmain.css'; // Make sure to import your CSS file

const CrgEgyptMain = () => {
  const { isLoggedIn } = useAuth();
  const [monthsData, setMonthsData] = useState([]);
  const [crgData, setCrgData] = useState(null);
  const title = localStorage.title;
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    // Fetch data from your API endpoint
    axios.get(`${baseUrl}/api/crg`)
      .then(response => {
        setCrgData(response.data);
      })
      .catch(error => {
        console.error('Error fetching CRG data:', error);
      });
  }, [baseUrl]);

  useEffect(() => {
    const fetchMonthsData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/months`);
        setMonthsData(response.data);
      } catch (error) {
        console.error('Error fetching months data:', error);
      }
    };

    fetchMonthsData();
  }, [baseUrl]);

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Year to Date';
    }

    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  };

  const createLineChart = (ctx, dates, kilograms, label, borderColor) => {
    // Check if there's an existing chart instance
    const existingChart = Chart.getChart(ctx);
  
    // If an existing chart is found, destroy it
    if (existingChart) {
      existingChart.destroy();
    }
  
    // Create a new Chart instance
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: dates,
        datasets: [
          {
            label: label,
            data: kilograms,
            borderColor: borderColor,
            borderWidth: 2,
            fill: false,
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: 'category',
            title: {
              display: true,
              text: 'Date',
              color: 'white', // Label color for x-axis title
            },
            ticks: {
              color: 'white', // Label color for x-axis ticks
            },
          },
          y: {
            title: {
              display: true,
              text: 'Kilograms',
              color: 'white', // Label color for y-axis title
            },
            ticks: {
              color: 'white', // Label color for y-axis ticks
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: 'white', // Label color for the legend
            },
          },
        },
      },
    });
  };
  
  

  useEffect(() => {
    // Create WEEE line chart when weeeCrg data is available
    if (crgData?.weeeCrg) {
      const weeeAggregatedData = Object.entries(
        crgData.weeeCrg.indWeeeCrg.reduce((agg, indWeee) => {
          const date = formatDateString(indWeee.dateCrg);
          if (!agg[date]) {
            agg[date] = {
              totalKg: 0,
              totalBuys: 0,
              totalProfit: 0,
            };
          }
          agg[date].totalKg += indWeee.kilogramsCrg;
          agg[date].totalBuys += 1; // Add logic to get individual buys if needed
          agg[date].totalProfit += indWeee.usdValueCrg;
          return agg;
        }, {})
      );
  
      // Extract the dates and aggregated kilograms from the result
      const weeeDates = weeeAggregatedData.map(([date, entry]) => date).reverse();
      const weeeKilograms = weeeAggregatedData.map(([date, entry]) => entry.totalKg).reverse();
  
      const weeeCtx = document.getElementById('weeeLineChart').getContext('2d');
      createLineChart(weeeCtx, weeeDates, weeeKilograms, 'WEEE Data', 'rgba(75, 192, 192, 1)');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crgData?.weeeCrg]);
  
  useEffect(() => {
    // Create Ceramics line chart when ceramicsCrg data is available
    if (crgData?.ceramicsCrg) {
      const ceramicsAggregatedData = Object.entries(
        crgData.ceramicsCrg.indCeramicsCrg.reduce((agg, indCeramics) => {
          const date = formatDateString(indCeramics.dateCrg);
          if (!agg[date]) {
            agg[date] = {
              totalKg: 0,
              totalBuys: 0,
              totalProfit: 0,
            };
          }
          agg[date].totalKg += indCeramics.kilogramsCrg;
          agg[date].totalBuys += 1; // Add logic to get individual buys if needed
          agg[date].totalProfit += indCeramics.usdValueCrg;
          return agg;
        }, {})
      );
  
      // Extract the dates and aggregated kilograms from the result
      const ceramicsDates = ceramicsAggregatedData.map(([date, entry]) => date);
      const ceramicsKilograms = ceramicsAggregatedData.map(([date, entry]) => entry.totalKg);
  
      const ceramicsCtx = document.getElementById('ceramicsLineChart').getContext('2d');
      createLineChart(ceramicsCtx, ceramicsDates, ceramicsKilograms, 'Ceramics Data', 'rgba(255, 99, 132, 1)');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crgData?.ceramicsCrg]);

  useEffect(() => {
    // Create Lambda line chart when weeeCrg data is available
    if (crgData?.lambdaCrg) {
      const lambdaAggregatedData = Object.entries(
        crgData.lambdaCrg.indLambdaCrg.reduce((agg, indLambda) => {
          const date = formatDateString(indLambda.dateCrg);
          if (!agg[date]) {
            agg[date] = {
              totalKg: 0,
              totalBuys: 0,
              totalProfit: 0,
            };
          }
          agg[date].totalKg += indLambda.kilogramsCrg;
          agg[date].totalBuys += 1; // Add logic to get individual buys if needed
          agg[date].totalProfit += indLambda.usdValueCrg;
          return agg;
        }, {})
      );
  
      // Extract the dates and aggregated kilograms from the result
      const lambdaDates = lambdaAggregatedData.map(([date, entry]) => date).reverse();
      const lambdaKilograms = lambdaAggregatedData.map(([date, entry]) => entry.totalKg).reverse();
  
      const lambdaCtx = document.getElementById('weeeLineChart').getContext('2d');
      createLineChart(lambdaCtx, lambdaDates, lambdaKilograms, 'WEEE Data', 'rgba(75, 192, 192, 1)');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crgData?.lambdaCrg]);


  return (
    <div className="crg-container">
      <div className='crg-second-container'>
        {isLoggedIn && title === 'Admin' && (
        <>
          <h1 className='crg-container-text'>CRG Taiwan Main</h1>
  
          {crgData ? (
            <div>

        {/* Table for Ceramics CRG */}
        {crgData?.ceramicsCrg && (
          <>
            <div className="chart-container">
              <h2  className='crg-container-text'>Ceramics Chart</h2>
              <canvas id="ceramicsLineChart"></canvas>
            </div>
            <div className="table-container">
              <div className="table-crg">
                <h2 className='crg-container-text'>Current Month</h2>
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Kg</th>
                      <th>Purchases</th>
                      <th>Spent</th>
                      <th>Per</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Display total for the main entry */}
                    {/* Display totals aggregated by day for individual entries */}
                    {Object.entries(
  crgData.ceramicsCrg.indCeramicsCrg.reduce((agg, indCeramics) => {
    const date = formatDateString(indCeramics.dateCrg);
    const thirtyOneDaysAgo = new Date();
    thirtyOneDaysAgo.setDate(thirtyOneDaysAgo.getDate() - 31);
    if (new Date(indCeramics.dateCrg) >= thirtyOneDaysAgo) { // Filter entries older than 31 days
      if (!agg[date]) {
        agg[date] = {
          totalKg: 0,
          totalBuys: 0,
          totalProfit: 0,
        };
      }
      agg[date].totalKg += indCeramics.kilogramsCrg;
      agg[date].totalBuys += 1; // Add logic to get individual buys if needed
      agg[date].totalProfit += indCeramics.usdValueCrg;
    }
    return agg;
  }, {})
).map(([date, entry], index) => (
  <tr key={index}>
    <td>{date}</td>
    <td>{entry.totalKg.toFixed(2)}</td>
    <td>{entry.totalBuys.toFixed(0)}</td>
    <td>${entry.totalProfit.toFixed(2)}</td>
    <td>${(entry.totalProfit / entry.totalKg).toFixed(2)}</td>
  </tr>
))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        



{crgData?.ceramicsCrg && monthsData.length > 0 && (
  <>
    <div className="table-container">
      <div className="table-crg">
        <h2 className='crg-container-text'>Ceramics By Month</h2>
        <table className="data-table">
          <thead>
            <tr>
              <th>Month</th>
              <th>Kg</th>
              <th>Purchases</th>
              <th>Spent</th>
              <th>Per</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>
                {(monthsData.reduce((sum, monthData) => sum + monthData.kilogramCrg, 0) + crgData.ceramicsCrg.kilogramCrg).toFixed(2)}
              </td>
              <td>{crgData.ceramicsCrg.purchasesCrg.toFixed(0)}</td>
              <td>
                ${(monthsData.reduce((sum, monthData) => sum + monthData.usdSpentCrg, 0) + crgData.ceramicsCrg.usdValueCrg).toFixed(2)}
              </td>
              <td>
                ${
                  ((monthsData.reduce((sum, monthData) => sum + monthData.usdSpentCrg, 0) + crgData.ceramicsCrg.usdValueCrg) /
                    (monthsData.reduce((sum, monthData) => sum + monthData.kilogramCrg, 0) + crgData.ceramicsCrg.kilogramCrg)).toFixed(2)
                }
              </td>
            </tr>
            {monthsData.map((monthData, index) => (
              <tr key={index}>
                <td>{new Date(monthData.month).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</td>
                <td>{monthData.kilogramCrg.toFixed(2)}</td>
                <td>{monthData.purchasesCrg.toFixed(0)}</td>
                <td>${monthData.usdSpentCrg.toFixed(2)}</td>
                <td>${(monthData.usdSpentCrg / monthData.kilogramCrg).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </>
)}



              {/* Table for Ceramics CRG */}
              {crgData.ceramicsCrg && (
  <div className="table-container">
    <div className='table-crg'>
      <h2 className='crg-container-text'>Ceramics Ind. Purchases</h2>
      <table className="data-table">
        <thead>
          <tr>
          <th>Date</th>
          <th>Customer</th>
            <th>Kg</th>
            <th>Spent</th>
            <th>Gross</th>
            <th>$ / Kg</th>

          </tr>
        </thead>
        <tbody>
          {/* Sort the data by date in descending order and filter for the last 6 days */}
          {crgData.ceramicsCrg.indCeramicsCrg
            .filter(indCeramics => {
              const today = new Date();
              const sixDaysAgo = new Date(today);
              sixDaysAgo.setDate(today.getDate() - 6);
              const dateCrg = new Date(indCeramics.dateCrg);
              return dateCrg >= sixDaysAgo && dateCrg <= today;
            })
            .sort((a, b) => new Date(b.dateCrg) - new Date(a.dateCrg))
            .map((indCeramics, index) => (
              <tr key={index}>
                                <td>{formatDateString(indCeramics.dateCrg)}</td>
                                <td>
                  <Link to={`/contact-details/${indCeramics.contactCrg.id}`}>
                    {indCeramics.contactCrg.name.slice(0, 18)}
                  </Link>
                </td>
                <td>{(indCeramics.kilogramsCrg ?? 0).toFixed(2)}</td>
                <td>${(indCeramics.usdSpentCrg ?? 0).toFixed(0)}</td>
                <td>${(indCeramics.usdValueCrg ?? 0).toFixed(0)}</td>
                <td>${(indCeramics.usdValueCrg  ?? 0 / indCeramics.kilogramsCrg  ?? 0).toFixed(2)}</td>

              </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)}


{crgData?.weeeCrg && (
          <>
            <div className="chart-container">
              <h2 className='crg-container-text'>Weee Chart</h2>
              <canvas id="weeeLineChart"></canvas>
            </div>
            <div className="table-container">
              <div className="table-crg">
                <h2 className='crg-container-text'>Weee Last 31 Days</h2>
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Kg</th>
                      <th>Purchases</th>
                      <th>Spent</th>
                      <th>Per</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Display total for the main entry */}
                    <tr>
                      <td>{formatDateString(crgData.weeeCrg.dateCrg)}</td>
                      <td>{crgData.weeeCrg.kilogramCrg.toFixed(2)}</td>
                      <td>{crgData.weeeCrg.purchasesCrg.toFixed(0)}</td>
                      <td>${crgData.weeeCrg.usdValueCrg.toFixed(2)}</td>
                      <td>${(crgData.weeeCrg.usdValueCrg / crgData.weeeCrg.kilogramCrg).toFixed(2)}</td>
                    </tr>
                    {/* Display totals aggregated by day for individual entries */}
                    {Object.entries(
                      crgData.weeeCrg.indWeeeCrg.reduce((agg, indWeee) => {
                        const date = formatDateString(indWeee.dateCrg);
                        if (!agg[date]) {
                          agg[date] = {
                            totalKg: 0,
                            totalBuys: 0,
                            totalProfit: 0,
                          };
                        }
                        agg[date].totalKg += indWeee.kilogramsCrg;
                        agg[date].totalBuys += 1; // Add logic to get individual buys if needed
                        agg[date].totalProfit += indWeee.usdValueCrg;
                        return agg;
                      }, {})
                    ).map(([date, entry], index) => (
                      <tr key={index}>
                        <td>{date}</td>
                        <td>{entry.totalKg.toFixed(2)}</td>
                        <td>{entry.totalBuys.toFixed(0)}</td>
                        <td>${entry.totalProfit.toFixed(2)}</td>
                        <td>${(entry.totalProfit / entry.totalKg).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}


{crgData?.weeeCrg && (
  <>
    <div className="table-container">
      <div className="table-crg">
        <h2 className='crg-container-text'>Weee By Month</h2>
        <table className="data-table">
          <thead>
            <tr>
              <th>Month</th>
              <th>Kg</th>
              <th>Purchases</th>
              <th>Spent</th>
              <th>Per</th>
            </tr>
          </thead>
          <tbody>
            {/* Display totals aggregated by month */}
            {Object.entries(
              crgData.weeeCrg.indWeeeCrg.reduce((agg, indWeee) => {
                const date = new Date(indWeee.dateCrg);
                const thirtyOneDaysAgo = new Date();
                thirtyOneDaysAgo.setDate(thirtyOneDaysAgo.getDate() - 31);
                if (date >= thirtyOneDaysAgo) { // Filter entries older than 31 days
                  const monthYear = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
                  if (!agg[monthYear]) {
                    agg[monthYear] = {
                      totalKg: 0,
                      totalBuys: 0,
                      totalProfit: 0,
                    };
                  }
                  agg[monthYear].totalKg += indWeee.kilogramsCrg;
                  agg[monthYear].totalBuys += 1; // Add logic to get individual buys if needed
                  agg[monthYear].totalProfit += indWeee.usdValueCrg;
                }
                return agg;
              }, {})
            ).map(([monthYear, entry], index) => (
              <tr key={index}>
                <td>{monthYear}</td>
                <td>{entry.totalKg.toFixed(2)}</td>
                <td>{entry.totalBuys.toFixed(0)}</td>
                <td>${entry.totalProfit.toFixed(2)}</td>
                <td>${(entry.totalProfit / entry.totalKg).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </>
)}


  
              {/* Table for Weee CRG */}
              {crgData.weeeCrg && (
                <div className="table-container">
                  <div className='table-crg'>
                    <h2 className='crg-container-text'>Weee Ind Purchases</h2>
                    <table className="data-table">
                      <thead>
                        <tr>
                          <th>Kg</th>
                          <th>Purchases</th>
                          <th>Spent</th>
                          <th>Per</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Sort the data by date in descending order */}
                        {crgData.weeeCrg.indWeeeCrg
                          .sort((a, b) => new Date(b.dateCrg) - new Date(a.dateCrg))
                          .map((indWeee, index) => (
                            <tr key={index}>
                              <td>{indWeee.kilogramsCrg.toFixed(2)}</td>
                              <td>
                                <Link to={`/contact-details/${indWeee.contactCrg.id}`}>
                                  {indWeee.contactCrg.name}
                                </Link>
                              </td>
                              <td>${indWeee.usdValueCrg.toFixed(2)}</td>
                              <td>${(indWeee.usdValueCrg/ indWeee.kilogramsCrg).toFixed(2)}</td>
                              <td>{formatDateString(indWeee.dateCrg)}</td>
                            </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
  



  {crgData?.lambdaCrg && (
          <>
            <div className="chart-container">
              <h2 className='crg-container-text'>Lambda Chart</h2>
              <canvas id="weeeLineChart"></canvas>
            </div>
            <div className="table-container">
              <div className="table-crg">
                <h2 className='crg-container-text'>Lambda Last 31 Days</h2>
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Kg</th>
                      <th>Purchases</th>
                      <th>Spent</th>
                      <th>Per</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Display total for the main entry */}
                    <tr>
                      <td>{formatDateString(crgData.lambdaCrg.dateCrg)}</td>
                      <td>{crgData.lambdaCrg.kilogramCrg.toFixed(2)}</td>
                      <td>{crgData.lambdaCrg.purchasesCrg.toFixed(0)}</td>
                      <td>${crgData.lambdaCrg.usdValueCrg.toFixed(2)}</td>
                      <td>${(crgData.lambdaCrg.usdValueCrg / crgData.lambdaCrg.kilogramCrg).toFixed(2)}</td>
                    </tr>
                    {/* Display totals aggregated by day for individual entries */}
                    {Object.entries(
                      crgData.lambdaCrg.indLambdaCrg.reduce((agg, indLambda) => {
                        const date = formatDateString(indLambda.dateCrg);
                        if (!agg[date]) {
                          agg[date] = {
                            totalKg: 0,
                            totalBuys: 0,
                            totalProfit: 0,
                          };
                        }
                        agg[date].totalKg += indLambda.kilogramsCrg;
                        agg[date].totalBuys += 1; // Add logic to get individual buys if needed
                        agg[date].totalProfit += indLambda.usdValueCrg;
                        return agg;
                      }, {})
                    ).map(([date, entry], index) => (
                      <tr key={index}>
                        <td>{date}</td>
                        <td>{entry.totalKg.toFixed(2)}</td>
                        <td>{entry.totalBuys.toFixed(0)}</td>
                        <td>${entry.totalProfit.toFixed(2)}</td>
                        <td>${(entry.totalProfit / entry.totalKg).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}


{crgData?.lambdaCrg && (
  <>
    <div className="table-container">
      <div className="table-crg">
        <h2 className='crg-container-text'>Lambda By Month</h2>
        <table className="data-table">
          <thead>
            <tr>
              <th>Month</th>
              <th>Kg</th>
              <th>Purchases</th>
              <th>Spent</th>
              <th>Per</th>
            </tr>
          </thead>
          <tbody>
            {/* Display totals aggregated by month */}
            {Object.entries(
              crgData.lambdaCrg.indLambdaCrg.reduce((agg, indLambda) => {
                const date = new Date(indLambda.dateCrg);
                const thirtyOneDaysAgo = new Date();
                thirtyOneDaysAgo.setDate(thirtyOneDaysAgo.getDate() - 31);
                if (date >= thirtyOneDaysAgo) { // Filter entries older than 31 days
                  const monthYear = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
                  if (!agg[monthYear]) {
                    agg[monthYear] = {
                      totalKg: 0,
                      totalBuys: 0,
                      totalProfit: 0,
                    };
                  }
                  agg[monthYear].totalKg += indLambda.kilogramsCrg;
                  agg[monthYear].totalBuys += 1; // Add logic to get individual buys if needed
                  agg[monthYear].totalProfit += indLambda.usdValueCrg;
                }
                return agg;
              }, {})
            ).map(([monthYear, entry], index) => (
              <tr key={index}>
                <td>{monthYear}</td>
                <td>{entry.totalKg.toFixed(2)}</td>
                <td>{entry.totalBuys.toFixed(0)}</td>
                <td>${entry.totalProfit.toFixed(2)}</td>
                <td>${(entry.totalProfit / entry.totalKg).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </>
)}


  
              {/* Table for Weee CRG */}
              {crgData.lambdaCrg && (
                <div className="table-container">
                  <div className='table-crg'>
                    <h2 className='crg-container-text'>Lambda Ind Purchases</h2>
                    <table className="data-table">
                      <thead>
                        <tr>
                          <th>Kg</th>
                          <th>Purchases</th>
                          <th>Spent</th>
                          <th>Per</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Sort the data by date in descending order */}
                        {crgData.lambdaCrg.indLambdaCrg
                          .sort((a, b) => new Date(b.dateCrg) - new Date(a.dateCrg))
                          .map((indLambda, index) => (
                            <tr key={index}>
                              <td>{indLambda.kilogramsCrg.toFixed(2)}</td>
                              <td>
                                <Link to={`/contact-details/${indLambda.contactCrg.id}`}>
                                  {indLambda.contactCrg.name}
                                </Link>
                              </td>
                              <td>${indLambda.usdValueCrg.toFixed(2)}</td>
                              <td>${(indLambda.usdValueCrg/ indLambda.kilogramsCrg).toFixed(2)}</td>
                              <td>{formatDateString(indLambda.dateCrg)}</td>
                            </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}


            </div>
          ) : (
            <p>Loading data...</p>
          )}
        </>
      )}
      </div>
    </div>
  );  
};

export default CrgEgyptMain;
