import React, { useState } from "react";
import "./load.css";

const LoadsMain = () => {
  const isLocal = window.location.hostname === "localhost";
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  // Form state
  const [formData, setFormData] = useState({
    month: "",
    material: "Ceramics",
    load: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/api/loads`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      if (data.success) {
        alert("Load created successfully!");
        setFormData({
          month: "",
          material: "Ceramics",
          load: "",
        });

      } else {
        alert("Error: " + data.message);
      }
    } catch (error) {
      console.error("Error creating load:", error);
    }
  };

  return (
    <div className="tasks-container-tasks">
      <h2 className="h1HeadersStockDetailsBigWhite">Create Load</h2>
      <form className="loadForm" onSubmit={handleSubmit}>
        <label>Month:</label>
        <input
          className="inputTaskExport"
          type="date"
          name="month"
          value={formData.month}
          onChange={handleChange}
          required
        />

        <label>Material:</label>
        <select
          className="selectTaskExport"
          name="material"
          value={formData.material}
          onChange={handleChange}
        >
          <option value="Ceramics">Ceramics</option>
          <option value="Weee">Weee</option>
          <option value="Lambda">Lambda</option>
          <option value="Steel">Steel</option>
          <option value="DPF">DPF</option>
        </select>

        <label>Load:</label>
        <input
          className="inputTaskExport"
          type="text"
          name="load"
          value={formData.load}
          onChange={handleChange}
          required
        />
        <button type="submit"
        className="expenseAllButton"
        >Create Load</button>
      </form>
    </div>
  );
};

export default LoadsMain;
